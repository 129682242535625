/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, {
  useState, useEffect, useRef, useMemo
} from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import QRCode from 'qrcode';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import format from 'date-fns/format';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInDays from 'date-fns/differenceInDays';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import Pagination from '@mui/material/Pagination';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Link } from 'react-router-dom';
import QrTest from '../../QRcodeSCAN/qrtest';
import { BASE_URL } from '../../../utils/api';
import './stockManagementStyles.scss';
import usePageTitle from '../../hooks/usePageTitle';
import ArticleImage from './Articles/articleImage/articleImage';
import AddArticle from './Articles/addArticles/addArticles';
import UpdateArticle from './Articles/updateArticles/updateArticles';
import CategoriesManagementModal from './Categories/categoriesManagementModal';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import BackButton from '../../hooks/backButton';

function StockManagement({
  theme, userId, userRole, userToken, userEmail
}) {
  usePageTitle('DRIVESOFT | GESTION DES STOCKS');
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentArticle, setCurrentArticle] = useState(null);
  const [quantityUpdateModalOpen, setQuantityUpdateModalOpen] = useState(false);
  const [categoriesModalOpen, setCategoriesModalOpen] = useState(false);
  const [quantityUpdate, setQuantityUpdate] = useState(0);
  const [actionType, setActionType] = useState('');
  const [operation, setOperation] = useState(null);
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [page, setPage] = useState(1);
  const [displayedArticles, setDisplayedArticles] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [showLowStock, setShowLowStock] = useState(false);
  const [commentaire, setCommentaire] = useState('');
  const [raison, setRaison] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState('');
  const [vehiculeList, setVehiculeList] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [qrDataToPrint, setQrDataToPrint] = useState(null);
  const itemsPerPage = 12;
  const modalArticleRef = useRef(null);
  const updateArticleModalRef = useRef(null);
  const quantityUpdateModalRef = useRef(null);
  const categoriesModalRef = useRef(null);

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Direction', 'Responsable exploitation', 'Gestionnaire de stock'];
    return authorizedRoles.includes(userRole);
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const handleClick = (e) => {
    if (modalArticleRef.current && !modalArticleRef.current.contains(e.target)) {
      setShowAddModal(false);
    }
    if (updateArticleModalRef.current && !updateArticleModalRef.current.contains(e.target)) {
      setShowUpdateModal(false);
    }
    if (quantityUpdateModalRef.current && !quantityUpdateModalRef.current.contains(e.target)) {
      setQuantityUpdateModalOpen(false);
    }
    if (categoriesModalRef.current && !categoriesModalRef.current.contains(e.target)) {
      setCategoriesModalOpen(false);
    }
  };

  const handleOpenDialog = (data) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous n\'êtes pas autorisé à effectuer cette action.',
      });
      return;
    }
    setQrDataToPrint(data);
    setOpenDialog(true);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleDoubleClick = (company) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous n\'êtes pas autorisé à modifié les articles.',
      });
      return;
    }
    setCurrentArticle(company);
    setShowUpdateModal(true);
  };

  const handleAddArticleClick = () => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous n\'êtes pas autorisé à ajouter des articles.',
      });
      return;
    }
    setShowAddModal(true);
  };

  const handleCategoriesClick = () => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous n\'êtes pas autorisé à modifier les catégories.',
      });
      return;
    }
    setCategoriesModalOpen(true);
  };

  const handleQuantityButtonClick = (articleId, operations) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous n\'êtes pas autorisé à modifier les articles.',
      });
      return;
    }
    setSelectedArticleId(articleId);
    setOperation(operations);
    setQuantityUpdateModalOpen(true);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseFloat(e.target.value);
    setQuantityUpdate(newQuantity);
  };

  const createHistoriqueRecord = async (articleId, adjustedQuantity, operationType) => {
    try {
      const historiqueData = {
        article_id: articleId,
        type: operationType,
        quantite: Math.abs(adjustedQuantity),
        user_id: userId,
        commentaire: commentaire,
        date: new Date().toISOString(),
      };

      // Include additional fields only for 'subtract' operation
      if (operationType === 'subtract') {
        historiqueData.destinataire = selectedStaff || '';
        historiqueData.vehicule_destinataire = selectedVehicle || '';
        historiqueData.raison = raison;
      }

      await axios.post(`${BASE_URL}historique`, historiqueData, { headers });
    }
    catch (error) {
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      else if (error.request) {
        console.error('Request made but no response received:', error.request);
      }
      else {
        console.error('Error in setting up the request:', error.message);
      }
    }
  };

  const handleQuantityUpdateConfirm = async () => {
    try {
      // Vérification si la raison est requise et n'est pas remplie
      if (operation === 'subtract' && !raison) {
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'La raison est obligatoire pour soustraire une quantité.',
        });
        return;
      }

      const currentArticleID = articles.find((article) => article.article_id === selectedArticleId);
      const currentQuantity = currentArticleID ? currentArticleID.quantity : 0;
      const adjustedQuantity = operation === 'subtract' ? -Math.abs(quantityUpdate) : Math.abs(quantityUpdate);
      const newQuantity = currentQuantity + adjustedQuantity;

      if (newQuantity < 0) {
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'La quantité ne peut pas être inférieure à 0.',
        });
        return;
      }

      // Confirmation avant d'envoyer la requête
      const confirmation = await Swal.fire({
        title: 'Confirmez-vous cette opération ?',
        text: `Êtes-vous sûr de vouloir ${operation === 'add' ? 'ajouter' : 'soustraire'} ${Math.abs(quantityUpdate)} unités de cet article ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmer'
      });

      if (confirmation.isConfirmed) {
        const response = await axios.patch(`${BASE_URL}articles/${selectedArticleId}/quantity`, {
          quantityChange: parseInt(adjustedQuantity, 10),
        }, { headers });

        if (response && response.status === 200) {
          const { updatedQuantity } = response.data;

          const updatedArticle = articles.find((article) => article.article_id === selectedArticleId);
          const prixUnite = updatedArticle ? updatedArticle.prix_unite : 0;
          const newPrixTotal = updatedQuantity * prixUnite;

          const updatedArticles = articles.map((article) => {
            if (article.article_id === selectedArticleId) {
              return { ...article, quantity: updatedQuantity, prix_total: newPrixTotal };
            }
            return article;
          });

          setArticles(updatedArticles);

          await createHistoriqueRecord(selectedArticleId, adjustedQuantity, operation);

          Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: `La quantité de l'article a été ${operation === 'add' ? 'ajoutée' : 'soustraite'} avec succès`,
          });

          // Réinitialisation des valeurs
          setQuantityUpdateModalOpen(false);
          setActionType('');
          setCommentaire('');
          setSelectedStaff('');
          setRaison('');
          setSelectedVehicle('');
          setQuantityUpdate(0);
          setIsScannerOpen(false);
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oups.',
            text: 'Une erreur inattendue s\'est produite. Veuillez réessayer.',
          });
          console.error('Unexpected response:', response);
        }
      }
    }
    catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oups.',
        text: 'Une erreur inattendue s\'est produite. Veuillez réessayer.',
      });
      console.error('Error updating article quantity:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      else if (error.request) {
        console.error('Request made but no response received:', error.request);
      }
      else {
        console.error('Error in setting up the request:', error.message);
      }
    }
  };

  const fetchArticles = async () => {
    try {
      const response = await axios.get(`${BASE_URL}articles`, { headers });
      const sortedArticles = response.data.articles.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      setArticles(sortedArticles);
      setIsLoading(false);
    }
    catch (error) {
      console.error('Error fetching articles:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}categories`, { headers });
      setCategories(response.data.categories);
    }
    catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchStaff = async () => {
    try {
      const response = await axios.get(`${BASE_URL}staff`, { headers });

      // Filtrer le personnel sans exit_date et trier par ordre alphabétique
      const filteredAndSortedStaff = response.data.staff
        .filter((staff) => !staff.exitdate)
        .sort((a, b) => a.name.localeCompare(b.name));

      setStaffList(filteredAndSortedStaff);
    }
    catch (error) {
      console.error('Error fetching staff:', error);
    }
  };

  const fetchVehicules = async () => {
    try {
      const response = await axios.get(`${BASE_URL}vehicules`, { headers });

      // Trier les véhicules par code du plus petit au plus grand
      const sortedVehicules = response.data.vehicule
        .sort((a, b) => a.code - b.code); // Trier par code

      setVehiculeList(sortedVehicules); // Mettre à jour la liste des véhicules
    }
    catch (error) {
      console.error('Error fetching vehicules:', error);
    }
  };

  useEffect(() => {
    fetchArticles();
    fetchCategories();
    fetchStaff();
    fetchVehicules();
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const handleArticleAdded = (article) => {
    setArticles([...articles, article]);
    setShowAddModal(false);
    Swal.fire({
      icon: 'success',
      title: 'Article ajouté avec succès',
      showConfirmButton: false,
    });
    fetchArticles();
  };

  const handleArticleUpdated = async (article) => {
    const confirmation = await Swal.fire({
      title: 'Êtes-vous sûr de vouloir modifier cet article ?',
      icon: 'warning',
      confirmButtonText: 'Oui, modifier',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
    });
    if (confirmation.isConfirmed) {
      const index = articles.findIndex((a) => a && a.article_id === article.article_id);
      if (index === -1) {
        console.error('Article not found in articles array:', article);
        return; // Early return if index is -1, preventing further errors
      }

      const newArticles = [...articles.slice(0, index), article, ...articles.slice(index + 1)];
      setArticles(newArticles);

      setShowUpdateModal(false);
      Swal.fire({
        icon: 'success',
        title: 'Article modifié avec succès',
        showConfirmButton: true,
        confirmationButtonText: 'OK',
      });
      fetchArticles();
    }
  };

  const handleDeleteArticle = async (articleId) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous n\'êtes pas autorisé à supprimer les articles.',
      });
      return;
    }
    Swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cet article ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Annuler',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${BASE_URL}articles/${articleId}`, { headers });
          const newList = articles.filter((article) => article.article_id !== articleId);
          setArticles(newList);
          fetchArticles();
          Swal.fire({
            icon: 'success',
            title: 'Article supprimé avec succès',
            showConfirmButton: true,
            confirmationButtonText: 'OK',
          });
          fetchArticles();
        }
        catch (error) {
          console.error('Error deleting article:', error);
        }
      }
    });
  };

  const handleCategoryUpdate = async (category) => {
    const confirmation = await Swal.fire({
      title: 'Êtes-vous sûr de vouloir modifier cette catégorie ?',
      icon: 'warning',
      confirmButtonText: 'Oui, modifier',
      cancelButtonText: 'Annuler',
      showCancelButton: true,
    });

    if (confirmation.isConfirmed) {
      try {
        const response = await axios.put(`${BASE_URL}categories/${category.category_id}`, category, { headers });

        // Vérification de la réussite de la requête
        if (response.status === 200) {
          const index = categories.findIndex((c) => c.category_id === category.category_id);
          const newCategories = [...categories.slice(0, index), category, ...categories.slice(index + 1)];
          setCategories(newCategories);
          setCategoriesModalOpen(false);
          Swal.fire({
            icon: 'success',
            title: 'Catégorie modifiée avec succès',
            showConfirmButton: true,
            confirmationButtonText: 'OK',
          });
          fetchCategories();
        }
        else {
          // Gérer les erreurs de la requête ici
          console.error('Erreur lors de la mise à jour de la catégorie:', response);
        }
      }
      catch (error) {
        // Gérer les erreurs réseau ou autres erreurs ici
        console.error('Erreur réseau ou autre lors de la mise à jour de la catégorie:', error);
      }
    }
  };

  const handleCategoryDelete = async (category) => {
    Swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette catégorie ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Annuler',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${BASE_URL}categories/${category.category_id}`, { headers });
          const newList = categories.filter((c) => c.category_id !== category.category_id);
          setCategories(newList);
          Swal.fire({
            icon: 'success',
            title: 'Catégorie supprimée avec succès',
            showConfirmButton: true,
            confirmationButtonText: 'OK',
          });
        }
        catch (error) {
          console.error('Error deleting category:', error);
          if (error.response && error.response.data && error.response.data.message) {
            Swal.fire({
              icon: 'error',
              title: 'Erreur',
              text: error.response.data.message,
            });
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Erreur',
              text: 'Cette catégorie à un ou plusieurs articles associés. Veuillez supprimer les articles avant de supprimer la catégorie.',
            });
          }
        }
      }
    });
  };

  useEffect(() => {
    const currentDate = new Date();

    const lowStockArticles = articles.filter((article) => article?.quantity <= article?.min_quantity);
    const expiredArticles = articles.filter((article) => {
      if (!article?.date_limite) return false; // Exclure si la date_limite est null ou undefined
      const expirationDate = new Date(article.date_limite);
      if (Number.isNaN(expirationDate.getTime())) return false; // Exclure si la date_limite n'est pas une date valide
      const dayDifference = differenceInDays(expirationDate, currentDate);
      return dayDifference <= 0;
    });

    const showAlerts = async () => {
      if (lowStockArticles.length > 0) {
        const articleNamesList = lowStockArticles.map((article) => `<li>${article.name}</li>`).join('');
        await Swal.fire({
          icon: 'warning',
          title: 'Alerte de Quantité',
          html: `La quantité des articles suivants est égale ou inférieure à la quantité minimale:<ul>${articleNamesList}</ul>`,
          showConfirmButton: true,
        });
      }

      if (expiredArticles.length > 0) {
        const expiredArticleNamesList = expiredArticles.map((article) => `<li>${article.name}</li>`).join('');
        await Swal.fire({
          icon: 'warning',
          title: 'Alerte de Date Limite',
          html: `Les articles suivants sont expirés ou proches de leur date d'expiration:<ul>${expiredArticleNamesList}</ul>`,
          showConfirmButton: true,
        });
      }
    };
    showAlerts();
  }, [articles]);

  const calculateTotalPrice = (items) => items.reduce((acc, article) => acc + (Number(article?.prix_total) || 0), 0).toFixed(2);
  const shouldHighlightDate = (dateLimite) => {
    if (!dateLimite) return false;

    const currentDate = new Date();
    const targetDate = new Date(dateLimite);
    const monthDifference = differenceInMonths(targetDate, currentDate);
    const dayDifference = differenceInDays(targetDate, currentDate);

    return monthDifference < 1 || dayDifference <= 0;
  };

  const filteredArticles = useMemo(
    () => articles
      .filter((article) => article && article.name && article.name.toLowerCase().includes(searchTerm.toLowerCase()))
      .filter((article) => (selectedCategory ? article && article.category_name === selectedCategory : true))
      .filter((article) => (selectedSupplier ? article && article.fournisseur === selectedSupplier : true))
      .filter((article) => (showLowStock ? article && article?.quantity <= article?.min_quantity : true)),
    [articles, searchTerm, selectedCategory, selectedSupplier, showLowStock]
  );

  const sortedArticles = useMemo(() => filteredArticles.sort((a, b) => {
    if (sortField === 'date_limite') {
      const dateA = new Date(a.date_limite);
      const dateB = new Date(b.date_limite);
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    }
    return 0;
  }), [filteredArticles, sortField, sortDirection]);

  const printQRCode = (qrCodeData, qrSize) => {
    const printWindow = window.open('', '_blank');

    const generateQRImgSrc = (data) => new Promise((resolve, reject) => {
      QRCode.toCanvas(document.createElement('canvas'), data.qrCode, { width: qrSize }, (error, canvas) => {
        if (error) reject(error);
        resolve({
          label: data.label,
          imgSrc: canvas.toDataURL()
        });
      });
    });

    const generateAllQRCodes = qrCodeData.map((data) => generateQRImgSrc(data));

    Promise.all(generateAllQRCodes).then((allData) => {
      let content = '<html><head><title>Impression de qr code</title></head><body>';
      allData.forEach((data) => {
        content += `
        <div style="display: inline-block; margin: 20px; text-align: center;">
        <div style="margin-bottom: 10px;">${data.label}</div>
        <img src="${data.imgSrc}" alt="QR Code" />
      </div>`;
      });
      content += '</body></html>';
      printWindow.document.write(content);
      printWindow.document.close();
      printWindow.print();
    }).catch((error) => {
      console.error('Error generating QR codes:', error);
    });
  };

  const allQRCodes = articles.filter((article) => article && article.name && article.qr_code)
    .map((article) => ({ label: article.name, qrCode: article.qr_code }));

  const exportToExcel = () => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous n\'êtes pas autorisé à effectuer cette action.',
      });
      return;
    }
    // 1. Collecter les données
    const ws_data = filteredArticles.map((article) => [
      article.category_name,
      article.name,
      article.quantity,
      article.min_quantity,
      article.conditionnement,
      article.prix_unite,
      article.quantity * article.prix_unite,
      article.date_limite ? format(new Date(article.date_limite), 'dd/MM/yyyy') : 'Sans date',
      article.fournisseur,
    ]);

    const totalGlobal = filteredArticles.reduce((acc, article) => acc + (article.quantity * article.prix_unite), 0);
    ws_data.push(['', '', '', '', '', 'Total Global HT:', totalGlobal, '', '']);

    // Ajouter les en-têtes de colonne
    ws_data.unshift([
      'Catégorie', 'Nom', 'Quantité', 'Quantité minimale',
      'Conditionnement', 'Prix unitaire € HT', 'Prix total € HT',
      'Date limite', 'Fournisseur'
    ]);

    // 2. Utiliser xlsx pour créer un workbook
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Articles');

    // 3. Exporter le workbook
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
    saveAs(new Blob([buf], { type: 'application/octet-stream' }), 'articles.xlsx');
  };

  useEffect(() => {
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const displayed = sortedArticles.slice(start, end);
    setDisplayedArticles(displayed);
  }, [sortedArticles, sortField, sortDirection, page, itemsPerPage]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }
    else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const confirmCloseModal = (setModalState) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester'
    }).then((result) => {
      if (result.isConfirmed) {
        // Fermer la modal si l'utilisateur confirme
        setModalState(false);
      }
    });
  };

  const checkIfTaskExists = async (articleName, type) => {
    try {
      const response = await axios.get(`${BASE_URL}tasks`, { headers });
      const { tasks } = response.data;

      // Vérification du field_name avec articleName au lieu de vehicule.code
      return tasks.some((task) => task.field_name === `${type} - Article ${articleName}`);
    }
    catch (error) {
      console.error('Error checking tasks:', error);
      return false;
    }
  };

  const createTask = async (article, type, expirationDate) => {
    const fieldName = `${type} - Article ${article.name}`;
    const comment = `${type} pour l'article ${article.name} expire le ${format(new Date(expirationDate), 'dd-MM-yyyy')}`;

    const taskData = {
      procedure_id: 0,
      status: 'à faire',
      author_name: userEmail,
      field_name: fieldName, // Utilise le nom de l'article pour créer une tâche unique
      article_id: article.article_id,
      role_name: 'Administrateur',
      comment,
    };

    // Vérification si la tâche existe déjà
    const taskExists = await checkIfTaskExists(article.name, type);

    if (!taskExists) {
      try {
        await axios.post(`${BASE_URL}tasks`, taskData, { headers });
      }
      catch (error) {
        console.error('Error creating task:', error);
      }
    }
  };

  useEffect(() => {
    const checkArticleExpirationDates = async () => {
      const currentDate = new Date();

      for (const article of articles) {
        if (article.date_limite) {
          const expirationDate = new Date(article.date_limite);
          const daysUntilExpiration = differenceInDays(expirationDate, currentDate);

          if (daysUntilExpiration <= 30) {
            // Créer une tâche si l'article expire dans moins de 30 jours
            await createTask(article, 'Date Limite', expirationDate);
          }

          if (daysUntilExpiration <= 0) {
            // Alerte si l'article est déjà expiré
            console.warn(`L'article ${article.name} est expiré.`);
          }
        }
      }
    };

    if (articles.length > 0) {
      checkArticleExpirationDates();
    }
  }, [articles]);

  if (isLoading) {
    return <AmbysoftLoader />;
  }

  return (
    <div className={`stockManagement ${theme === 'dark' ? 'stockManagement-dark' : 'stockManagement-light'}`}>
      <BackButton />
      <div className="search-bar">
        <Link to="/activityManagement/stockManagement/historique">
          <button type="button" className="historiqueButton">Historique</button>
        </Link>
        <input
          type="text"
          placeholder="Recherche par nom"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
          <option value="">Toutes les categories</option>
          {categories.map((category) => (
            <option key={category.category_id} value={category.category_name}>
              {category.category_name}
            </option>
          ))}
        </select>
        <select value={selectedSupplier} onChange={(e) => setSelectedSupplier(e.target.value)} className="hide-on-mobile">
          <option value="">Tous les fournisseurs</option>
          {[...new Set(articles
            .filter((article) => article && article.fournisseur && article.fournisseur.trim() !== '')
            .map((article) => article.fournisseur))]
            .map((fournisseur) => (
              <option key={fournisseur} value={fournisseur}>
                {fournisseur}
              </option>
            ))}
        </select>
        <div className="qr-scanner">
          {isScannerOpen && (
            <Modal
              isOpen={isScannerOpen}
              onRequestClose={() => confirmCloseModal(setIsScannerOpen)}
              style={{
                overlay: {
                },
                content: {
                  top: '50%',
                  left: '55%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  width: '80%',
                  margin: 'auto',
                  padding: 0,
                  border: 'none',
                  borderRadius: 0,
                },
              }}
              ariaHideApp={false}
            >
              <QrTest
                setModalOpen={setQuantityUpdateModalOpen}
                setActionType={setActionType}
                handleQuantityButtonClick={handleQuantityButtonClick}
                userToken={userToken}
              />
            </Modal>

          )}
          <Button className="qr-scanner-button" onClick={() => setIsScannerOpen(!isScannerOpen)}>
            <QrCodeScannerIcon />
          </Button>
        </div>
        <button
          className="hide-on-mobile"
          type="button"
          onClick={() => setShowLowStock(!showLowStock)}
        >
          {showLowStock ? 'Afficher tous les articles' : 'Afficher les articles en rupture de stock'}
        </button>
      </div>

      <div className="regulation__header__butto">
        <button type="button" className="AddTransport" onClick={handleAddArticleClick}>Ajouter un article</button>
      </div>
      <table width="100%" className="table">
        <thead>
          <tr>
            <th className="table__header stock" />
            <th className="table__header stock">Catégorie
              <Tooltip title="Gestion des catégories" arrow>
                <IconButton
                  edge="end"
                  aria-label="manage categories"
                  onClick={handleCategoriesClick}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </th>
            <th className="table__header stock">Nom</th>
            <th className="table__header stock">Quantité</th>
            <th className="table__header stock hide-on-mobile">Quantité minimale</th>
            <th className="table__header stock hide-on-mobile">Conditionnement</th>
            <th className="table__header stock hide-on-mobile">Prix unitaire € HT</th>
            <th className="table__header stock hide-on-mobile">Prix total € HT</th>
            <th
              className="table__header stock dateLimit"
              onClick={() => handleSort('date_limite')}
            >
              Date limite&nbsp;
              {sortField === 'date_limite' && sortDirection === 'asc' ? (
                <ArrowDropUpIcon fontSize="small" />
              ) : (
                <ArrowDropDownIcon fontSize="small" />
              )}
            </th>

            <th className="table__header stock hide-on-mobile">Fournisseur</th>
            <th className="table__header stock">Actions</th>
          </tr>
        </thead>
        <tbody className="TransportBody">
          {displayedArticles.map((article) => (
            <tr key={article?.article_id} className="table-row" onClick={() => handleDoubleClick(article)}>
              <td className="table_cell stockC imageCell"><ArticleImage userToken={userToken} imageKey={article?.picture} /></td>
              <td className="table_cell stockC">{article?.category_name}</td>
              <td className="table_cell stockC">{article?.name}</td>
              <td className="table_cell stockC">{article?.quantity}</td>
              <td className="table_cell stockC hide-on-mobile" style={{ color: article?.quantity <= article?.min_quantity ? 'red' : 'inherit' }}>
                {article?.min_quantity}
              </td>
              <td className="table_cell stockC hide-on-mobile">{article?.conditionnement}</td>
              <td className="table_cell stockC hide-on-mobile">{article?.prix_unite}</td>
              <td className="table_cell stockC hide-on-mobile">{article?.prix_total}</td>
              <td className="table_cell stockC" style={{ color: shouldHighlightDate(article?.date_limite) ? 'red' : 'inherit' }}>
                {article?.date_limite ? (
                  format(new Date(article.date_limite), 'dd/MM/yyyy')
                ) : (
                  <span>Sans date</span>
                )}
              </td>
              <td className="table_cell stockC hide-on-mobile">{article?.fournisseur}</td>
              <td className="table_cell stockC action">
                <Tooltip title="Ajout quantité" arrow>
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleQuantityButtonClick(article.article_id, 'add');
                      setActionType('add');
                    }}
                  >
                    <AddIcon style={{ color: 'green' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Suppression quantité" arrow>
                  <IconButton
                    edge="end"
                    aria-label="subtract"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleQuantityButtonClick(article.article_id, 'subtract');
                      setActionType('subtract');
                    }}
                  >
                    <RemoveIcon style={{ color: 'red' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Impression du QR code" arrow>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenDialog([{ label: article.name, qrCode: article.qr_code }]);
                    }}
                  >
                    <PrintIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Article" arrow>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteArticle(article.article_id);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogActions>
              <Button onClick={() => {
                printQRCode(qrDataToPrint, 80);
                setOpenDialog(false);
              }}
              >
                Petit
              </Button>
              <Button onClick={() => {
                printQRCode(qrDataToPrint, 120);
                setOpenDialog(false);
              }}
              >
                Moyen
              </Button>
              <Button onClick={() => {
                printQRCode(qrDataToPrint, 170);
                setOpenDialog(false);
              }}
              >
                Grand
              </Button>
            </DialogActions>
          </Dialog>

        </tbody>
      </table>
      {quantityUpdateModalOpen && (
      <Modal
        className="quantity-update-modal"
        isOpen={quantityUpdateModalOpen}
        onRequestClose={() => {
          confirmCloseModal(setQuantityUpdateModalOpen);
          setIsScannerOpen(false);
        }}
        ariaHideApp={false}
        ref={(el) => {
          quantityUpdateModalRef.current = el && el.contentRef;
        }}
      >
        <p className="modalTitle">
          {actionType === 'add' ? 'Quantité à ajouter' : 'Quantité à soustraire'}
        </p>
        <TextField
          id="filled-basic"
          label="Quantité"
          variant="filled"
          type="number"
          value={quantityUpdate}
          onChange={handleQuantityChange}
          style={{
            width: '100%', padding: '10px', borderRadius: '4px', marginBottom: '10px'
          }}
        />
        {actionType === 'subtract' && (
        <>
          <FormControl style={{
            width: '100%', borderRadius: '4px', padding: '10px',
          }}
          >
            <InputLabel id="staff-label">Destinataire</InputLabel>
            <Select
              labelId="staff-label"
              id="staff"
              value={selectedStaff}
              onChange={(e) => setSelectedStaff(e.target.value)}
              style={{
                width: '100%', borderRadius: '4px',
              }}
            >
              {staffList.map((staff) => (
                <MenuItem key={staff.id} value={`${staff.name} ${staff.last_name}`.trim()}>
                  {staff.name} {staff.last_name}
                </MenuItem>
              ))}
            </Select>

          </FormControl>
          <FormControl style={{
            width: '100%', borderRadius: '4px', padding: '10px',
          }}
          >
            <InputLabel id="vehicule-label">Véhicule destinataire</InputLabel>
            <Select
              labelId="vehicule-label"
              id="vehicule"
              value={selectedVehicle}
              onChange={(e) => setSelectedVehicle(e.target.value)}
              style={{
                width: '100%', borderRadius: '4px',
              }}
            >
              {vehiculeList.map((vehicule) => (
                <MenuItem key={vehicule.id} value={vehicule.code}>
                  {vehicule.code}
                </MenuItem>
              ))}
            </Select>

          </FormControl>
          <FormControl style={{
            width: '100%', borderRadius: '4px', padding: '10px',
          }}
          >
            <InputLabel id="raison-label">Raison</InputLabel>
            <Select
              labelId="raison-label"
              id="raison"
              required
              value={raison}
              onChange={(e) => setRaison(e.target.value)}
              style={{
                width: '100%', borderRadius: '4px',
              }}
            >
              <MenuItem value="Périmé">Périmé</MenuItem>
              <MenuItem value="Défectueux">Défectueux</MenuItem>
              <MenuItem value="Réapprovisionnement">Réapprovisionnement</MenuItem>
            </Select>
          </FormControl>
        </>
        )}
        <TextField
          id="commentaire"
          label="Commentaire"
          variant="filled"
          value={commentaire}
          onChange={(e) => setCommentaire(e.target.value)}
          style={{
            width: '100%', padding: '10px', borderRadius: '4px', marginBottom: '10px'
          }}
        />
        <Button
          sx={{ margin: 'auto', backgroundColor: '#505154', width: '80%' }}
          variant="contained"
          onClick={handleQuantityUpdateConfirm}
        >
          Enregistrer
        </Button>
        <Button sx={{ margin: 'auto', backgroundColor: '#505154', width: '80%' }} variant="contained" onClick={() => setQuantityUpdateModalOpen(false)}>Fermer</Button>
      </Modal>
      )}

      {showAddModal && (
      <Modal
        className="article-modal"
        isOpen={showAddModal}
        onRequestClose={() => confirmCloseModal(setShowAddModal)}
        ariaHideApp={false}
        ref={(el) => {
          modalArticleRef.current = el && el.contentRef;
        }}
      >
        <AddArticle userToken={userToken} onArticleAdded={handleArticleAdded} />
        <Button sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }} variant="contained" onClick={() => setShowAddModal(false)}>Fermer</Button>

      </Modal>
      )}

      <Modal
        className="article-modal"
        isOpen={showUpdateModal}
        onRequestClose={() => confirmCloseModal(setShowUpdateModal)}
        ariaHideApp={false}
        ref={(el) => {
          updateArticleModalRef.current = el && el.contentRef;
        }}
      >
        <UpdateArticle userToken={userToken} onArticleUpdated={handleArticleUpdated} currentArticle={currentArticle} />
        <Button sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }} variant="contained" onClick={() => setShowUpdateModal(false)}>Fermer</Button>

      </Modal>

      {categoriesModalOpen && (
      <Modal
        className="category-modal"
        isOpen={categoriesModalOpen}
        onRequestClose={() => confirmCloseModal(setCategoriesModalOpen)}
        ariaHideApp={false}
        ref={(el) => {
          categoriesModalRef.current = el && el.contentRef;
        }}
      >
        <CategoriesManagementModal userToken={userToken} categories={categories} onCategoryUpdate={handleCategoryUpdate} onCategoryDelete={handleCategoryDelete} />
        <Button sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }} variant="contained" onClick={() => setCategoriesModalOpen(false)}>Fermer</Button>
      </Modal>
      )}
      <div className="footer">
        <Tooltip title="Imprimer tous les QR codes" arrow>
          <IconButton onClick={() => handleOpenDialog(allQRCodes)}>
            <PrintIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Exporter en Excel" arrow>
          <IconButton onClick={exportToExcel}>
            <FileDownloadIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Pagination count={Math.ceil(filteredArticles.length / itemsPerPage)} page={page} onChange={handleChange} />
        <span>Valeur du stock: {calculateTotalPrice(articles)} € HT</span>
      </div>
    </div>

  );
}

export default React.memo(StockManagement);
