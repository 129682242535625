/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox, Input, LinearProgress, Button, ToggleButton, ToggleButtonGroup, Rating,
  Slider,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../../../utils/api';
import ProceduresImages from './proceduresResponses/proceduresImages';

// champ de type "chapitre"
function ChapterComponent({ field }) {
  return <strong className="field-item chapter">{field.name}</strong>;
}

// champ de type "nombre"
function NumberFieldComponent({ field, onChange }) {
  return (
    <>
      <FormLabel component="legend">{field.name} {!field.optional && <span style={{ color: 'red' }}>*</span>}
        <br />
        <span style={{ color: 'red', fontSize: '.8rem' }}>{field.information}</span>
      </FormLabel>
      <TextField
        type="number"
        label={field.name}
        value={field.value}
        onChange={(e) => onChange(field.id, e.target.value)}
      />
    </>
  );
}

function SatisfactionFieldComponent({ field, onChange }) {
  return (
    <>
      <FormLabel component="legend">
        {field.name} {!field.optional && <span style={{ color: 'red' }}>*</span>}
        <br />
        <span style={{ fontSize: '.8rem' }}>{field.information}</span>
      </FormLabel>
      <Rating
        name={`field-${field.id}`}
        value={field.value || 0}
        onChange={(event, newValue) => onChange(field.id, newValue)}
        max={5}
      />
    </>
  );
}

function JaugeFieldComponent({ field, onChange }) {
  return (
    <>
      <FormLabel component="legend">
        {field.name} {!field.optional && <span style={{ color: 'red' }}>*</span>}
        <br />
        <span style={{ fontSize: '.8rem' }}>{field.information}</span>
      </FormLabel>
      <Slider
        value={field.value || 0}
        onChange={(event, newValue) => onChange(field.id, newValue)}
        min={0}
        max={100}
        marks
        valueLabelDisplay="auto"
      />
    </>
  );
}

// champ de type "date"
function DateFieldComponent({ field, onChange }) {
  return (
    <>
      <span style={{ color: 'red', fontSize: '.8rem' }}>{field.information}</span>
      <TextField
        type="date"
        label={`${field.name} ${!field.optional ? '*' : ''}`}
        value={field.value}
        onChange={(e) => onChange(field.id, e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={field.optional ? { label: { color: 'normal' } } : { label: { color: 'red' } }}
      />
    </>
  );
}

// champ de type "date et heure"
function DateTimeFieldComponent({ field, onChange }) {
  return (
    <>
      <FormLabel component="legend">{field.name} {!field.optional && <span style={{ color: 'red' }}>*</span>}
        <br />
      </FormLabel>
      <TextField
        type="datetime-local"
        value={field.value}
        information={field.information}
        onChange={(e) => onChange(field.id, e.target.value)}
        InputLabelProps={{ shrink: true }}
      />
      <span style={{ color: 'red', fontSize: '.8rem' }}>{field.information}</span>
    </>
  );
}

// champ de type "fichier"
function FileFieldComponent({ field, onFileChange }) {
  return (
    <div className="file-input">
      <FormLabel component="legend">
        {field.name} {!field.optional && <span style={{ color: 'red' }}>*</span>}
      </FormLabel>
      <span style={{ color: 'red', fontSize: '.8rem' }}>{field.information}</span>
      <Button variant="contained" component="label">
        Choisir un fichier
        <Input
          type="file"
          accept="image/*,application/pdf"
          onChange={(e) => onFileChange(e, field.id)}
          style={{ display: 'none' }}
        />
      </Button>
    </div>
  );
}

// champ de type "texte multiligne"
function MultilineTextFieldComponent({ field, onChange }) {
  return (
    <>
      <FormLabel component="legend">{field.name} {!field.optional && <span style={{ color: 'red' }}>*</span>}
        <br />
        <span style={{ color: 'red', fontSize: '.8rem' }}>{field.information}</span>
      </FormLabel>
      <TextField
        multiline
        rows={4}
        value={field.value}
        onChange={(e) => onChange(field.id, e.target.value)}
      />
    </>
  );
}

// champ de type "checkbox"
function RadioFieldComponent({ field, onChange }) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        {field.name} {!field.optional && <span style={{ color: 'red' }}>*</span>}
        <br />
        <span style={{ color: 'red', fontSize: '.8rem' }}>{field.information}</span>
      </FormLabel>
      <RadioGroup
        value={field.value}
        onChange={(e) => onChange(field.id, e.target.value)}
      >
        {field.options.map((option) => (
          <FormControlLabel
            key={option}
            value={option}
            control={<Radio />}
            label={option}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

function QuantityFieldComponent({ field, onChange }) {
  // La valeur du champ est un objet contenant la quantité,
  // le booléen perimable et la date éventuellement.
  const value = field.value || { quantity: 0, perimable: false, date: '' };

  const handleQuantityChange = (e) => {
    const updatedValue = { ...value, quantity: parseInt(e.target.value, 10) };
    onChange(field.id, updatedValue);
  };

  const handlePerimableChange = (e) => {
    const updatedValue = { ...value, perimable: e.target.checked };
    // Si on décoche, on peut remettre la date à vide
    if (!e.target.checked) {
      updatedValue.date = '';
    }
    onChange(field.id, updatedValue);
  };

  const handleDateChange = (e) => {
    const updatedValue = { ...value, date: e.target.value };
    onChange(field.id, updatedValue);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <FormLabel component="legend">
        {field.name} {!field.optional && <span style={{ color: 'red' }}>*</span>}
      </FormLabel>
      {field.information && (
        <div style={{ fontSize: '0.8rem', color: 'red', marginBottom: '5px' }}>
          {field.information}
        </div>
      )}
      <TextField
        type="number"
        label={`Quantité (min: ${field.minQuantity || 0})`}
        value={value.quantity}
        onChange={handleQuantityChange}
        style={{ marginRight: '10px', marginTop: '10px' }}
      />

      <FormControlLabel
        control={<Checkbox checked={value.perimable} onChange={handlePerimableChange} />}
        label="Date de péremption ?"
      />

      {value.perimable && (
        <TextField
          type="date"
          label="Date limite"
          value={value.date}
          onChange={handleDateChange}
          InputLabelProps={{ shrink: true }}
          style={{ marginTop: '10px' }}
        />
      )}
    </div>
  );
}

// champ de type information
function InformationFieldComponent({ field }) {
  const [showInfo, setShowInfo] = useState(false);

  const toggleInfoDisplay = () => {
    setShowInfo(!showInfo);
  };

  return (
    <>
      <div className="field-item info" onClick={toggleInfoDisplay}>
        <span className="field-icon">
          <InfoIcon />
        </span>
        {field.name}

        {showInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
      {showInfo && <p>{field.information}</p>}
    </>
  );
}

function CheckboxFieldComponent({ field, onChange }) {
  const handleCheckboxChange = (option) => {
    const updatedValue = Array.isArray(field.value)
      ? field.value.includes(option)
        ? field.value.filter((v) => v !== option)
        : [...field.value, option]
      : [option];
    onChange(field.id, [...updatedValue]); // Créer un nouveau tableau
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{field.name} {!field.optional && <span style={{ color: 'red' }}>*</span>}
        <br />
        <span style={{ color: 'red', fontSize: '.8rem' }}>{field.information}</span>
      </FormLabel>
      {field.options.map((option) => (
        <FormControlLabel
          key={option}
          control={(
            <Checkbox
              checked={Array.isArray(field.value) && field.value.includes(option)}
              onChange={() => handleCheckboxChange(option)}
            />
          )}
          label={option}
        />
      ))}
    </FormControl>
  );
}

function ToggleButtonComponent({ field, onChange }) {
  const handleToggleChange = (event, newValue) => {
    onChange(field.id, newValue);
  };

  return (
    <>
      <FormLabel component="legend">{field.name} {!field.optional && <span style={{ color: 'red' }}>*</span>}
        <br />
        <span style={{ color: 'red', fontSize: '.8rem' }}>{field.information}</span>
      </FormLabel>
      <ToggleButtonGroup
        value={field.value}
        exclusive
        onChange={handleToggleChange}
      >
        <ToggleButton
          value="OK"
          style={{
            color: 'green',
            border: field.value === 'OK' ? '2px solid green' : 'none'
          }}

        >
          OK <CheckIcon fontSize="small" />
        </ToggleButton>
        <ToggleButton
          value="KO"
          style={{
            color: 'red',
            border: field.value === 'KO' ? '2px solid red' : 'none'
          }}
        >
          KO <CloseIcon fontSize="small" />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}

// champ de type "text"
function TextFieldComponent({ field, onChange }) {
  return (
    <>
      <FormLabel component="legend">
        {field.name} {!field.optional && <span style={{ color: 'red' }}>*</span>}
        <br />
        <span style={{ color: 'red', fontSize: '.8rem' }}>{field.information}</span>
      </FormLabel>
      <TextField
        value={field.value}
        onChange={(e) => onChange(field.id, e.target.value)}
      />
    </>
  );
}

function UserProcedureForm({ procedureData, onClose, userToken }) {
  if (!procedureData) {
    return null; // ou un rendu alternatif si nécessaire
  }

  const currentDate = new Date().toISOString().slice(0, 10); // Format YYYY-MM-DD

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };
  const [staff, setStaff] = useState([]);
  const [crews, setCrews] = useState([]);
  const [vehicules, setVehicules] = useState([]);
  const [vehicleCode, setVehicleCode] = useState('');
  const [isSubmissionAllowed, setIsSubmissionAllowed] = useState(true);
  const [nextAllowedDate, setNextAllowedDate] = useState(null);
  const [responses, setResponses] = useState({});
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await axios.get(`${BASE_URL}staff`, { headers });
        setStaff(response.data.staff);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des membres du staff', error);
      }
    };
    fetchStaff();

    const fetchCrews = async () => {
      try {
        const response = await axios.get(`${BASE_URL}crews`, { headers });
        setCrews(response.data.crews);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des équipages', error);
      }
    };
    fetchCrews();

    const fetchVehicules = async () => {
      try {
        const response = await axios.get(`${BASE_URL}vehicules`, { headers });
        setVehicules(response.data.vehicule);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des véhicules', error);
      }
    };
    fetchVehicules();
  }, []);

  useEffect(() => {
    const email = localStorage.getItem('userEmail');
    const vehicleIdsByDate = JSON.parse(localStorage.getItem('vehicleIdsByDate') || '{}');
    const currentDateVehicleIds = vehicleIdsByDate[currentDate];

    if (email) {
      setUserEmail(email);
    }

    if (currentDateVehicleIds && currentDateVehicleIds.length > 0) {
      // Ici on suppose qu'un utilisateur peut être lié à plusieurs véhicules dans une journée
      // Vous devrez déterminer comment sélectionner le bon véhicule si c'est le cas
      const vehicleIdForUser = currentDateVehicleIds[0]; // Exemple : prendre le premier ID de véhicule
      const vehicle = vehicules.find((v) => v.id === parseInt(vehicleIdForUser, 10));
      if (vehicle) {
        setVehicleCode(vehicle.code); // Utiliser le code du véhicule
      }
    }
  }, [vehicules, currentDate]);

  useEffect(() => {
    const checkProcedureSubmission = async () => {
      try {
        const response = await axios.get(`${BASE_URL}procedure-responses?procedureId=${procedureData.id}&vehicleCode=${vehicleCode}&date=${currentDate}`, { headers });

        if (response.data.length > 0) {
          const lastResponse = response.data[response.data.length - 1];
          const lastResponseDate = new Date(lastResponse.submitted_at);

          // Calculez la prochaine date autorisée en fonction de la fréquence
          let nextDate;
          switch (procedureData.frequency) {
            case 'Quotidienne':
              nextDate = new Date(lastResponseDate);
              nextDate.setDate(nextDate.getDate() + 1);
              break;
            case 'Hebdomadaire':
              nextDate = new Date(lastResponseDate);
              nextDate.setDate(nextDate.getDate() + 7);
              break;
            case 'Bihebdomadaire':
              nextDate = new Date(lastResponseDate);
              nextDate.setDate(nextDate.getDate() + 14);
              break;
            case 'Mensuelle':
              nextDate = new Date(lastResponseDate);
              nextDate.setMonth(nextDate.getMonth() + 1);
              break;
            case 'Trimestrielle':
              nextDate = new Date(lastResponseDate);
              nextDate.setMonth(nextDate.getMonth() + 3);
              break;
            case 'Ponctuelle':
              nextDate = new Date(lastResponseDate);
              nextDate.setDate(nextDate.getDate() + 1);
              break;
            default:
              nextDate = lastResponseDate;
          }

          if (new Date() < nextDate) {
            setIsSubmissionAllowed(false);

            const diffTime = Math.abs(nextDate - new Date());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convertir en jours
            setNextAllowedDate(diffDays);
          }
        }
      }
      catch (error) {
        console.error('Erreur lors de la vérification de la soumission de la procédure', error);
      }
    };
    if (vehicleCode) { // S'assurer que vehicleCode est défini avant d'exécuter la vérification
      checkProcedureSubmission();
    }
  }, [procedureData.id, procedureData.frequency, vehicleCode]);

  const staffMember = staff.find((member) => member.email === userEmail);
  if (staffMember) {
    const staffId = staffMember.id;

    // Trouver l'équipage contenant ce membre du staff pour la date actuelle
    const crew = crews.find((c) => (c.staff_id_1 === staffId || c.staff_id_2 === staffId)
      && c.date.startsWith(currentDate)
      && c.vehicule_id !== null);
    if (crew) {
      // Stocker l'ID du véhicule dans le Local Storage
      localStorage.setItem('vehicleId', crew.vehicule_id);
    }
  }

  const handleFieldChange = (fieldId, value) => {
    setResponses({ ...responses, [fieldId]: value });
  };

  const validateForm = () => {
    let isValid = true;
    const validationErrors = {};

    procedureData.fields.forEach((field) => {
      if (!field.optional) { // Si le champ est obligatoire
        if (field.type === 'checkbox' || field.type === 'radio') {
          if (!responses[field.id] || responses[field.id].length === 0) {
            validationErrors[field.id] = 'Ce champ est requis.';
            isValid = false;
          }
        }
        else if (!responses[field.id]) {
          validationErrors[field.id] = 'Ce champ est requis.';
          isValid = false;
        }
      }
    });

    if (!isValid) {
      Swal.fire({
        title: 'Erreur!',
        text: 'Veuillez remplir tous les champs obligatoires avant de soumettre.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isSubmissionAllowed) {
      Swal.fire({
        title: 'Attendez!',
        text: `La prochaine procédure sera dans ${nextAllowedDate} jours.`,
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
      return;
    }

    if (!validateForm()) {
      return; // Ne pas soumettre si la validation échoue
    }
    const userName = userEmail;
    const submissionData = {
      procedure_id: procedureData.id,
      user_id: userName,
      vehicule_id: vehicleCode,
      response_data: responses,
    };

    try {
      const existingResponses = await axios.get(`${BASE_URL}procedure-responses?procedureId=${procedureData.id}&vehicleCode=${vehicleCode}&date=${currentDate}`, { headers });

      if (existingResponses.data.length > 0) {
        // Si une soumission pour cette procédure existe déjà pour ce véhicule à cette date, afficher une alerte
        Swal.fire({
          title: 'Erreur!',
          text: 'Une soumission pour cette procédure et ce véhicule à cette date existe déjà.',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
        return;
      }
      const response = await axios.post(`${BASE_URL}procedure-responses`, submissionData, { headers });
      const createdResponseId = response.data.response_id;
      const twoWeeksInMillis = 2 * 7 * 24 * 60 * 60 * 1000; // Deux semaines en millisecondes

      // Utiliser `responses` (les réponses de la procédure) au lieu de `response` (résultat de Axios)
      const createTasksPromises = Object.entries(responses)
        .filter(([fieldId, fieldValue]) => {
          const field = procedureData.fields.find((f) => f.id.toString() === fieldId);

          // Pour les champs 'KO' ou non vides de type 'text' et 'multiline'
          const isFieldForTask = fieldValue === 'KO' || (fieldValue.trim() !== '' && (field.type === 'text' || field.type === 'multiline'));

          // Pour les champs de type 'date' approchant de leur date limite
          let isDateFieldApproachingOrPastLimit = false;
          if ((field.type === 'date' || field.type === 'datelimite') && fieldValue) {
            const responseDate = new Date(fieldValue);
            const timeDiff = responseDate.getTime() - currentDate.getTime();
            // Condition modifiée pour inclure les dates déjà passées et celles dans les deux semaines à venir
            if (timeDiff < twoWeeksInMillis) {
              isDateFieldApproachingOrPastLimit = true;
            }
          }
          return isFieldForTask || isDateFieldApproachingOrPastLimit;
        })
        .map(([fieldId, fieldValue]) => {
          const field = procedureData.fields.find((f) => f.id.toString() === fieldId);
          if (field) {
            let commentValue = fieldValue;
            if ((field.type === 'date' || field.type === 'datelimite') && fieldValue) {
              commentValue = formatDate(fieldValue);
            }
            else if (field.type === 'text' || field.type === 'multiline') {
              commentValue = fieldValue.trim() !== '' ? fieldValue : undefined;
            }

            const taskData = {
              procedure_id: procedureData.id,
              procedure_response_id: createdResponseId,
              status: 'à faire',
              author_name: userEmail,
              field_name: field.name,
              vehicule_code: vehicleCode,
              role_name: 'Administrateur',
              comment: commentValue,
            };
            return axios.post(`${BASE_URL}tasks`, taskData, { headers });
          }
          return null;
        })
        .filter((task) => task);

      if (createTasksPromises.length > 0) {
        await Promise.all(createTasksPromises);
      }

      Swal.fire({
        title: 'Succès!',
        text: 'La procédure a été soumise avec succès.',
        icon: 'success',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          onClose(); // Ferme la modal après confirmation
        }
      });
    }
    catch (error) {
      console.error('Erreur lors de la soumission du formulaire', error);
      Swal.fire({
        title: 'Erreur!',
        text: 'Une erreur est survenue lors de la soumission de la procédure.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };

  const totalFields = procedureData.fields.filter((field) => field.type !== 'chapter' && field.type !== 'info' && !field.optional).length;
  const filledFields = Object.keys(responses).filter((key) => responses[key] && procedureData.fields.find((field) => field.id.toString() === key && !field.optional)).length;
  const progressPercentage = totalFields > 0 ? (filledFields / totalFields) * 100 : 0;

  const handleFileChange = async (e, fieldId) => {
    const file = e.target.files[0];
    if (!file) return;

    // Vérifiez la taille et le type du fichier ici si nécessaire

    try {
      const response = await axios.post(`${BASE_URL}procedures/presigned`, { name: file.name, type: file.type }, { headers });
      const { url, fields } = response.data;

      const uploadData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        uploadData.append(key, value);
      });
      uploadData.append('file', file);

      await axios.post(url, uploadData, {
        headers: {
          'Content-Type': file.type
        }
      });

      const uploadedFileKey = `${fields.key}`;
      setResponses((prevResponses) => {
        const newResponses = { ...prevResponses, [fieldId]: uploadedFileKey };
        return newResponses;
      });
    }
    catch (error) {
      console.error('Erreur lors de la soumission du fichier', error);
    }
  };

  return (
    <div className="proceduresView">
      <div className="identifierProcedures">
        <p>{userEmail}</p>
        <p>Vehicule {vehicleCode}</p>
      </div>
      <div>
        <h2>{procedureData.name}</h2>
        <p>{procedureData.commentaire}</p>
      </div>
      <div>
        <LinearProgress variant="determinate" value={progressPercentage} />
        <p>{Math.round(progressPercentage)}%</p>
      </div>

      <form>
        {procedureData && procedureData.fields && procedureData.fields.map((field) => {
          switch (field.type) {
            case 'text':
              return <div className="GroupResponses"><TextFieldComponent key={field.id} field={field} onChange={handleFieldChange} /></div>;
            case 'satisfaction':
              return (
                <div className="GroupResponses">
                  <SatisfactionFieldComponent
                    key={field.id}
                    field={{ ...field, value: responses[field.id] }}
                    onChange={handleFieldChange}
                  />
                </div>
              );

            case 'quantity':
              return (
                <div className="GroupResponses">
                  <QuantityFieldComponent
                    key={field.id}
                    field={{ ...field, value: responses[field.id] }}
                    onChange={handleFieldChange}
                  />
                </div>
              );

            case 'jauge':
              return (
                <div className="GroupResponses">
                  <JaugeFieldComponent
                    key={field.id}
                    field={{ ...field, value: responses[field.id] }}
                    onChange={handleFieldChange}
                  />
                </div>
              );
            case 'number':
              return <div className="GroupResponses"><NumberFieldComponent key={field.id} field={field} onChange={handleFieldChange} /></div>;
            case 'date':
              return <div className="GroupResponses"><DateFieldComponent key={field.id} field={field} onChange={handleFieldChange} /></div>;
            case 'datetime':
              return <div className="GroupResponses"><DateTimeFieldComponent key={field.id} field={field} onChange={handleFieldChange} /></div>;
            case 'datelimite':
              return <div className="GroupResponses"><DateFieldComponent key={field.id} field={field} onChange={handleFieldChange} /></div>;
            case 'file':
              return (
                <div className="GroupResponses">
                  <FileFieldComponent key={field.id} field={field} onFileChange={handleFileChange} />
                  {responses[field.id] && <ProceduresImages imageKey={responses[field.id]} />}
                </div>
              );
            case 'multiline':
              return <div className="GroupResponses"><MultilineTextFieldComponent key={field.id} field={field} onChange={handleFieldChange} /></div>;
            case 'radio':
              return <div className="GroupResponses"><RadioFieldComponent key={field.id} field={field} onChange={handleFieldChange} /></div>;
            case 'checkbox':
              return (
                <div className="GroupResponses"><CheckboxFieldComponent
                  key={field.id}
                  field={{ ...field, value: responses[field.id] }}
                  onChange={handleFieldChange}
                />
                </div>
              );
            case 'toggle':
              return (
                <div className="GroupResponses"><ToggleButtonComponent
                  key={field.id}
                  field={{ ...field, value: responses[field.id] }}
                  onChange={handleFieldChange}
                />
                </div>
              );
            case 'chapter':
              return <div className="GroupResponses"><ChapterComponent key={field.id} field={field} /></div>;
            case 'info':
              return <div className="GroupResponses"><InformationFieldComponent key={field.id} field={field} /></div>;
            default:
              return null;
          }
        })}
        <button className="buttonLogin" type="button" onClick={handleSubmit}>Soumettre la Procédure</button>
      </form>
    </div>
  );
}

export default React.memo(UserProcedureForm);
