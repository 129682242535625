/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import {
  Box,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Rating,
  Fade
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, ChartDataLabels);

function ProceduresManagementData({
  theme,
  procedureTypes,
  setSelectedProcedureType,
  selectedProcedureType,
  procedures,
  selectedDetailedType,
  setSelectedDetailedType,
  setSelectedProcedure,
  selectedProcedure,
  questionAverages,
  dataForEmployeesChart,
  dataForVehiclesChart,
  dataForProceduresChart,
  radioResponses,
  selectedOptionFilter,
  setSelectedOptionFilter,
}) {
  const procedureHasType = (procedure, typeName) => procedure.types.some((type) => type.name === typeName);

  const radioOptions = useMemo(() => {
    const optionsSet = new Set();
    procedures
      .filter((proc) => procedureHasType(proc, 'QUALITE'))
      .forEach((procedure) => {
        procedure.fields.forEach((field) => {
          if (field.type === 'radio') {
            field.options.forEach((option) => {
              optionsSet.add(option);
            });
          }
        });
      });
    return Array.from(optionsSet);
  }, [procedures]);

  const isQualiteView = selectedDetailedType === 'QUALITE';
  const isSecuriteView = selectedDetailedType === 'SECURITE';

  const chartOptionsBar = (themes) => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          display: false,
          color: themes === 'dark' ? '#feba00' : '#000',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: themes === 'dark' ? '#feba00' : '#000',
          font: {
            size: 12,
          },
        },
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: themes === 'dark' ? '#feba00' : '#000',
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            return `${label}: ${value}`;
          },
        },
        backgroundColor: themes === 'dark' ? '#333' : '#fff',
        titleColor: themes === 'dark' ? '#feba00' : '#000',
        bodyColor: themes === 'dark' ? '#feba00' : '#000',
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: themes === 'dark' ? '#feba00' : '#000',
        font: {
          size: 12,
        },
        formatter: (value) => Math.abs(Math.round(value)),
      },
    },
  });

  const chartOptionsEmployees = (themes) => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: themes === 'dark' ? '#feba00' : '#000',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: themes === 'dark' ? '#feba00' : '#000',
          beginAtZero: true,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        color: themes === 'dark' ? '#feba00' : '#000',
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += context.parsed.y.toFixed(0);
            return label;
          },
        },
      },
    },
  });

  const chartOptionsVehicles = () => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.parsed.y}`;
          },
        },
      },
    },
  });

  const isDetailedView = isQualiteView || isSecuriteView;
  const detailedTypeLabel = isQualiteView ? 'QUALITE' : isSecuriteView ? 'SECURITE' : 'Global';

  const filteredProceduresForDetail = useMemo(() => {
    if (isQualiteView) {
      return procedures.filter((proc) => procedureHasType(proc, 'QUALITE'));
    }
    if (isSecuriteView) {
      return procedures.filter((proc) => procedureHasType(proc, 'SECURITE'));
    }
    return [];
  }, [procedures, isQualiteView, isSecuriteView]);

  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: '#f4f6f8',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Statistiques des procédures
      </Typography>

      <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="detailed-type-label">Type de détail</InputLabel>
            <Select
              labelId="detailed-type-label"
              id="detailed-type-select"
              value={selectedDetailedType}
              label="Type de détail"
              onChange={(e) => setSelectedDetailedType(e.target.value)}
            >
              <MenuItem value="">
                <em>Aucun filtrage détaillé</em>
              </MenuItem>
              <MenuItem value="QUALITE">QUALITE</MenuItem>
              <MenuItem value="SECURITE">SECURITE</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {!isDetailedView && (
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="procedure-type-label">Type de procédure</InputLabel>
              <Select
                labelId="procedure-type-label"
                id="procedure-type-select"
                value={selectedProcedureType}
                label="Type de Procédure"
                onChange={(e) => setSelectedProcedureType(e.target.value)}
              >
                <MenuItem value="">
                  <em>Tous les types de procédures</em>
                </MenuItem>
                {procedureTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="procedure-select-label">Procédure</InputLabel>
            <Select
              labelId="procedure-select-label"
              id="procedure-select"
              value={selectedProcedure}
              label="Procédure"
              onChange={(e) => setSelectedProcedure(e.target.value)}
            >
              <MenuItem value="">
                <em>Toutes les procédures</em>
              </MenuItem>
              {(isDetailedView ? filteredProceduresForDetail : procedures).map((procedure) => (
                <MenuItem key={procedure.id} value={procedure.id}>
                  {procedure.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {isQualiteView && radioOptions.length > 0 && (
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="option-filter-label">Option</InputLabel>
              <Select
                labelId="option-filter-label"
                id="option-filter-select"
                value={selectedOptionFilter}
                label="Option"
                onChange={(e) => setSelectedOptionFilter(e.target.value)}
              >
                <MenuItem value="">
                  <em>Toutes les options</em>
                </MenuItem>
                {radioOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      {isDetailedView ? (
        <Fade in={isDetailedView} timeout={500}>
          <Box>
            {filteredProceduresForDetail.length > 0 ? (
              filteredProceduresForDetail.map((procedure) => {
                const averages = questionAverages[procedure.id] || {};
                const radioResp = radioResponses[procedure.id] || {};

                // Calcul du nombre de réponses
                const numberOfResponses = (() => {
                  const avgValues = Object.values(averages);
                  if (avgValues.length > 0) {
                    const firstField = avgValues[0];
                    return firstField.count;
                  }
                  const fieldNames = Object.keys(radioResp);
                  if (fieldNames.length > 0) {
                    const firstFieldName = fieldNames[0];
                    const optionsCounts = Object.values(radioResp[firstFieldName]);
                    return optionsCounts.reduce((acc, val) => acc + val, 0);
                  }

                  return 0;
                })();

                return (
                  <Paper
                    key={procedure.id}
                    elevation={3}
                    sx={{ padding: 3, marginBottom: 3 }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ color: 'primary.main' }}
                    >
                      {procedure.name} ({detailedTypeLabel})
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                      {numberOfResponses} réponses
                    </Typography>

                    {Object.keys(averages).length > 0 && (
                      <Box sx={{ marginTop: 2 }}>
                        <Grid container spacing={2}>
                          {Object.entries(averages).map(([fieldName, data]) => (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              key={fieldName}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontWeight: 'bold',
                                    width: '60%',
                                  }}
                                >
                                  {fieldName}:
                                </Typography>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Rating
                                    name={`rating-${fieldName}`}
                                    value={data.total / data.count}
                                    precision={0.1}
                                    readOnly
                                    sx={{ marginRight: 1 }}
                                  />
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {(data.total / data.count).toFixed(2)}/5
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}

                    {Object.keys(radioResp).length > 0 && (
                      <Box sx={{ marginTop: 4 }}>
                        <Typography variant="h6">Distribution</Typography>
                        <Grid container spacing={2}>
                          {Object.entries(radioResp).map(([fieldName, options]) => {
                            const labels = Object.keys(options);
                            const counts = Object.values(options);

                            const data = {
                              labels,
                              datasets: [
                                {
                                  label: 'Nombre de réponses',
                                  data: counts,
                                  backgroundColor: 'rgba(255, 99, 132, 0.6)',
                                },
                              ],
                            };

                            const fieldChartOptions = {
                              responsive: true,
                              maintainAspectRatio: false,
                              indexAxis: 'y',
                              scales: {
                                x: {
                                  ticks: { color: theme === 'dark' ? '#feba00' : '#000' },
                                  grid: { display: true },
                                },
                                y: {
                                  ticks: {
                                    color: theme === 'dark' ? '#feba00' : '#000',
                                    callback: function (value) {
                                      const label = labels[value];
                                      if (typeof label !== 'string') {
                                        return label;
                                      }

                                      const maxLength = 20;
                                      const words = label.split(' ');
                                      const lines = [];
                                      let currentLine = [];

                                      words.forEach((word) => {
                                        const testLine = [...currentLine, word].join(' ');
                                        if (testLine.length > maxLength) {
                                          lines.push(currentLine.join(' '));
                                          currentLine = [word];
                                        }
                                        else {
                                          currentLine.push(word);
                                        }
                                      });

                                      if (currentLine.length > 0) {
                                        lines.push(currentLine.join(' '));
                                      }

                                      return lines;
                                    }
                                  },
                                  grid: { display: false },
                                },
                              },
                              plugins: {
                                legend: { display: false },
                                title: {
                                  display: true,
                                  text: fieldName,
                                  font: { size: 16 },
                                  color: theme === 'dark' ? '#feba00' : '#000',
                                },
                              },
                            };

                            return (
                              <Grid item xs={12} md={6} key={fieldName}>
                                <Paper
                                  elevation={3}
                                  sx={{
                                    padding: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 400,
                                    overflowY: 'auto',
                                  }}
                                >
                                  <Box sx={{ flexGrow: 1 }}>
                                    <Bar data={data} options={fieldChartOptions} />
                                  </Box>
                                </Paper>
                              </Grid>

                            );
                          })}
                        </Grid>
                      </Box>
                    )}
                  </Paper>
                );
              })
            ) : (
              <Typography
                variant="body1"
                align="center"
                color="text.secondary"
                sx={{ marginTop: 4 }}
              >
                Aucune donnée disponible pour les procédures "{detailedTypeLabel}".
              </Typography>
            )}
          </Box>
        </Fade>
      ) : (
        <Fade in={!isDetailedView} timeout={500}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  Nombre par employé
                </Typography>
                <Box sx={{ height: 300 }}>
                  <Bar
                    data={dataForEmployeesChart}
                    options={chartOptionsEmployees(theme)}
                  />
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  Nombre par véhicule
                </Typography>
                <Box sx={{ height: 300 }}>
                  <Bar
                    data={dataForVehiclesChart}
                    options={chartOptionsVehicles(theme)}
                  />
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  Balances retours des procédures
                </Typography>
                <Box sx={{ height: 400 }}>
                  <Bar
                    data={dataForProceduresChart}
                    options={chartOptionsBar(theme)}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Fade>
      )}
    </Box>
  );
}

export default React.memo(ProceduresManagementData);
