/* eslint-disable react/prop-types */
// Navbar.jsx
import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import PeopleIcon from '@mui/icons-material/People';
import ReportIcon from '@mui/icons-material/Report';
import InventoryIcon from '@mui/icons-material/Inventory';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import PaidIcon from '@mui/icons-material/Paid';
import BusinessIcon from '@mui/icons-material/Business';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Définition des éléments de menu avec des icônes
const menuItems = [
  { label: 'Ressources humaines', tab: 'humanResourcesData', icon: <PeopleIcon /> },
  { label: 'Incidents établissements', tab: 'facilityIncidents', icon: <ReportIcon /> },
  { label: 'Incidents personnel', tab: 'staffIncidents', icon: <ReportIcon /> },
  { label: 'Gestion de stock', tab: 'stockRessourcesData', icon: <InventoryIcon /> },
  { label: 'Gestion de véhicules', tab: 'vehicleManagementData', icon: <DirectionsCarIcon /> },
  { label: 'Gestion des procédures', tab: 'ProceduresManagementData', icon: <AssignmentIcon /> },
  { label: 'Gestion du matériel médical', tab: 'medicalDataTab', icon: <MedicalServicesIcon /> },
  { label: 'Masse salariale', tab: 'salaryDataTab', icon: <PaidIcon /> },
  { label: 'Gestion des sociétés', tab: 'companyDataTab', icon: <BusinessIcon /> },
  { label: 'Gestion des locaux', tab: 'locauxDataTab', icon: <LocationCityIcon /> },
  { label: 'Données de la pointeuse', tab: 'timeClockData', icon: <AccessTimeIcon /> },
];
// Style personnalisé pour l'AppBar
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

// Style personnalisé pour les boutons
const StyledButton = styled(Button)(({ theme, active }) => ({
  margin: theme.spacing(0.5),
  padding: theme.spacing(1),
  width: '100%', // Assure que les boutons remplissent la cellule de la grille
  height: 50,
  textTransform: 'none',
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  backgroundColor: active ? 'rgba(25, 118, 210, 0.1)' : 'transparent', // Fond léger pour l'actif
  borderRadius: 8,
  transition: 'background-color 0.3s, color 0.3s, box-shadow 0.3s',
  boxShadow: active ? '0 4px 8px rgba(25, 118, 210, 0.2)' : 'none', // Ombre légère pour l'actif
  '&:hover': {
    backgroundColor: active
      ? 'rgba(25, 118, 210, 0.2)'
      : 'rgba(0, 0, 0, 0.04)', // Hover subtil
  },
}));

function Navbar({ currentTab, setCurrentTab }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleButtonClick = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <StyledAppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
          {/* Remplacez par votre Logo ou Titre */}
          Mon Application
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerToggle}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={handleDrawerToggle}
                onKeyDown={handleDrawerToggle}
              >
                <Typography variant="h6" sx={{ m: 2 }}>
                  Menu
                </Typography>
                <Divider />
                <List>
                  {menuItems.map((item) => (
                    <ListItem key={item.tab} disablePadding>
                      <ListItemButton onClick={() => handleButtonClick(item.tab)}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {item.icon}
                          <ListItemText primary={item.label} sx={{ ml: 2 }} />
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(5, 1fr)', // 5 boutons par ligne
              gap: theme.spacing(1),
              width: '100%',
            }}
          >
            {menuItems.map((item) => (
              <StyledButton
                key={item.tab}
                onClick={() => handleButtonClick(item.tab)}
                active={currentTab === item.tab ? 1 : 0}
                startIcon={item.icon}
              >
                {item.label}
              </StyledButton>
            ))}
          </Box>
        )}
      </Toolbar>
    </StyledAppBar>
  );
}

export default React.memo(Navbar);
