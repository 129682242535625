/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// StaffIncidents.jsx
import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
  Card,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Doughnut, Bar } from 'react-chartjs-2';

function StaffIncidents({
  staff,
  selectedDriver,
  setSelectedDriver,
  currentStaffIncidents,
  paginateStaff,
  currentStaffPage,
  totalStaffPages,
  driverIncidentData,
  incidentColors,
}) {
  const muiTheme = useTheme();
  const isSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const handleDriverChange = (event) => {
    setSelectedDriver(event.target.value);
  };

  // Calculer le nombre total d'incidents par chauffeur
  const incidentsPerDriver = {};
  Object.keys(driverIncidentData.byDriver).forEach((driverName) => {
    const incidentsByType = driverIncidentData.byDriver[driverName];
    const totalIncidents = Object.values(incidentsByType).reduce((sum, count) => sum + count, 0);
    incidentsPerDriver[driverName] = totalIncidents;
  });

  // Préparer les données pour le graphique en barres
  const barChartData = {
    labels: Object.keys(incidentsPerDriver),
    datasets: [
      {
        label: 'Nombre d\'incidents par chauffeur',
        data: Object.values(incidentsPerDriver),
        backgroundColor: 'rgba(54, 162, 235, 0.6)', // Couleur bleue pour les barres
      },
    ],
  };

  // Préparer les données pour le graphique Doughnut
  const doughnutChartData = {
    labels: Object.keys(driverIncidentData.allDrivers),
    datasets: [
      {
        data: Object.values(driverIncidentData.allDrivers),
        backgroundColor: Object.keys(driverIncidentData.allDrivers).map(
          (type) => incidentColors[type] || '#000000'
        ),
      },
    ],
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* Sélecteur de chauffeur */}
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="driver-select-label">Sélectionner un chauffeur</InputLabel>
        <Select
          labelId="driver-select-label"
          value={selectedDriver}
          label="Sélectionner un chauffeur"
          onChange={handleDriverChange}
        >
          <MenuItem value="">
            <em>Tous les chauffeurs</em>
          </MenuItem>
          {staff
            .filter((driver) => !driver.exitdate) // Exclut les chauffeurs avec une exit_date
            .map((driver) => (
              <MenuItem key={driver.id} value={`${driver.name} ${driver.last_name}`}>
                {driver.name} {driver.last_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {/* Tableau des incidents */}
      <TableContainer component={Paper}>
        <Table aria-label="Incidents du personnel">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Type d'incident</TableCell>
              <TableCell>Description de l'incident</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentStaffIncidents.map((incident, index) => (
              <TableRow key={index}>
                <TableCell>{incident.date}</TableCell>
                <TableCell>{incident.firstName}</TableCell>
                <TableCell>{incident.lastName}</TableCell>
                <TableCell>{incident.type}</TableCell>
                <TableCell>{incident.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginY: 2 }}>
        <Pagination
          count={totalStaffPages}
          page={currentStaffPage}
          onChange={(event, page) => paginateStaff(page)}
          color="primary"
        />
      </Box>

      {/* Graphique Doughnut */}
      <Box sx={{ marginTop: 4 }}>
        <Card>
          <CardContent>
            <Typography variant="h6" align="center">
              Répartition des incidents par type
            </Typography>
            <Box sx={{ height: isSmDown ? 250 : 300 }}>
              <Doughnut
                data={doughnutChartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'bottom',
                      labels: {
                        color: muiTheme.palette.text.primary,
                      },
                    },
                  },
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* Graphique en barres */}
      <Box sx={{ marginTop: 4 }}>
        <Card>
          <CardContent>
            <Typography variant="h6" align="center">
              Nombre d'incidents par personne
            </Typography>
            <Box sx={{ height: isSmDown ? 250 : 300 }}>
              <Bar
                data={barChartData}
                options={{
                  indexAxis: 'y', // Barres horizontales
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      ticks: {
                        color: muiTheme.palette.text.primary,
                      },
                      beginAtZero: true,
                    },
                    y: {
                      ticks: {
                        color: muiTheme.palette.text.primary,
                      },
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />

            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default React.memo(StaffIncidents);
