/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-mixed-operators */
// utils/calculateInvoice.js

const calculateInvoice = (tarifApplicable, transportDetails) => {
  console.log('[calculateInvoice] Début de la fonction');
  console.log('[calculateInvoice] Paramètres reçus :', { tarifApplicable, transportDetails });

  if (!tarifApplicable || !transportDetails) {
    console.error('[calculateInvoice] Informations manquantes pour le calcul de la facture');
    return {};
  }

  const {
    selectedTransportType,
    distance,
    waitingTime,
    roundTrip,
    departureTime,
    arrivalTime,
    dayOfWeek,
    aeroport,
    premature,
    tpmr,
    numberOfPeople,
    cancelled,
    urgent,
    tarifTaxi,
  } = transportDetails;

  console.log('[calculateInvoice] Détails du transport :', {
    selectedTransportType,
    distance,
    waitingTime,
    roundTrip,
    departureTime,
    arrivalTime,
    dayOfWeek,
    aeroport,
    premature,
    tpmr,
    numberOfPeople,
    cancelled,
    urgent,
    tarifTaxi,
  });

  let totalMinutes = 0;
  if (waitingTime) {
    const [hours, minutes, seconds] = waitingTime.split(':').map(Number);
    totalMinutes = hours * 60 + minutes + (seconds >= 30 ? 1 : 0); // Arrondir à la minute la plus proche
  }
  else {
    totalMinutes = 0;
  }
  console.log('[calculateInvoice] Temps d\'attente total (en minutes) :', totalMinutes);

  let estimate = 0;
  let totalWithTVA = 0;
  let distanceCharge = 0;

  const tarifbaseKmTaxiApplicable = tarifTaxi;
  console.log('[calculateInvoice] tarifbaseKmTaxiApplicable :', tarifbaseKmTaxiApplicable);

  const isTaxi = tarifApplicable.categorie === 'TAXI';
  const isTaxiAm = tarifApplicable.categorie === 'TAXI-AM';
  const isTaxiAmRem = tarifApplicable.categorie === 'TAXI-AMREM';
  const isParaHC = tarifApplicable.categorie === 'PARAHC' || tarifApplicable.categorie === 'PARAC';
  const isParacOrParahc = isParaHC;
  const isUPH = tarifApplicable.categorie === 'UPH';
  const isAgglomerationOrDepartemental = tarifApplicable.categorie === 'Agglomération' || tarifApplicable.categorie === 'Départemental';
  const baseCharge = parseFloat(tarifApplicable.base);

  console.log('[calculateInvoice] Catégorie du tarif :', tarifApplicable.categorie);
  console.log('[calculateInvoice] baseCharge :', baseCharge);

  // Calcul de la distanceCharge selon la catégorie
  if (isTaxi || isTaxiAm || isTaxiAmRem) {
    distanceCharge = distance * parseFloat(tarifbaseKmTaxiApplicable);
  }
  else if (isAgglomerationOrDepartemental) {
    distanceCharge = distance > 3 ? (distance - 3) * parseFloat(tarifApplicable.basekm) : 0;
  }
  else if (isUPH) {
    distanceCharge = distance > 20 ? (distance - 20) * parseFloat(tarifApplicable.basekm) : 0;
  }
  else {
    distanceCharge = distance * parseFloat(tarifApplicable.basekm);
  }
  console.log('[calculateInvoice] distanceCharge :', distanceCharge);

  // Estimation de base
  estimate = baseCharge + distanceCharge;
  console.log('[calculateInvoice] Estimation de départ (baseCharge + distanceCharge) :', estimate);

  // Ajout de la TVA ou non en fonction du type de transport
  const tvaRate = selectedTransportType === 2 ? parseFloat(tarifApplicable.tva) : 0;
  console.log('[calculateInvoice] Taux de TVA :', tvaRate);

  // Si c'est un petit trajet taxi
  if ((isTaxi || isTaxiAm || isTaxiAmRem) && distance > 0 && distance <= 8) {
    estimate = 15;
    totalWithTVA = estimate * (1 + parseFloat(tarifApplicable.tva) / 100);
    console.log('[calculateInvoice] Course <= 8 km, estimation = 15, totalWithTVA =', totalWithTVA);

    return {
      estimate: estimate.toFixed(2),
      totalWithTVA: totalWithTVA.toFixed(2),
    };
  }

  // Gestion du trajet court
  let valorisationTrajetCourtCharge = 0;
  const conditions = tarifApplicable.tarifscourttrajet.map((trajet) => ({
    min: trajet.condition.includes('>') ? parseFloat(trajet.condition.split('>')[1].split('≤')[0]) : 0,
    max: trajet.condition.includes('≤') ? parseFloat(trajet.condition.split('≤')[1]) : Infinity,
    tarif: parseFloat(trajet.tarif),
  }));

  console.log('[calculateInvoice] Liste des conditions de trajet court :', conditions);

  conditions.sort((a, b) => a.min - b.min);
  for (const condition of conditions) {
    if (distance > condition.min && distance <= condition.max) {
      valorisationTrajetCourtCharge = condition.tarif;
      break;
    }
  }
  console.log('[calculateInvoice] Majoration trajet court :', valorisationTrajetCourtCharge);

  // Calcul de la majoration par tranche de 25 km au-delà de 100 km pour certaines catégories
  let majorationTranche25km = 0;
  if (isParacOrParahc && distance > 100) {
    const valeurMajoration25km = parseFloat(
      tarifApplicable.majorations.find((majoration) => majoration.nom === 'Tranche 25km')?.valeur || 0
    );
    const tranches = Math.floor((distance - 100) / 25);
    majorationTranche25km = tranches * valeurMajoration25km;
    console.log(
      '[calculateInvoice] Distance > 100 km, majorationTranche25km :',
      majorationTranche25km
    );
  }

  estimate += majorationTranche25km + valorisationTrajetCourtCharge;
  console.log('[calculateInvoice] Estimation après trajet court et 25km :', estimate);

  // Calcul du coût de l'attente
  let waitingCost = 0;
  if (isTaxi || isTaxiAm || isTaxiAmRem) {
    const hourlyRate = parseFloat(
      tarifApplicable.majorations.find((m) => m.nom === "Heure d'attente")?.valeur || 0
    );
    waitingCost = (totalMinutes / 60) * hourlyRate; // Coût basé sur la fraction d'heure
    console.log('[calculateInvoice] waitingCost (taxi, AM, AMREM) :', waitingCost);
  }
  else {
    const ratePer15Min = parseFloat(
      tarifApplicable.majorations.find((m) => m.nom === 'Attente 15 min')?.valeur || 0
    );
    const periods = Math.ceil(totalMinutes / 15); // Nombre de périodes de 15 minutes entamées
    waitingCost = periods * ratePer15Min;
    console.log('[calculateInvoice] waitingCost (autres) :', waitingCost);
  }

  // Variables pour les majorations
  let nightRate = 0;
  let holidayRate = 0;
  let aeroportMajoration = 0;
  let prematureMajoration = 0;
  let tpmrMajoration = 0;
  let cancelledBill = 0;
  let urgentMajoration = 0;

  const isNight = (departureTime > '20:00' || departureTime < '08:00')
    || (arrivalTime !== '00:00:00' && (arrivalTime > '20:00' || arrivalTime < '08:00'));
  const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // 0 pour dimanche, 6 pour samedi
  const isSaturday = dayOfWeek === 6;
  const isSaturdayAfternoon = isSaturday && departureTime >= '12:00';

  console.log('[calculateInvoice] isNight ?', isNight);
  console.log('[calculateInvoice] isWeekend ?', isWeekend);
  console.log('[calculateInvoice] isSaturdayAfternoon ?', isSaturdayAfternoon);

  // Application des majorations
  tarifApplicable.majorations.forEach((majoration) => {
    switch (majoration.nom) {
      case 'Nuit':
        if (isNight) {
          nightRate = (baseCharge + distanceCharge + valorisationTrajetCourtCharge)
            * (parseFloat(majoration.valeur) / 100);
          console.log('[calculateInvoice] Majoration Nuit :', nightRate);
        }
        break;
      case 'Dim/férié':
        if (isWeekend) {
          holidayRate = (baseCharge + distanceCharge + valorisationTrajetCourtCharge)
            * (parseFloat(majoration.valeur) / 100);
          console.log('[calculateInvoice] Majoration Dim/férié :', holidayRate);
        }
        break;
      case 'Aéroport/Gare':
        if (aeroport) {
          aeroportMajoration = parseFloat(majoration.valeur);
          console.log('[calculateInvoice] Majoration Aéroport/Gare :', aeroportMajoration);
        }
        break;
      case 'Prématuré':
        if (premature) {
          prematureMajoration = parseFloat(majoration.valeur);
          console.log('[calculateInvoice] Majoration Prématuré :', prematureMajoration);
        }
        break;
      case 'TPMR':
        if (tpmr) {
          tpmrMajoration = parseFloat(majoration.valeur);
          console.log('[calculateInvoice] Majoration TPMR :', tpmrMajoration);
        }
        break;
      case 'Annuler facturé':
        if (cancelled) {
          cancelledBill = parseFloat(majoration.valeur);
          console.log('[calculateInvoice] Majoration Annuler facturé :', cancelledBill);
        }
        break;
      case 'Urgent':
        if (urgent) {
          urgentMajoration = parseFloat(majoration.valeur);
          console.log('[calculateInvoice] Majoration Urgent :', urgentMajoration);
        }
        break;
      case 'Nuit/Férié/Week-end':
        if (isParaHC && (isNight || isWeekend)) {
          // On augmente la valorisation de trajet court si le trajet se passe la nuit / weekend
          valorisationTrajetCourtCharge
            *= 1 + parseFloat(majoration.valeur) / 100;
          console.log('[calculateInvoice] Majoration Nuit/Férié/Week-end (paraHC) :', valorisationTrajetCourtCharge);
        }
        break;
      default:
        break;
    }
  });

  // Appliquer majoration 50% pour les samedis après 12:00 (sauf PARA et SAMU)
  if (isSaturdayAfternoon && !(isParaHC || tarifApplicable.categorie === 'SAMU')) {
    estimate *= 1.5;
    console.log('[calculateInvoice] Majoration samedi après 12h, nouvelle estimation :', estimate);
  }

  estimate
    += nightRate
    + holidayRate
    + aeroportMajoration
    + prematureMajoration
    + tpmrMajoration
    + cancelledBill
    + urgentMajoration
    + waitingCost;

  console.log('[calculateInvoice] Estimation après ajout de toutes les majorations :', estimate);

  // Remise pour plusieurs personnes
  let remisePercent = 0;
  const numPeople = Number(numberOfPeople);
  if (numPeople === 2) {
    remisePercent = parseFloat(tarifApplicable.reductiondeuxpersonnes);
  }
  else if (numPeople === 3) {
    remisePercent = parseFloat(tarifApplicable.reductiontroispersonnes);
  }

  let remiseSimultane = 0;
  if (remisePercent > 0) {
    remiseSimultane = estimate * (remisePercent / 100);
    estimate -= remiseSimultane;
    console.log('[calculateInvoice] Remise simultanée pour', numPeople, 'personne(s) :', remiseSimultane);
  }

  // Application de la TVA
  if (tvaRate > 0) {
    totalWithTVA = estimate * (1 + tvaRate / 100);
  }
  else {
    totalWithTVA = estimate;
  }
  console.log('[calculateInvoice] totalWithTVA avant aller-retour :', totalWithTVA);

  // Gestion de l'aller-retour
  if (roundTrip) {
    estimate *= 2;
    totalWithTVA *= 2;
    console.log('[calculateInvoice] Aller-retour cochée, x2 => estimate :', estimate, ', totalWithTVA :', totalWithTVA);
  }

  // Course annulée ?
  if (cancelled) {
    estimate = cancelledBill;
    totalWithTVA = estimate; // Ou totalWithTVA = cancelledBill * (1 + tvaRate / 100) si nécessaire
    console.log('[calculateInvoice] Course annulée => estimate :', estimate, ', totalWithTVA :', totalWithTVA);
  }

  const result = {
    totalWithTVA: totalWithTVA.toFixed(2),
    distanceCharge: distanceCharge.toFixed(2),
    nightRate: nightRate.toFixed(2),
    holidayRate: holidayRate.toFixed(2),
    waitingCost: waitingCost.toFixed(2),
    valorisationTrajetCourtCharge: valorisationTrajetCourtCharge.toFixed(2),
    remiseSimultane: remiseSimultane.toFixed(2),
    aeroportMajoration: aeroportMajoration.toFixed(2),
    prematureMajoration: prematureMajoration.toFixed(2),
    tpmrMajoration: tpmrMajoration.toFixed(2),
    cancelledBill: cancelledBill.toFixed(2),
    urgentMajoration: urgentMajoration.toFixed(2),
    majorationTranche25km: majorationTranche25km.toFixed(2),
  };

  console.log('[calculateInvoice] Résultat final :', result);
  console.log('[calculateInvoice] Fin de la fonction');

  return result;
};

export default calculateInvoice;
