/* eslint-disable react/prop-types */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
// components/Devis/Devis.jsx
import React, { useEffect, useState } from 'react';
import {
  Grid, FormControl, TextField, MenuItem, Button,
  FormControlLabel, Checkbox, InputLabel, Select,
  Typography, Box, Paper, Snackbar, Alert,
  Stepper, Step, StepLabel, Autocomplete
} from '@mui/material';
import axios from 'axios';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Holidays from 'date-holidays';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../../utils/api';
import Logo from '../../../../images/logoJussieu.png';
import AmbysoftLoader from '../../../AmbysoftLoader/AmbysoftLoader';
import priseEnChargeData from '../../../../utils/priseEnCharge.json';
import agglomerationData from '../../../../utils/agglomeration.json';
import DistanceCalculator from '../../../hooks/distanceCalculator';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const steps = [
  'Informations de l\'expéditeur',
  'Informations du destinataire',
  'Détails de la mission',
  'Adresse de prise en charge et destination',
  'Résumé et Génération du devis'
];

function Devis({ userToken }) {
  const location = useLocation();
  const navigate = useNavigate();
  const initialValues = location.state?.initialValues;
  // États principaux
  const [activeStep, setActiveStep] = useState(0);
  const defaultFormValues = {
    // Expéditeur
    selectedCompany: null,
    // Destinataire
    recipientName: '',
    recipientPostalCode: '',
    selectedRecipientCity: '',
    recipientAddress: '',
    recipientPhone: '',
    recipientEmail: '',
    // Détails de la mission
    selectedTransportType: '',
    selectedCategory: '',
    // Adresse de prise en charge et destination
    address: '',
    postalCode: '',
    selectedCity: '',
    destinationAddress: '',
    destinationPostalCode: '',
    selectedDestinationCity: '',
    distanceKm: 0,
    waitingTime: 0,
    tolls: 0,
    nonReimbursableSupplement: 0,
    comment: '',
    roundTrip: false,
    aeroport: false,
    premature: false,
    numberOfPeople: '',
    tpmr: false,
    // Autres
    departureTime: '',
    arrivalTime: '',
    date: ''
  };
  const [formValues, setFormValues] = useState(initialValues || defaultFormValues);
  const [isGenerating, setIsGenerating] = useState(false);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [inputAddress, setInputAddress] = useState('');
  const [destinationAddressSuggestions, setDestinationAddressSuggestions] = useState([]);
  const [inputDestinationAddress, setInputDestinationAddress] = useState('');
  const [etablissementsData, setEtablissementsData] = useState([]);
  const [inputDepartureLabel, setInputDepartureLabel] = useState('');
  const [inputDestinationLabel, setInputDestinationLabel] = useState('');

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tarifs, setTarifs] = useState([]);
  const [typesDeTarif, setTypesDeTarif] = useState([]);
  const [forfait, setForfait] = useState('');
  const [cities, setCities] = useState([]);
  const [recipientCities, setRecipientCities] = useState([]);
  const [destinationCities, setDestinationCities] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [logoDataURL, setLogoDataURL] = useState('');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  // Fonction pour charger l'image du logo en Base64
  const getBase64Image = (imgUrl, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', imgUrl);
    xhr.responseType = 'blob';
    xhr.send();
  };

  useEffect(() => {
    getBase64Image(Logo, (base64Img) => {
      setLogoDataURL(base64Img);
    });
  }, []);

  // Fonctions pour récupérer les données depuis l'API
  const fetchInitialData = async () => {
    try {
      const [companiesRes, tarifsRes, typesRes] = await Promise.all([
        axios.get(`${BASE_URL}companies`, { headers }),
        axios.get(`${BASE_URL}tarifs`, { headers }),
        axios.get(`${BASE_URL}types-de-tarif`, { headers }),
      ]);
      setCompanies(companiesRes.data.companies);
      setTarifs(tarifsRes.data.tarifs);
      setTypesDeTarif(typesRes.data.typesDeTarif);
      setLoading(false);
    }
    catch (error) {
      console.error(error);
      setSnackbar({ open: true, message: 'Erreur lors du chargement des données.', severity: 'error' });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    axios.get(`${BASE_URL}etablissements`, { headers })
      .then((response) => {
        setEtablissementsData(response.data.etablissements);
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des établissements:', error);
      });
  }, []);

  const fetchCities = async (codePostal) => {
    try {
      const response = await axios.get(`https://geo.api.gouv.fr/communes?codePostal=${codePostal}`);
      setCities(response.data);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des villes:', error);
      setSnackbar({ open: true, message: 'Erreur lors de la récupération des villes.', severity: 'error' });
    }
  };

  const fetchRecipientCities = async (codePostal) => {
    try {
      const response = await axios.get(`https://geo.api.gouv.fr/communes?codePostal=${codePostal}`);
      setRecipientCities(response.data);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des villes destinataire:', error);
      setSnackbar({ open: true, message: 'Erreur lors de la récupération des villes destinataire.', severity: 'error' });
    }
  };

  const fetchDestinationCities = async (codePostal) => {
    try {
      const response = await axios.get(`https://geo.api.gouv.fr/communes?codePostal=${codePostal}`);
      setDestinationCities(response.data);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des villes destination:', error);
      setSnackbar({ open: true, message: 'Erreur lors de la récupération des villes destination.', severity: 'error' });
    }
  };

  // Gestion des changements de champs
  const handleInputChange = (e) => {
    const {
      name, value, type, checked
    } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const fetchAddresses = async (query) => {
    try {
      const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}&limit=5`);
      return response.data;
    }
    catch (error) {
      console.error('Erreur lors de la récupération des adresses:', error);
      return null;
    }
  };

  const handleAddressChange = async (event, newValue) => {
    setInputAddress(newValue);
    if (newValue) {
      const results = await fetchAddresses(newValue);
      if (results && results.features) {
        setAddressSuggestions(results.features);
      }
    }
    else {
      setAddressSuggestions([]);
    }
  };

  const handleDestinationAddressChange = async (event, newValue) => {
    setInputDestinationAddress(newValue);
    if (newValue) {
      const results = await fetchAddresses(newValue);
      if (results && results.features) {
        setDestinationAddressSuggestions(results.features);
      }
    }
    else {
      setDestinationAddressSuggestions([]);
    }
  };

  const handleCompanyChange = (event, value) => {
    setFormValues((prev) => ({
      ...prev,
      selectedCompany: value,
      companyAddress: value?.address || '',
      companyPostal: value?.boitepostale || '',
      companyPhone: value?.phone || '',
      companyEmail: value?.email || '',
      companySiret: value?.siret || '',
      companyApe: value?.codeape || '',
      companyIdentification: value?.numeroidentification || '',
      companyTva: value?.numerotva || '',
      companyIban: value?.iban || '',
      companyBic: value?.bic || '',
    }));
  };

  const handleTransportTypeChange = (e) => {
    const selectedType = e.target.value;
    setFormValues((prev) => ({
      ...prev,
      selectedTransportType: selectedType,
      selectedCategory: ''
    }));
  };

  // Fonction pour vérifier l'éligibilité aux forfaits spéciaux
  useEffect(() => {
    const checkEligibilityForSpecialForfaits = () => {
      const { postalCode, selectedCity } = formValues;
      if (!postalCode || !selectedCity) return 'Départemental';
      const departmentCode = postalCode.substring(0, 2);

      // Vérification pour le forfait de prise en charge
      for (const department in priseEnChargeData.PriseEnCharge) {
        const eligibleDepartmentsOrCities = priseEnChargeData.PriseEnCharge[department];
        if (
          eligibleDepartmentsOrCities.includes(parseInt(departmentCode, 10))
          || eligibleDepartmentsOrCities.includes(selectedCity)
        ) {
          return 'PEC';
        }
      }

      // Vérification pour le forfait agglomération
      for (const department in agglomerationData.ForfaitAgglomeration) {
        for (const agglomeration in agglomerationData.ForfaitAgglomeration[department]) {
          const agglomerationCities = agglomerationData.ForfaitAgglomeration[department][agglomeration];
          if (Array.isArray(agglomerationCities) && agglomerationCities.includes(selectedCity)) {
            return 'Agglomération';
          }
        }
      }

      // Si la ville n'est éligible à aucun des deux forfaits spéciaux
      return 'Départemental';
    };

    const updateForfait = () => {
      const selectedTarifsFiltered = tarifs.filter(
        (tarif) => tarif.typedetarifid === parseInt(formValues.selectedTransportType, 10)
      );
      let forfaitApplique = 'Départemental';

      if (selectedTarifsFiltered.length > 0) {
        const category = selectedTarifsFiltered[0].categorie;
        if (['Agglomération', 'Départemental', 'PEC'].includes(category)) {
          forfaitApplique = checkEligibilityForSpecialForfaits();
        }
        else {
          forfaitApplique = category;
        }
      }

      setForfait(forfaitApplique);
    };

    updateForfait();
  }, [formValues.selectedCity, formValues.postalCode, formValues.selectedTransportType, tarifs]);

  // Fonction pour passer à l'étape suivante
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Fonction pour revenir à l'étape précédente
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEstimate = async (e) => {
    e.preventDefault();
    setIsGenerating(true); // Commencer le chargement

    const transportTypeId = parseInt(formValues.selectedTransportType, 10); // S'assurer que c'est un nombre

    // Trouver l'objet dans typesDeTarif correspondant à l'ID
    const foundType = typesDeTarif.find((type) => type.id === transportTypeId);

    // Utiliser le champ 'nom' de l'objet trouvé pour afficher le nom du type dans le devis
    const typeName = foundType ? foundType.nom : 'Type non spécifié';
    // Récupérer les données du formulaire
    const data = formValues;
    const estimateNumber = Math.floor(Math.random() * 1000000); // Génère un numéro de devis aléatoire
    const estimateDate = new Date().toLocaleDateString(); // Date du jour
    const validityDate = new Date();
    validityDate.setMonth(validityDate.getMonth() + 1);

    // Fonction pour vérifier les jours fériés ou week-end
    function isHolidayOrWeekend(departureDateTime) {
      const hd = new Holidays('FR'); // Crée une instance pour la France
      const holidays = hd.getHolidays(departureDateTime.getFullYear());
      const dateStr = departureDateTime.toISOString().split('T')[0];
      const isHoliday = holidays.some((holiday) => holiday.date === dateStr);

      const isSunday = departureDateTime.getDay() === 0;
      const isSaturdayAfterNoon = departureDateTime.getDay() === 6 && departureDateTime.getHours() >= 12;

      return isHoliday || isSunday || isSaturdayAfterNoon;
    }

    const transportDate = new Date(data.date);
    const { departureTime } = data; // "HH:MM"
    const [hours, minutes] = departureTime.split(':').map(Number);
    const departureDateTime = new Date(transportDate);
    departureDateTime.setHours(hours, minutes, 0, 0);

    const isSundayOrHoliday = isHolidayOrWeekend(departureDateTime);

    const formattedDate = transportDate.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    });
    const distance = parseFloat(data.distanceKm);
    const waitingTime = parseFloat(data.waitingTime) || 0;
    const { roundTrip } = data;
    const { arrivalTime } = data;
    const tolls = parseFloat(data.tolls) || 0;
    const nonReimbursableSupplement = parseFloat(data.nonReimbursableSupplement) || 0;
    const company = data.selectedCompany;
    const recipientDetails = `${data.recipientName}\n${data.recipientAddress}\n${data.recipientPhone}\n${data.recipientEmail}`;
    const issuingCompanyDetails = `${company?.companyname || ''}\n${data.companyAddress}\n${data.companyPostal}\n${data.companyPhone}\n${data.companyEmail}\nSIRET: ${data.companySiret}\nCode APE: ${data.companyApe}\nN° d'identification: ${data.companyIdentification}\nN° de TVA intra: ${data.companyTva}\nIBAN: ${data.companyIban}\nBIC: ${data.companyBic}`;

    // Trouver le tarif applicable
    const tarifApplicable = tarifs.find(
      (tarif) => tarif.typedetarifid === parseInt(data.selectedTransportType, 10)
        && (data.selectedCategory ? tarif.categorie === data.selectedCategory : tarif.categorie.toLowerCase() === forfait.toLowerCase())
    );

    if (!tarifApplicable) {
      setSnackbar({ open: true, message: 'Aucun tarif applicable trouvé pour le type de transport et la catégorie sélectionnés.', severity: 'error' });
      return;
    }

    // Calculs des tarifs
    const isParaHC = tarifApplicable.categorie === 'PARAHC' || tarifApplicable.categorie === 'PARAC';
    const isParacOrParahc = isParaHC;
    const isUPH = tarifApplicable.categorie === 'UPH';
    const isAgglomerationOrDepartemental = tarifApplicable.categorie === 'Agglomération' || tarifApplicable.categorie === 'Départemental';

    const baseCharge = parseFloat(tarifApplicable.base);
    let distanceCharge;
    if (isAgglomerationOrDepartemental) {
      distanceCharge = distance > 3 ? (distance - 3) * parseFloat(tarifApplicable.basekm) : 0;
    }
    else if (isUPH) {
      distanceCharge = distance > 20 ? (distance - 20) * parseFloat(tarifApplicable.basekm) : 0;
    }
    else {
      distanceCharge = distance * parseFloat(tarifApplicable.basekm);
    }

    let kilometresFactures;
    if (isAgglomerationOrDepartemental) {
      kilometresFactures = distance > 3 ? distance - 3 : 0;
    }
    else if (isUPH) {
      kilometresFactures = distance > 20 ? distance - 20 : 0;
    }
    else {
      kilometresFactures = distance;
    }

    const tvaRate = data.selectedTransportType === 2 ? parseFloat(tarifApplicable.tva) : 0;

    let valorisationTrajetCourtCharge = 0;
    const conditions = tarifApplicable.tarifscourttrajet.map((trajet) => ({
      min: trajet.condition.includes('>') ? parseFloat(trajet.condition.split('>')[1].split('≤')[0]) : 0,
      max: trajet.condition.includes('≤') ? parseFloat(trajet.condition.split('≤')[1]) : Infinity,
      tarif: parseFloat(trajet.tarif)
    }));

    conditions.sort((a, b) => a.min - b.min);

    for (const condition of conditions) {
      if (distance > condition.min && distance <= condition.max) {
        valorisationTrajetCourtCharge = condition.tarif;
        break;
      }
    }

    let miseADispositionRate = 0;
    if (data.selectedTransportType === 'Mise à disposition') {
      miseADispositionRate = parseFloat(data.tarif) || 0;
    }

    let majorationTranche25km = 0;
    const isNight = departureTime > '20:00' || departureTime < '08:00' || arrivalTime > '20:00' || arrivalTime < '08:00';
    const numberOfPeople = parseInt(data.numberOfPeople || '1', 10);
    const { tpmr } = data;
    const { aeroport } = data;
    const { premature } = data;

    let nightRate = 0; let holidayRate = 0; let aeroportMajoration = 0; let prematureMajoration = 0; let
      tpmrMajoration = 0;
    const waitingMajoration = tarifApplicable.majorations.find((majoration) => majoration.nom === 'Attente 15 min');
    const waitingRate = waitingMajoration ? parseFloat(waitingMajoration.valeur) : 0;

    tarifApplicable.majorations.forEach((majoration) => {
      switch (majoration.nom) {
        case 'Nuit':
          if (isNight) nightRate = (baseCharge + distanceCharge + valorisationTrajetCourtCharge) * (parseFloat(majoration.valeur) / 100);
          break;
        case 'Dim/férié':
          if (isSundayOrHoliday) holidayRate = (baseCharge + distanceCharge + valorisationTrajetCourtCharge) * (parseFloat(majoration.valeur) / 100);
          break;
        case 'Aéroport/Gare':
          if (aeroport) aeroportMajoration = parseFloat(majoration.valeur);
          break;
        case 'Prématuré':
          if (premature) prematureMajoration = parseFloat(majoration.valeur);
          break;
        case 'TPMR':
          if (tpmr) tpmrMajoration = parseFloat(majoration.valeur);
          break;
        case 'Nuit/Férié/Week-end':
          if (isParacOrParahc && (isNight || isSundayOrHoliday)) {
            valorisationTrajetCourtCharge *= (1 + parseFloat(majoration.valeur) / 100);
          }
          break;
        default:
          break;
      }
    });

    if (isParacOrParahc && distance > 100) {
      const valeurMajoration25km = parseFloat(tarifApplicable.majorations.find((majoration) => majoration.nom === 'Tranche 25km')?.valeur || 0);
      const tranches = Math.floor((distance - 100) / 25);
      majorationTranche25km = tranches * valeurMajoration25km;
    }

    let remise = 0;
    if (numberOfPeople === 2) {
      remise = parseFloat(tarifApplicable.reductiondeuxpersonnes) || 0;
    }
    else if (numberOfPeople === 3) {
      remise = parseFloat(tarifApplicable.reductiontroispersonnes) || 0;
    }

    const waitingRateTotal = waitingTime * waitingRate;

    const majorationsTableLines = [];

    if (nightRate > 0) {
      majorationsTableLines.push(['Tarif de nuit', `${nightRate.toFixed(2)} €`, '']);
    }
    if (holidayRate > 0) {
      majorationsTableLines.push(['Tarif dimanche/jour férié', `${holidayRate.toFixed(2)} €`, '']);
    }
    if (aeroport && aeroportMajoration > 0) {
      majorationsTableLines.push(['Tarif Aéroport', `${aeroportMajoration.toFixed(2)} €`, '']);
    }
    if (premature && prematureMajoration > 0) {
      majorationsTableLines.push(['Tarif Prématuré', `${prematureMajoration.toFixed(2)} €`, '']);
    }
    if (tpmr && tpmrMajoration > 0) {
      majorationsTableLines.push(['Majoration TPMR', `${tpmrMajoration.toFixed(2)} €`, '']);
    }
    if (waitingRateTotal > 0) {
      majorationsTableLines.push(['Tarif d\'attente', `${waitingRateTotal.toFixed(2)} €`, '']);
    }

    let estimate = baseCharge + distanceCharge + waitingRateTotal + tolls + nonReimbursableSupplement + miseADispositionRate + valorisationTrajetCourtCharge + majorationTranche25km + nightRate + holidayRate + aeroportMajoration + prematureMajoration + tpmrMajoration;

    let remisePercent = 0;
    if (numberOfPeople === 2) {
      remisePercent = parseFloat(tarifApplicable.reductiondeuxpersonnes) || 0;
    }
    else if (numberOfPeople === 3) {
      remisePercent = parseFloat(tarifApplicable.reductiontroispersonnes) || 0;
    }

    if (remisePercent > 0) {
      const remiseAmount = estimate * (remisePercent / 100);
      estimate -= remiseAmount;
    }

    let totalWithTVA = estimate;
    if (tvaRate > 0) {
      const tvaAmount = estimate * (tvaRate / 100);
      totalWithTVA += tvaAmount;
    }

    let roundTripRate = 0;
    if (roundTrip) {
      roundTripRate = baseCharge + distanceCharge;
      estimate += roundTripRate;
    }

    // Création du tableau des détails
    const detailsTable = [
      [{ text: 'Détails', fillColor: '#cccccc' }, { text: 'Tarif HT', fillColor: '#cccccc' }, { text: `Tarif TTC${tvaRate > 0 ? ` (TVA ${tvaRate}%)` : ''}`, fillColor: '#cccccc' }],
      // Majoration tranche 25 km
      ...(majorationTranche25km > 0 ? [['Majoration tranche 25 km', `${majorationTranche25km.toFixed(2)} €`, '']] : []),
      // Prise en charge
      ['Prise en charge', `${baseCharge.toFixed(2)} €`, `${(baseCharge * (1 + tvaRate / 100)).toFixed(2)} €`],
      // Tarif kilométrique
      [`Tarif kilométrique (${kilometresFactures} km à ${tarifApplicable.basekm} €/km)`, `${distanceCharge.toFixed(2)} €`, `${(distanceCharge * (1 + tvaRate / 100)).toFixed(2)} €`],
      // Valorisation court trajet
      ['Valorisation court trajet', `${valorisationTrajetCourtCharge.toFixed(2)} €`, `${(valorisationTrajetCourtCharge * (1 + tvaRate / 100)).toFixed(2)} €`],
      // Péages
      // Étend un seul sous-tableau représentant une ligne
      ...(tolls > 0 ? [['Péages', `${tolls.toFixed(2)} €`, `${(tolls * (1 + tvaRate / 100)).toFixed(2)} €`]] : []),
      // Supplément non remboursable
      ...(nonReimbursableSupplement > 0 ? [['Supplément non remboursable', `${nonReimbursableSupplement.toFixed(2)} €`, `${(nonReimbursableSupplement * (1 + tvaRate / 100)).toFixed(2)} €`]] : []),
      // Tarif retour
      ...(roundTripRate > 0 ? [['Tarif retour', `${roundTripRate.toFixed(2)} €`, `${(roundTripRate * (1 + tvaRate / 100)).toFixed(2)} €`]] : []),
      // Tarification mise à disposition
      ...(miseADispositionRate > 0 ? [['Tarification mise à disposition', `${miseADispositionRate.toFixed(2)} €`, `${(miseADispositionRate * (1 + tvaRate / 100)).toFixed(2)} €`]] : []),
      // Remise
      ...(remise > 0 ? [[`Remise pour nombre de personnes (${remisePercent}%)`, `-${remise.toFixed(2)} €`, '']] : []),
      // Majoration supplémentaires
      ...majorationsTableLines,
      // Total HT
      ...(data.selectedTransportType === 2 ? [
        ['', '', ''],
        ['Total HT', `${estimate.toFixed(2)} €`, ''],
        ['TVA', '', `${(estimate * tvaRate / 100).toFixed(2)} €`],
        [{ text: 'Total TTC', fillColor: '#ffff00' }, '', { text: `${totalWithTVA.toFixed(2)} €`, bold: true, fillColor: '#ffff00' }]
      ] : [
        [{ text: 'Total', fillColor: '#ffff00' }, { text: `${estimate.toFixed(2)} €`, bold: true, fillColor: '#ffff00' }, '']
      ])
    ];

    // Ajout des notes de bas de page
    detailsTable.push([
      {
        colSpan: 3,
        italics: true,
        fontSize: 10,
        text: 'Veuillez noter que ce devis est basé sur les informations fournies et peut être sujet à des ajustements en fonction des conditions réelles du transport.',
      },
      {}, {}
    ]);

    if (data.selectedTransportType === 'Mise à disposition') {
      detailsTable.push([
        [{ text: 'Détails', fillColor: '#cccccc' }, { text: 'TVA', fillColor: '#cccccc' }, { text: 'Tarif TTC', fillColor: '#cccccc' }],
        ['Tarification mise à disposition', '', `${miseADispositionRate.toFixed(2)} €`],
        [{ text: 'Total', fillColor: '#ffff00' }, '', { text: `${miseADispositionRate.toFixed(2)} €`, bold: true, fillColor: '#ffff00' }],
        [
          {
            colSpan: 3,
            italics: true,
            fontSize: 10,
            text: 'Veuillez noter que ce devis est basé sur les informations fournies et peut être sujet à des ajustements en fonction des conditions réelles du transport. Le paiement de celui-ci devra être effectué 24h avant la prestation.',
          },
          {}, {},
        ],
      ]);
    }

    // Création du PDF
    const docDefinition = {
      content: [
        {
          columns: [
            {
              image: logoDataURL,
              width: 200,
              alignment: 'top'
            },
            [
              {
                text: `Date du devis: ${estimateDate}`,
                alignment: 'right'
              },
              {
                text: `Numéro de devis: ${estimateNumber}`,
                alignment: 'right'
              },
              {
                text: `Date de validité: ${validityDate.toLocaleDateString()}`,
                alignment: 'right'
              }
            ]
          ]
        },
        {
          columns: [
            { text: issuingCompanyDetails, style: 'subheader' },
            { text: recipientDetails, style: 'subheader', alignment: 'right' }
          ]
        },
        { text: `Objet: Demande de tarification en ${typeName}`, margin: [0, 20, 0, 0] },
        { text: 'Madame, Monsieur,', margin: [0, 10, 0, 0] },
        { text: `Nous avons le plaisir de vous adresser notre devis pour votre demande de tarification en ${typeName}, le ${formattedDate} avec une heure de début prévue pour ${departureTime} et une heure de fin prévue pour ${arrivalTime}.`, margin: [0, 10, 0, 0] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*'],
            body: detailsTable,
          },
        },
        { text: `Informations supplémentaires: ${data.comment || 'Aucune'}`, margin: [0, 10, 0, 0] },
        { text: 'Nous restons à votre disposition pour toute information complémentaire.', margin: [0, 10, 0, 0] },
        { text: '\nDate et Signature du client précédée de la mention "Bon pour accord"', style: 'signature' },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 12,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          fontSize: 10,
          margin: [0, 5, 0, 15]
        },
        signature: {
          fontSize: 10,
          margin: [0, 10, 0, 10]
        },
      },
      defaultStyle: {
        columnGap: 20,
      },
    };

    // Après la génération du PDF
    pdfMake.createPdf(docDefinition).getBlob(async (blob) => {
      const fileName = `Devis_${estimateNumber}_${data.recipientName}.pdf`;

      try {
        // Obtenir une URL présignée pour l'upload
        const presignedResponse = await axios.post(`${BASE_URL}devis/presigned`, { name: fileName, type: 'application/pdf' }, { headers });
        const { url, fields } = presignedResponse.data;

        // Préparer les données pour l'upload
        const uploadData = new FormData();
        Object.entries(fields).forEach(([key, value]) => {
          uploadData.append(key, value);
        });
        uploadData.append('file', blob, fileName);

        // Uploader le fichier vers S3
        await axios.post(url, uploadData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // Le key du fichier uploadé est dans fields.key
        const pdfFileKey = fields.key;

        // Enregistrement du devis dans la base de données
        const devisData = {
          estimate_number: estimateNumber,
          date_created: new Date(),
          validity_date: validityDate,
          status: 'Devis généré',
          data: formValues,
          pdf_file_url: pdfFileKey, // Stocker le key du fichier
        };

        const response = await axios.post(`${BASE_URL}devis`, devisData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.status === 201) {
          setSnackbar({ open: true, message: 'Devis généré et sauvegardé avec succès.', severity: 'success' });
          setActiveStep(0);
          setFormValues(defaultFormValues);
          // Rediriger vers le composant GestionDevis après un court délai pour que le Snackbar soit visible
          setTimeout(() => {
            navigate('/facturationManagement/devis');
          }, 1000); // Vous pouvez ajuster le délai si nécessaire
        }
        else {
          setSnackbar({ open: true, message: 'Erreur lors de la sauvegarde du devis.', severity: 'error' });
        }
      }
      catch (error) {
        console.error('Erreur lors de l\'upload du PDF ou de la sauvegarde du devis:', error);
        setSnackbar({ open: true, message: 'Erreur lors de la sauvegarde du devis.', severity: 'error' });
      }
      finally {
        setIsGenerating(false); // Fin du chargement
      }
    });
  };

  if (loading) {
    return <AmbysoftLoader />;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        padding: 4,
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: 1200,
        margin: 'auto',
      }}
    >
      {isGenerating && (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <CircularProgress size={60} />
        <Typography variant="body1" sx={{ mt: 2, color: '#fff' }}>
          Génération du devis en cours, veuillez patienter...
        </Typography>
      </Box>
      )}
      <Typography variant="h2" align="center" gutterBottom>Générer un devis</Typography>

      {/* Stepper */}
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <form onSubmit={handleEstimate}>
        {activeStep === 0 && (
        /* Étape 1 : Informations de l'expéditeur */
        <Box sx={{ mt: 2 }}>
          <Paper sx={{ padding: 3, backgroundColor: '#ffffff' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={companies}
                    getOptionLabel={(option) => option.companyname}
                    value={formValues.selectedCompany}
                    onChange={handleCompanyChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nom de la société"
                        name="companyName"
                        required
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Adresse"
                  type="text"
                  name="companyAddress"
                  InputLabelProps={{ shrink: true }}
                  value={formValues.companyAddress}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Boîte postale"
                  type="text"
                  name="companyPostal"
                  InputLabelProps={{ shrink: true }}
                  value={formValues.companyPostal}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Numéro de téléphone"
                  type="text"
                  name="companyPhone"
                  InputLabelProps={{ shrink: true }}
                  value={formValues.companyPhone}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Email"
                  type="email"
                  name="companyEmail"
                  InputLabelProps={{ shrink: true }}
                  value={formValues.companyEmail}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="SIRET"
                  type="text"
                  name="companySiret"
                  InputLabelProps={{ shrink: true }}
                  value={formValues?.companySiret}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Code APE"
                  type="text"
                  name="companyApe"
                  InputLabelProps={{ shrink: true }}
                  value={formValues?.companyApe}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Numéro d'identification"
                  type="text"
                  name="companyIdentification"
                  InputLabelProps={{ shrink: true }}
                  value={formValues?.companyIdentification}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Numéro de TVA intra"
                  type="text"
                  name="companyTva"
                  InputLabelProps={{ shrink: true }}
                  value={formValues?.companyTva}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="IBAN"
                  type="text"
                  name="companyIban"
                  InputLabelProps={{ shrink: true }}
                  value={formValues?.companyIban}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="BIC"
                  type="text"
                  name="companyBic"
                  InputLabelProps={{ shrink: true }}
                  value={formValues?.companyBic}
                  disabled
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
        )}

        {activeStep === 1 && (
        /* Étape 2 : Informations du destinataire */
        <Box sx={{ mt: 2 }}>
          <Paper sx={{ padding: 3, backgroundColor: '#ffffff' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Nom / Prénom / Raison sociale"
                  type="text"
                  name="recipientName"
                  value={formValues.recipientName}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Adresse"
                  type="text"
                  name="recipientAddress"
                  value={formValues.recipientAddress}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Email"
                  type="email"
                  name="recipientEmail"
                  value={formValues.recipientEmail}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Code Postal"
                  type="text"
                  name="recipientPostalCode"
                  value={formValues.recipientPostalCode}
                  onChange={(e) => {
                    handleInputChange(e);
                    fetchRecipientCities(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Numéro de téléphone"
                  type="text"
                  name="recipientPhone"
                  value={formValues.recipientPhone}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="recipient-city-label">Ville</InputLabel>
                  <Select
                    sx={{ width: '100%' }}
                    labelId="recipient-city-label"
                    label="Ville"
                    id="recipient-city-select"
                    name="selectedRecipientCity"
                    value={formValues.selectedRecipientCity}
                    onChange={handleInputChange}
                  >
                    {recipientCities.map((city) => (
                      <MenuItem key={city.code} value={city.nom}>
                        {city.nom}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        )}

        {activeStep === 2 && (
        /* Étape 3 : Détails de la mission */
        <Box sx={{ mt: 2 }}>
          <Paper sx={{ padding: 3, backgroundColor: '#ffffff' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="transport-type-label">Mode de transport</InputLabel>
                  <Select
                    label="Mode de transport"
                    labelId="transport-type-label"
                    id="transport-type-select"
                    name="selectedTransportType"
                    value={formValues.selectedTransportType}
                    onChange={handleTransportTypeChange}
                    required
                  >
                    {typesDeTarif.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.nom}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {formValues.selectedTransportType && tarifs.filter((tarif) => tarif.typedetarifid === parseInt(formValues.selectedTransportType, 10)).length > 0 && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="category-label">Tarification</InputLabel>
                  <Select
                    labelId="category-label"
                    id="category-select"
                    name="selectedCategory"
                    value={formValues.selectedCategory}
                    label="Catégorie"
                    onChange={handleInputChange}
                    required
                  >
                    {tarifs
                      .filter((tarif) => tarif.typedetarifid === parseInt(formValues.selectedTransportType, 10))
                      .map((tarif) => (
                        <MenuItem key={tarif.categorie} value={tarif.categorie}>
                          {tarif.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              )}
              {formValues.selectedTransportType !== 'Mise à disposition' && (
              <>
                <Grid item xs={12}>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      label="Date de la mission"
                      type="date"
                      name="date"
                      value={formValues.date}
                      onChange={(e) => setFormValues({ ...formValues, date: e.target.value })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      label="Heure de départ"
                      type="time"
                      name="departureTime"
                      value={formValues.departureTime}
                      onChange={(e) => setFormValues({ ...formValues, departureTime: e.target.value })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      label="Heure d'arrivée"
                      type="time"
                      name="arrivalTime"
                      value={formValues.arrivalTime}
                      onChange={(e) => setFormValues({ ...formValues, arrivalTime: e.target.value })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </FormControl>
                </Grid>
              </>
              )}
            </Grid>
          </Paper>
        </Box>
        )}

        {activeStep === 3 && (
        /* Étape 4 : Adresse de prise en charge et destination */
        <Box sx={{ mt: 2 }}>
          <Paper sx={{ padding: 3, backgroundColor: '#ffffff' }}>
            <Grid container spacing={2}>
              {/* Prise en Charge */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" sx={{ marginBottom: '10px' }}>Prise en charge</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      sx={{ width: '100%', marginTop: '10px' }}
                      options={etablissementsData.map((etab) => etab.name)}
                      value={formValues.departureEtablissement || ''}
                      inputValue={inputDepartureLabel} // Ajoutez cette ligne
                      onInputChange={(event, newInputValue) => {
                        setInputDepartureLabel(newInputValue);
                      }}
                      onChange={(event, newValue) => {
                        const selectedEtablissement = etablissementsData.find((etab) => etab.name === newValue);
                        if (selectedEtablissement) {
                          let postalCode = selectedEtablissement.zipcode;
                          let { city } = selectedEtablissement;

                          // Si le zipcode contient une virgule, on le sépare en code postal et ville
                          if (postalCode && postalCode.includes(',')) {
                            const [codePostal, ville] = postalCode.split(',').map((part) => part.trim());
                            postalCode = codePostal;
                            city = ville;
                          }

                          setFormValues((prevFormValues) => ({
                            ...prevFormValues,
                            departureEtablissement: selectedEtablissement.name,
                            address: selectedEtablissement.address,
                            postalCode: postalCode || '',
                            selectedCity: city || '',
                          }));

                          // Appeler fetchCities avec le nouveau code postal
                          fetchCities(postalCode || '');
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Établissement de départ"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      freeSolo
                      options={addressSuggestions}
                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.properties.label)}
                      value={formValues.address}
                      inputValue={inputAddress}
                      onInputChange={handleAddressChange}
                      onChange={(event, newValue) => {
                        if (newValue && newValue.properties) {
                          setFormValues((prevFormValues) => ({
                            ...prevFormValues,
                            address: newValue.properties.name || '',
                            postalCode: newValue.properties.postcode || '',
                            selectedCity: newValue.properties.city || '',
                          }));

                          // Appeler fetchCities avec le nouveau code postal
                          fetchCities(newValue.properties.postcode || '');
                        }

                        else {
                          setFormValues((prevFormValues) => ({
                            ...prevFormValues,
                            address: newValue || '',
                          }));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Adresse de prise en charge"
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ width: '100%' }}
                      label="Code Postal"
                      type="text"
                      name="postalCode"
                      value={formValues.postalCode}
                      onChange={(e) => {
                        handleInputChange(e);
                        fetchCities(e.target.value);
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="city-label">Ville de prise en charge</InputLabel>
                      <Select
                        sx={{ width: '100%' }}
                        labelId="city-label"
                        id="city-select"
                        label="Ville de prise en charge"
                        name="selectedCity"
                        value={formValues.selectedCity}
                        onChange={handleInputChange}
                        required
                      >
                        {cities.map((city) => (
                          <MenuItem key={city.code} value={city.nom}>
                            {city.nom}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* Destination */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" sx={{ marginBottom: '10px' }}>Destination</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      sx={{ width: '100%', marginTop: '10px' }}
                      options={etablissementsData.map((etab) => etab.name)}
                      value={formValues.destinationEtablissement || ''}
                      inputValue={inputDestinationLabel} // Ajoutez cette ligne
                      onInputChange={(event, newInputValue) => {
                        setInputDestinationLabel(newInputValue);
                      }}
                      onChange={(event, newValue) => {
                        const selectedEtablissement = etablissementsData.find((etab) => etab.name === newValue);
                        if (selectedEtablissement) {
                          let postalCode = selectedEtablissement.zipcode;
                          let { city } = selectedEtablissement;

                          // Si le zipcode contient une virgule, on le sépare en code postal et ville
                          if (postalCode && postalCode.includes(',')) {
                            const [codePostal, ville] = postalCode.split(',').map((part) => part.trim());
                            postalCode = codePostal;
                            city = ville;
                          }

                          setFormValues((prevFormValues) => ({
                            ...prevFormValues,
                            destinationEtablissement: selectedEtablissement.name,
                            destinationAddress: selectedEtablissement.address,
                            destinationPostalCode: postalCode || '',
                            selectedDestinationCity: city || '',
                          }));

                          // Appeler fetchDestinationCities avec le nouveau code postal
                          fetchDestinationCities(postalCode || '');
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Établissement de destination"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      freeSolo
                      options={destinationAddressSuggestions}
                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.properties.label)}
                      value={formValues.destinationAddress}
                      inputValue={inputDestinationAddress}
                      onInputChange={handleDestinationAddressChange}
                      onChange={(event, newValue) => {
                        if (newValue && newValue.properties) {
                          setFormValues((prevFormValues) => ({
                            ...prevFormValues,
                            destinationAddress: newValue.properties.name || '',
                            destinationPostalCode: newValue.properties.postcode || '',
                            selectedDestinationCity: newValue.properties.city || '',
                          }));

                          // Appeler fetchDestinationCities avec le nouveau code postal
                          fetchDestinationCities(newValue.properties.postcode || '');
                        }

                        else {
                          setFormValues((prevFormValues) => ({
                            ...prevFormValues,
                            destinationAddress: newValue || '',
                          }));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Adresse de destination"
                          required
                        />
                      )}
                    />

                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ width: '100%' }}
                      label="Code postal destination"
                      type="text"
                      name="destinationPostalCode"
                      value={formValues.destinationPostalCode}
                      onChange={(e) => {
                        handleInputChange(e);
                        fetchDestinationCities(e.target.value);
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="destination-city-label">Ville de destination</InputLabel>
                      <Select
                        sx={{ width: '100%' }}
                        labelId="destination-city-label"
                        id="destination-city-select"
                        name="selectedDestinationCity"
                        label="Ville de destination"
                        value={formValues.selectedDestinationCity}
                        onChange={handleInputChange}
                        required
                      >
                        {destinationCities.map((city) => (
                          <MenuItem key={city.code} value={city.nom}>
                            {city.nom}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* Bouton Calculer la Distance */}
              <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 2 }}>
                <DistanceCalculator
                  pickupAddress={`${formValues.postalCode} ${formValues.selectedCity} ${formValues.address}`}
                  destinationAddress={`${formValues.destinationPostalCode} ${formValues.selectedDestinationCity} ${formValues.destinationAddress}`}
                  onDistanceCalculated={(distance) => {
                    setFormValues((prev) => ({ ...prev, distanceKm: distance }));
                    setSnackbar({ open: true, message: `Distance calculée: ${distance} km`, severity: 'success' });
                  }}
                />
              </Grid>

              {/* Champs Distance et Autres */}
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Distance (en Km)"
                  value={formValues.distanceKm}
                  onChange={handleInputChange}
                  type="number"
                  name="distanceKm"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Quarts d'heure d'attente"
                  value={formValues.waitingTime}
                  onChange={handleInputChange}
                  type="number"
                  name="waitingTime"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Péages"
                  value={formValues.tolls}
                  onChange={handleInputChange}
                  type="number"
                  name="tolls"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Supplément non remboursable"
                  value={formValues.nonReimbursableSupplement}
                  onChange={handleInputChange}
                  type="number"
                  name="nonReimbursableSupplement"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: '100%' }}
                  label="Commentaire"
                  type="text"
                  name="comment"
                  value={formValues.comment}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Checkbox name="roundTrip" checked={formValues.roundTrip} onChange={handleInputChange} />}
                  label="Retour"
                />
                <FormControlLabel
                  control={<Checkbox name="aeroport" checked={formValues.aeroport} onChange={handleInputChange} />}
                  label="Aéroport/Gare"
                />
                {formValues.selectedTransportType === 1 && (
                <FormControlLabel
                  control={<Checkbox name="premature" checked={formValues.premature} onChange={handleInputChange} />}
                  label="Prématuré"
                />
                )}
                {(formValues.selectedTransportType === 2 || formValues.selectedTransportType === 4) && (
                <>
                  <FormControl fullWidth sx={{ marginTop: 1 }}>
                    <InputLabel id="number-of-people-label">Nombre de personnes</InputLabel>
                    <Select
                      labelId="number-of-people-label"
                      id="number-of-people-select"
                      name="numberOfPeople"
                      value={formValues.numberOfPeople}
                      label="Nombre de personnes"
                      onChange={handleInputChange}
                    >
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    control={<Checkbox name="tpmr" checked={formValues.tpmr} onChange={handleInputChange} />}
                    label="TPMR"
                  />
                </>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Box>
        )}

        {activeStep === 4 && (
        /* Étape 5 : Résumé et Génération du devis */
        <Box sx={{ mt: 2 }}>
          <Paper sx={{ padding: 3, backgroundColor: '#ffffff' }}>
            <Typography variant="h4" gutterBottom>Résumé du devis</Typography>
            <Grid container spacing={2}>
              {/* Affichez un résumé des informations saisies */}
              <Grid item xs={12}>
                <Typography variant="h6">Informations de l'expéditeur</Typography>
                <Typography>{formValues.selectedCompany?.companyname || 'N/A'}</Typography>
                <Typography>{formValues.companyAddress}</Typography>
                <Typography>{formValues.companyPostal}</Typography>
                <Typography>{formValues.companyPhone}</Typography>
                <Typography>{formValues.companyEmail}</Typography>
                <Typography>{formValues.companySiret}</Typography>
                <Typography>{formValues.companyApe}</Typography>
                <Typography>{formValues.companyIdentification}</Typography>
                <Typography>{formValues.companyTva}</Typography>
                <Typography>{formValues.companyIban}</Typography>
                <Typography>{formValues.companyBic}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Informations du destinataire</Typography>
                <Typography>{formValues.recipientName}</Typography>
                <Typography>{formValues.recipientAddress}</Typography>
                <Typography>{formValues.selectedRecipientCity} {formValues.recipientPostalCode}</Typography>
                <Typography>{formValues.recipientPhone}</Typography>
                <Typography>{formValues.recipientEmail}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Détails de la mission</Typography>
                <Typography>Mode de transport: {typesDeTarif.find((type) => type.id === formValues.selectedTransportType)?.nom || 'N/A'}</Typography>
                <Typography>Tarif: {formValues.selectedCategory}</Typography>
                <Typography>Date: {formValues.date}</Typography>
                <Typography>Heure de départ: {formValues.departureTime}</Typography>
                <Typography>Heure d'arrivée: {formValues.arrivalTime}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Adresse de prise en charge</Typography>
                <Typography>{formValues.address}</Typography>
                <Typography>{formValues.selectedCity}, {formValues.postalCode}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Destination</Typography>
                <Typography>{formValues.destinationAddress}</Typography>
                <Typography>{formValues.selectedDestinationCity}, {formValues.destinationPostalCode}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Autres détails</Typography>
                <Typography>Distance: {formValues.distanceKm} km</Typography>
                <Typography>Quarts d'heure d'attente: {formValues.waitingTime}</Typography>
                <Typography>Péages: {formValues.tolls} €</Typography>
                <Typography>Supplément non remboursable: {formValues.nonReimbursableSupplement} €</Typography>
                <Typography>Commentaire: {formValues.comment || 'Aucun'}</Typography>
                <Typography>Retour: {formValues.roundTrip ? 'Oui' : 'Non'}</Typography>
                <Typography>Aéroport/Gare: {formValues.aeroport ? 'Oui' : 'Non'}</Typography>
                {formValues.selectedTransportType === 1 && (
                <Typography>Prématuré: {formValues.premature ? 'Oui' : 'Non'}</Typography>
                )}
                {(formValues.selectedTransportType === 2 || formValues.selectedTransportType === 4) && (
                <>
                  <Typography>Nombre de personnes: {formValues.numberOfPeople || 'N/A'}</Typography>
                  <Typography>TPMR: {formValues.tpmr ? 'Oui' : 'Non'}</Typography>
                </>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Box>
        )}

        {/* Navigation des Étapes */}
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Précédent
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep < steps.length - 1 && (
          <Button onClick={handleNext} variant="contained" color="primary">
            Suivant
          </Button>
          )}
          {activeStep === steps.length - 1 && (
          <Button type="submit" variant="contained" color="primary">
            Générer un devis
          </Button>
          )}
        </Box>
      </form>

      {/* Snackbar pour les messages de feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default React.memo(Devis);
