/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';

function DistanceCalculator({ pickupAddress, destinationAddress, onDistanceCalculated }) {
  const [isCalculated, setIsCalculated] = useState(false);

  // Charge le script de l'API Google Maps si nécessaire
  const loadGoogleMapsScript = (callback) => {
    const existingScript = document.getElementById('googleMapsScript');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDo6Q-gVHGzcy0VtTb-jo-Ojx56amB2Oi4&libraries=places';
      script.id = 'googleMapsScript';
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }
    else if (callback) {
      callback();
    }
  };

  useEffect(() => {
    setIsCalculated(false);
    loadGoogleMapsScript(() => {
      console.log('Google Maps API script loaded.');
    });
  }, [pickupAddress, destinationAddress]);

  const calculateDistance = () => {
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [pickupAddress],
        destinations: [destinationAddress],
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          const distance = Math.round(response.rows[0].elements[0].distance.value / 1000);
          onDistanceCalculated(distance);
          setIsCalculated(true);
        }
        else {
          console.error('Erreur lors du calcul de la distance:', status);
        }
      }
    );
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={calculateDistance}
          disabled={!pickupAddress || !destinationAddress || isCalculated}
        >
          Calculer la distance
        </Button>
      </Grid>
    </Grid>
  );
}

export default React.memo(DistanceCalculator);
