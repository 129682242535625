/* eslint-disable new-cap */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Typography, Select, MenuItem,
  Checkbox, FormControlLabel, Divider, Box, IconButton, Autocomplete, FormControl, InputLabel
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Swal from 'sweetalert2';

// Onglets MUI Lab
import { TabContext, TabList, TabPanel } from '@mui/lab';

// Pour la génération de PDF
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

// Votre URL de base (ex: http://localhost:3000/api/ ...)
import { BASE_URL } from '../../../utils/api';
// Votre hook personnalisé pour le calcul de la facture
import calculateInvoice from '../../hooks/calculateInvoice';

function FactureManager({ userToken }) {
  const [factures, setFactures] = useState([]);
  const [selectedFacture, setSelectedFacture] = useState(null);
  const [open, setOpen] = useState(false);

  // Gestion de l’onglet actif
  const [activeTab, setActiveTab] = useState('1');

  // Données externes
  const [companies, setCompanies] = useState([]);
  const [tarifs, setTarifs] = useState([]);
  const [tarifTypes, setTarifTypes] = useState([]);
  const [tarifTaxiOptions, setTarifTaxiOptions] = useState([]);
  const [assures, setAssures] = useState([]);
  const [mutuelles, setMutuelles] = useState([]);
  const [caisses, setCaisses] = useState([]);
  const [exonerations, setExonerations] = useState([]);

  /**
   * Formate une date (string) au format JJ/MM/YYYY pour l’affichage.
   */
  function formatDate(dateString) {
    if (!dateString) return '';
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  }

  /**
   * Formate une date pour un input type="date".
   */
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  // Vérifie si le type de transport est Taxi ou VSL
  const isTaxiOrVSL = () => {
    const transport = (selectedFacture?.type_transport || '').toLowerCase();
    return transport === 'taxi' || transport === 'vsl';
  };

  // Vérifie si c’est un Taxi
  const isTaxi = () => {
    const transport = (selectedFacture?.type_transport || '').toLowerCase();
    return transport === 'taxi';
  };

  /**
   * Récupération des données initiales (factures, sociétés, tarifs, assurés, etc.)
   */
  useEffect(() => {
    axios.get(`${BASE_URL}invoices`, { headers })
      .then((r) => setFactures(r.data.factures))
      .catch((e) => console.error('Failed to fetch invoices', e));

    axios.get(`${BASE_URL}companies`, { headers })
      .then((r) => setCompanies(r.data.companies))
      .catch((e) => console.error('Failed to fetch companies', e));

    axios.get(`${BASE_URL}tarifs`, { headers })
      .then((r) => setTarifs(r.data.tarifs))
      .catch((e) => console.error('Failed to fetch tarifs', e));

    axios.get(`${BASE_URL}types-de-tarif`, { headers })
      .then((r) => setTarifTypes(r.data.typesDeTarif))
      .catch((e) => console.error('Failed to fetch types de tarif', e));

    axios.get(`${BASE_URL}assures`, { headers })
      .then((r) => setAssures(r.data.assures))
      .catch((e) => console.error('Failed to fetch assures', e));

    // Fetch mutuelles
    axios.get(`${BASE_URL}mutuelles`, { headers })
      .then((r) => setMutuelles(r.data.mutuelles))
      .catch((e) => console.error('Failed to fetch mutuelles', e));

    // Fetch caisses
    axios.get(`${BASE_URL}caisses`, { headers })
      .then((r) => setCaisses(r.data.caisses))
      .catch((e) => console.error('Failed to fetch caisses', e));

    // Fetch exonerations
    axios.get(`${BASE_URL}risques-exoneration`, { headers })
      .then((r) => setExonerations(r.data.entries))
      .catch((e) => console.error('Failed to fetch exonerations', e));
  }, []);

  /**
   * Quand on sélectionne (ou crée) une facture, on charge toutes les infos
   * (société, tarifs, assuré, etc.) pour pré-remplir le formulaire.
   */
  const handleSelectFacture = (facture) => {
    // Recherche de la société
    const matchingCompany = companies.find((company) => company.code === facture.societe_nom);
    if (matchingCompany) {
      facture.societe_siret = matchingCompany.siret;
      facture.societe_adresse = matchingCompany.address;
      facture.societe_code_postal = matchingCompany.address.split(' ').slice(-2, -1)[0];
      facture.societe_ville = matchingCompany.address.split(' ').slice(-1)[0];
      facture.societe_numero_identification = matchingCompany.numeroidentification;
    }

    // Recherche du tarif
    const matchingTarif = tarifs.find((tarif) => tarif.categorie === facture.tarif_nom);
    if (matchingTarif) {
      const matchingTarifType = tarifTypes.find((type) => type.id === matchingTarif.typedetarifid);
      facture.tarif_type = matchingTarifType ? matchingTarifType.nom : '';
      facture.tarif_nom = matchingTarif.nom;
      facture.tarif_forfait = matchingTarif.base;

      // Prépare les options de tarifs taxi
      setTarifTaxiOptions([
        { label: `A - ${matchingTarif.basekma}`, value: matchingTarif.basekma },
        { label: `B - ${matchingTarif.basekmb}`, value: matchingTarif.basekmb },
        { label: `C - ${matchingTarif.basekmc}`, value: matchingTarif.basekmc },
        { label: `D - ${matchingTarif.basekmd}`, value: matchingTarif.basekmd }
      ]);

      // Préparation pour calculateInvoice
      const transportDetails = {
        selectedTransportType: matchingTarif.typedetarifid,
        distance: facture.distance,
        waitingTime: facture.attente,
        roundTrip: facture.retour,
        departureTime: facture.heure_depart,
        arrivalTime: facture.heure_arrivee,
        dayOfWeek: new Date(facture.date_transport).getDay(),
        aeroport: facture.aeroport,
        premature: facture.premature,
        tpmr: facture.tpmr,
        numberOfPeople: facture.nombre_personnes,
        cancelled: facture.annule,
        urgent: facture.urgent,
        tarifTaxi: facture.tarif_taxi,
      };

      // Calcul
      const {
        totalWithTVA,
        distanceCharge,
        nightRate,
        holidayRate,
        waitingCost,
        valorisationTrajetCourtCharge,
        remiseSimultane,
        aeroportMajoration,
        prematureMajoration,
        tpmrMajoration,
        cancelledBill,
        urgentMajoration,
        majorationTranche25km
      } = calculateInvoice(matchingTarif, transportDetails);

      facture.tarif_total = totalWithTVA;
      facture.tarif_total_facture = totalWithTVA;
      facture.tarif_distance = distanceCharge;
      facture.tarif_nuit = nightRate;
      facture.tarif_dimanche_ferie = holidayRate;
      facture.majoration_trajet_court = valorisationTrajetCourtCharge;
      facture.tarif_remise_simultane = remiseSimultane;
      facture.tarif_peage = aeroportMajoration;
      facture.tarif_part_organisme = prematureMajoration;
      facture.tarif_part_assure = tpmrMajoration;
      facture.tarif_supplement_non_remboursable = cancelledBill;
      facture.tarif_somme_payee_assure = urgentMajoration;
      facture.cout_temps_attente = waitingCost;
      facture.cout_par_tranche_25km = majorationTranche25km;
    }

    // Recherche de l'assuré
    const matchingAssure = assures.find(
      (a) => a.nom === facture.assure_nom && a.prenom === facture.assure_prenom
    );
    if (matchingAssure) {
      facture.assure_numero_securite_sociale = matchingAssure.numero_securite_sociale;
      facture.assure_adresse = matchingAssure.adresse;
      facture.assure_code_postal = `${matchingAssure.code_postal} ${matchingAssure.ville}`;
      facture.patient_risque = matchingAssure.nature_assurance;
      facture.patient_exo = matchingAssure.exoneration;
      facture.patient_taux = matchingAssure.taux;
      facture.patient_date_fin_droits = matchingAssure.date_fin_droits;

      // Mutuelle
      const matchingMutuelle = mutuelles.find((m) => m.id === matchingAssure.mutuelle_id);
      if (matchingMutuelle) {
        facture.mutuelle_id = matchingMutuelle.id; // Stocker l'ID
        facture.assure_mutuelle_nom = matchingMutuelle.nom;
        facture.assure_type_contrat = matchingMutuelle.numero_contrat;
      }
      else {
        facture.mutuelle_id = '';
        facture.assure_mutuelle_nom = '';
        facture.assure_type_contrat = '';
      }

      // Caisse
      const matchingCaisse = caisses.find((c) => c.id === matchingAssure.caisse_id);
      if (matchingCaisse) {
        facture.caisse_id = matchingCaisse.id; // Stocker l'ID
        facture.assure_organisme_nom = matchingCaisse.libelle;
        facture.assure_organisme_code = `${matchingCaisse.code_regime} ${matchingCaisse.caisse_gestionnaire} ${matchingCaisse.centre_gestionnaire}`;
      }
      else {
        facture.caisse_id = '';
        facture.assure_organisme_nom = '';
        facture.assure_organisme_code = '';
      }
    }

    setSelectedFacture(facture);
    setOpen(true);
    setActiveTab('1');
  };

  /**
   * Fermeture de la modale (avec confirmation)
   */
  const handleClose = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Voulez-vous vraiment quitter ?',
      text: 'Les modifications non sauvegardées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, quitter',
      cancelButtonText: 'Annuler',
    });
    if (!isConfirmed) return;

    setOpen(false);
    setSelectedFacture(null);
  };

  /**
   * Création ou mise à jour de la facture
   */
  const handleSaveFacture = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Enregistrer',
      text: 'Voulez-vous enregistrer les modifications ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    });
    if (!isConfirmed) return;

    if (selectedFacture.id) {
      // Update
      axios
        .put(`${BASE_URL}invoices/${selectedFacture.id}`, selectedFacture, { headers })
        .then((response) => {
          setFactures((prev) => prev.map((fact) => (fact.id === selectedFacture.id ? response.data.facture : fact)));
        })
        .catch((error) => {
          console.error('Failed to update invoice', error);
        });
    }
    else {
      // Create
      axios
        .post(`${BASE_URL}invoices`, selectedFacture, { headers })
        .then((response) => {
          setFactures((prev) => [...prev, response.data.facture]);
        })
        .catch((error) => {
          console.error('Failed to create invoice', error);
        });
    }
    handleClose();
  };

  /**
   * Suppression d’une facture
   */
  const handleDeleteFacture = async (id) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Supprimer la facture ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Annuler',
    });
    if (!isConfirmed) return;

    axios
      .delete(`${BASE_URL}invoices/${id}`, { headers })
      .then(() => {
        setFactures((prev) => prev.filter((fact) => fact.id !== id));
      })
      .catch((error) => {
        console.error('Failed to delete invoice', error);
      });
  };

  /**
   * Génération PDF
   */
  const handleGeneratePDF = async (facture) => {
    if (!facture) return;

    const { isConfirmed } = await Swal.fire({
      title: 'Générer PDF ?',
      text: `Voulez-vous vraiment générer un PDF pour la facture n°${facture.numero_facture || ''} ?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui, générer',
      cancelButtonText: 'Annuler',
    });
    if (!isConfirmed) return;

    const doc = new jsPDF({ orientation: 'portrait', unit: 'pt', format: 'A4' });

    // Titre
    doc.setFontSize(18);
    doc.text(`Facture N° ${facture.numero_facture || 'N/A'}`, 40, 50);

    // Sous-titre
    doc.setFontSize(12);
    doc.text(`Date de facture: ${formatDate(facture.date_facture)}`, 40, 70);

    // Contenu du PDF
    const rows = [
      // ...
      // Ajoutez ici toutes les lignes que vous souhaitez dans votre PDF
      // ...
    ];

    autoTable(doc, {
      startY: 90,
      head: [['Champ', 'Valeur']],
      body: rows,
      margin: { left: 40, right: 40 },
      styles: { fontSize: 10, cellPadding: 5 },
    });

    const fileName = `Facture_${facture.numero_facture || 'nouvelle'}.pdf`;
    doc.save(fileName);
  };

  /**
   * Gestion des cases à cocher
   */
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedFacture({ ...selectedFacture, [name]: checked });
  };

  /**
   * Sélection d’une société
   */
  const handleCompanyChange = (e) => {
    const companyCode = e.target.value;
    const selectedCompany = companies.find((company) => company.code === companyCode);

    if (selectedCompany) {
      setSelectedFacture({
        ...selectedFacture,
        societe_nom: selectedCompany.code,
        societe_siret: selectedCompany.siret,
        societe_adresse: selectedCompany.address,
        societe_code_postal: selectedCompany.address.split(' ').slice(-2, -1)[0],
        societe_ville: selectedCompany.address.split(' ').slice(-1)[0],
        societe_numero_identification: selectedCompany.numeroidentification,
      });
    }
  };

  /**
   * Sélection d'un tarif
   */
  const handleTarifChange = (e) => {
    const tarifNom = e.target.value;
    const selectedTarif = tarifs.find((tarif) => tarif.nom === tarifNom);

    if (selectedTarif) {
      const matchingTarifType = tarifTypes.find((type) => type.id === selectedTarif.typedetarifid);
      setSelectedFacture((prev) => ({
        ...prev,
        tarif_nom: selectedTarif.nom,
        tarif_type: matchingTarifType ? matchingTarifType.nom : '',
        tarif_forfait: selectedTarif.base,
        tarif_taxi: '',
      }));

      setTarifTaxiOptions([
        { label: `A - ${selectedTarif.basekma}`, value: selectedTarif.basekma },
        { label: `B - ${selectedTarif.basekmb}`, value: selectedTarif.basekmb },
        { label: `C - ${selectedTarif.basekmc}`, value: selectedTarif.basekmc },
        { label: `D - ${selectedTarif.basekmd}`, value: selectedTarif.basekmd }
      ]);

      // Recalcul via calculateInvoice
      const transportDetails = {
        selectedTransportType: selectedTarif.typedetarifid,
        distance: selectedFacture?.distance,
        waitingTime: selectedFacture?.attente,
        roundTrip: selectedFacture?.retour,
        departureTime: selectedFacture?.heure_depart,
        arrivalTime: selectedFacture?.heure_arrivee,
        dayOfWeek: new Date(selectedFacture?.date_transport).getDay(),
        aeroport: selectedFacture?.aeroport,
        premature: selectedFacture?.premature,
        tpmr: selectedFacture?.tpmr,
        numberOfPeople: selectedFacture?.nombre_personnes,
        cancelled: selectedFacture?.annule,
        urgent: selectedFacture?.urgent,
        tarifTaxi: selectedFacture?.tarif_taxi,
      };

      const {
        totalWithTVA,
        distanceCharge,
        nightRate,
        holidayRate,
        waitingCost,
        valorisationTrajetCourtCharge,
        remiseSimultane,
        aeroportMajoration,
        prematureMajoration,
        tpmrMajoration,
        cancelledBill,
        urgentMajoration,
        majorationTranche25km
      } = calculateInvoice(selectedTarif, transportDetails);

      setSelectedFacture((prevFacture) => ({
        ...prevFacture,
        tarif_total: totalWithTVA,
        tarif_total_facture: totalWithTVA,
        tarif_distance: distanceCharge,
        tarif_nuit: nightRate,
        tarif_dimanche_ferie: holidayRate,
        majoration_trajet_court: valorisationTrajetCourtCharge,
        tarif_remise_simultane: remiseSimultane,
        tarif_peage: aeroportMajoration,
        tarif_part_organisme: prematureMajoration,
        tarif_part_assure: tpmrMajoration,
        tarif_supplement_non_remboursable: cancelledBill,
        tarif_somme_payee_assure: urgentMajoration,
        cout_temps_attente: waitingCost,
        cout_par_tranche_25km: majorationTranche25km,
      }));
    }
  };

  /**
   * Sélection du type de tarif taxi
   */
  const handleTarifTaxiChange = (e) => {
    setSelectedFacture({ ...selectedFacture, tarif_taxi: e.target.value });
  };

  /**
   * Recherche auto de l’assuré en base dès qu’on modifie son nom/prénom
   */
  const handleAssureSearch = (nom, prenom) => {
    if (nom && prenom) {
      axios
        .get(`${BASE_URL}assures/search?nom=${nom}&prenom=${prenom}`, { headers })
        .then((response) => {
          const matchingAssure = response.data.assure;
          if (matchingAssure) {
            setSelectedFacture((prev) => {
              const updatedFacture = {
                ...prev,
                assure_numero_securite_sociale: matchingAssure.numero_securite_sociale,
                assure_adresse: matchingAssure.adresse,
                assure_code_postal: `${matchingAssure.code_postal} ${matchingAssure.ville}`,
                patient_risque: matchingAssure.nature_assurance,
                patient_exo: matchingAssure.exoneration,
                patient_taux: matchingAssure.taux,
                patient_date_fin_droits: matchingAssure.date_fin_droits,

                // Mutuelle
                mutuelle_id: matchingAssure.mutuelle_id || '',
                assure_mutuelle_nom: matchingAssure.mutuelle ? matchingAssure.mutuelle.nom : '',
                assure_type_contrat: matchingAssure.mutuelle ? matchingAssure.mutuelle.numero_contrat : '',

                // Caisse
                caisse_id: matchingAssure.caisse_id || '',
                assure_organisme_nom: matchingAssure.caisse ? matchingAssure.caisse.libelle : '',
                assure_organisme_code: matchingAssure.caisse
                  ? `${matchingAssure.caisse.code_regime} ${matchingAssure.caisse.caisse_gestionnaire} ${matchingAssure.caisse.centre_gestionnaire}`
                  : '',
              };
              console.log('Updated selectedFacture:', updatedFacture); // Ajouté pour débogage
              return updatedFacture;
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching assure:', error);
        });
    }
  };

  /**
   * Gestion des champs texte
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedFacture((prev) => ({ ...prev, [name]: value }));

    // Recherche de l'assuré si on modifie son nom ou prénom
    if (name === 'assure_nom' || name === 'assure_prenom') {
      handleAssureSearch(
        name === 'assure_nom' ? value : selectedFacture?.assure_nom,
        name === 'assure_prenom' ? value : selectedFacture?.assure_prenom
      );
    }
  };

  /**
   * Sélection d'une caisse => remplir assure_organisme_nom, assure_organisme_code
   */
  const handleCaisseChange = (e) => {
    const caisseId = e.target.value;
    const selectedCaisse = caisses.find((c) => c.id === caisseId);
    if (selectedCaisse) {
      setSelectedFacture((prev) => ({
        ...prev,
        caisse_id: caisseId, // Stocker l'ID
        assure_organisme_nom: selectedCaisse.libelle || '',
        assure_organisme_code: `${selectedCaisse.code_regime} ${selectedCaisse.caisse_gestionnaire} ${selectedCaisse.centre_gestionnaire}`,
      }));
    }
    else {
      setSelectedFacture((prev) => ({
        ...prev,
        caisse_id: '',
        assure_organisme_nom: '',
        assure_organisme_code: '',
      }));
    }
  };

  /**
   * Sélection d'une mutuelle => remplir assure_mutuelle_nom, assure_type_contrat
   */
  const handleMutuelleChange = (e) => {
    const mutuelleId = e.target.value;
    const selectedMutuelle = mutuelles.find((m) => m.id === mutuelleId);
    if (selectedMutuelle) {
      setSelectedFacture((prev) => ({
        ...prev,
        mutuelle_id: mutuelleId, // on stocke l'ID si besoin
        assure_mutuelle_nom: selectedMutuelle.nom,
        assure_type_contrat: selectedMutuelle.numero_contrat,
      }));
    }
    else {
      // Pas de mutuelle => vider
      setSelectedFacture((prev) => ({
        ...prev,
        mutuelle_id: '',
        assure_mutuelle_nom: '',
        assure_type_contrat: '',
      }));
    }
  };

  /**
   * Sélection d'une exo => remplir patient_exo, patient_risque, patient_taux, etc.
   */
  const handleExonerationChange = (e) => {
    const exoId = e.target.value;
    const selectedExo = exonerations.find((exo) => exo.id === exoId);
    if (selectedExo) {
      setSelectedFacture((prev) => ({
        ...prev,
        exoneration_id: exoId, // Stocker l'ID
        patient_exo: selectedExo.commentaire,
        patient_risque: selectedExo.code_risque,
        patient_taux: selectedExo.taux,
      }));
    }
    else {
      setSelectedFacture((prev) => ({
        ...prev,
        exoneration_id: '',
        patient_exo: '',
        patient_risque: '',
        patient_taux: '',
      }));
    }
  };

  return (
    <div style={{ padding: '1rem' }}>
      <Typography variant="h4" gutterBottom>
        Gestion des factures
      </Typography>

      <Box mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSelectFacture({})}
        >
          Créer Facture
        </Button>
      </Box>

      {/* Table d'affichage */}
      <TableContainer component={Paper}>
        <Table
          size="small"
          sx={{
            '& .MuiTableCell-root': {
              padding: '4px',
              textAlign: 'center'
            }
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Numéro de facture</TableCell>
              <TableCell>Date de facture</TableCell>
              <TableCell>Type de transport</TableCell>
              <TableCell>Nom de l'assuré</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {factures.map((facture) => (
              <TableRow key={facture.id}>
                <TableCell>{facture.numero_facture}</TableCell>
                <TableCell>{formatDate(facture.date_facture)}</TableCell>
                <TableCell>{facture.type_transport}</TableCell>
                <TableCell>{facture.assure_nom}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleSelectFacture(facture)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteFacture(facture.id)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={() => handleGeneratePDF(facture)}>
                    <PictureAsPdfIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog pour créer / modifier une facture */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
      >
        <DialogTitle sx={{ fontSize: '1.25rem', fontWeight: 500 }}>
          {selectedFacture?.id ? 'Modifier Facture' : 'Créer Facture'}
        </DialogTitle>

        <DialogContent>
          <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(event, newValue) => setActiveTab(newValue)}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Typography sx={{ pl: 2, pt: 1, fontWeight: 500 }}>Sections :</Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

                <Button
                  onClick={() => setActiveTab('1')}
                  variant={activeTab === '1' ? 'contained' : 'text'}
                  sx={{ mr: 2 }}
                >
                  Facture
                </Button>
                <Button
                  onClick={() => setActiveTab('2')}
                  variant={activeTab === '2' ? 'contained' : 'text'}
                  sx={{ mr: 2 }}
                >
                  Assuré
                </Button>
                <Button
                  onClick={() => setActiveTab('3')}
                  variant={activeTab === '3' ? 'contained' : 'text'}
                  sx={{ mr: 2 }}
                >
                  Transport
                </Button>
                <Button
                  onClick={() => setActiveTab('4')}
                  variant={activeTab === '4' ? 'contained' : 'text'}
                  sx={{ mr: 2 }}
                >
                  Règlement
                </Button>
                <Button
                  onClick={() => setActiveTab('5')}
                  variant={activeTab === '5' ? 'contained' : 'text'}
                  sx={{ mr: 2 }}
                >
                  Attestation
                </Button>
                <Button
                  onClick={() => setActiveTab('6')}
                  variant={activeTab === '6' ? 'contained' : 'text'}
                  sx={{ mr: 2 }}
                >
                  Paiements
                </Button>
                <Button
                  onClick={() => setActiveTab('7')}
                  variant={activeTab === '7' ? 'contained' : 'text'}
                  sx={{ mr: 2 }}
                >
                  Tarif
                </Button>
                <Button
                  onClick={() => setActiveTab('8')}
                  variant={activeTab === '8' ? 'contained' : 'text'}
                >
                  Société
                </Button>
              </TabList>
            </Box>

            {/** Onglet 1 : Informations de la facture */}
            <TabPanel value="1" sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>Informations de la facture</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="numero_teletransmission"
                    label="Numéro de Télétransmission"
                    type="text"
                    fullWidth
                    value={selectedFacture?.numero_teletransmission || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="numero_releve"
                    label="Numéro de Relevé"
                    type="text"
                    fullWidth
                    value={selectedFacture?.numero_releve || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="type_transport"
                    label="Type de Transport"
                    type="text"
                    fullWidth
                    value={selectedFacture?.type_transport || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="numero_facture"
                    label="Numéro de Facture"
                    type="text"
                    fullWidth
                    value={selectedFacture?.numero_facture || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="date_facture"
                    label="Date de Facture"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={formatDateForInput(selectedFacture?.date_facture) || ''}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={selectedFacture?.accident || false}
                        onChange={handleCheckboxChange}
                        name="accident"
                        color="primary"
                      />
                    )}
                    label="Accident de travail"
                  />
                </Grid>

                {selectedFacture?.accident && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="date_accident"
                        label="Date de l'Accident"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={formatDateForInput(selectedFacture?.date_accident) || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="numero_at"
                        label="Numéro AT"
                        type="text"
                        fullWidth
                        value={selectedFacture?.numero_at || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={3}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={selectedFacture?.article115 || false}
                        onChange={handleCheckboxChange}
                        name="article115"
                        color="primary"
                      />
                    )}
                    label="Article 115"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={selectedFacture?.ald || false}
                        onChange={handleCheckboxChange}
                        name="ald"
                        color="primary"
                      />
                    )}
                    label="ALD"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={selectedFacture?.atmp || false}
                        onChange={handleCheckboxChange}
                        name="atmp"
                        color="primary"
                      />
                    )}
                    label="ATMP"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={selectedFacture?.cent_pourcent || false}
                        onChange={handleCheckboxChange}
                        name="cent_pourcent"
                        color="primary"
                      />
                    )}
                    label="100%"
                  />
                </Grid>
              </Grid>
            </TabPanel>

            {/** Onglet 2 : Renseignements assuré */}
            <TabPanel value="2" sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>Renseignements assuré</Typography>
              <Grid container spacing={2}>
                {/*
      Autocomplete pour rechercher un assuré en base (Nom + Prénom).
      freeSolo = saisie libre autorisée.
    */}
                <Grid item xs={12}>
                  <Autocomplete
                    freeSolo
                    fullWidth
        // Liste des assurés (options) : chargée depuis votre API
                    options={assures}
        // Affiche "nom + prénom" dans la liste
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option; // Saisie libre
                      }
                      // Assuré existant => "Nom Prénom"
                      return `${option.nom || ''} ${option.prenom || ''}`.trim();
                    }}
        // Valeur actuelle : on la construit à partir de assure_nom + assure_prenom
                    value={
          selectedFacture?.assure_nom && selectedFacture?.assure_prenom
            ? {
              nom: selectedFacture.assure_nom,
              prenom: selectedFacture.assure_prenom
            }
            : null
        }
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        // 1) Saisie libre => on split ou on met tout dans assure_nom
                        setSelectedFacture((prev) => ({
                          ...prev,
                          assure_nom: newValue.nom,
                          assure_prenom: newValue.prenom,
                          assure_numero_securite_sociale: newValue.numero_securite_sociale || '',
                          assure_adresse: newValue.adresse || '',
                          assure_code_postal: newValue.code_postal
                            ? `${newValue.code_postal} ${newValue.ville || ''}`.trim()
                            : '',
                          patient_exo: newValue.exoneration || '',
                          patient_risque: newValue.nature_assurance || '',
                          patient_taux: newValue.taux || '',
                          patient_date_fin_droits: newValue.date_fin_droits || '',
                          mutuelle_id: newValue.mutuelle_id || '', // Ajouter l'ID de la mutuelle
                          assure_mutuelle_nom: newValue.mutuelle ? newValue.mutuelle.nom : '',
                          assure_type_contrat: newValue.mutuelle ? newValue.mutuelle.numero_contrat : '',
                          caisse_id: newValue.caisse_id || '', // Ajouter l'ID de la caisse
                          assure_organisme_nom: newValue.caisse ? newValue.caisse.libelle : '',
                          assure_organisme_code: newValue.caisse
                            ? `${newValue.caisse.code_regime} ${newValue.caisse.caisse_gestionnaire} ${newValue.caisse.centre_gestionnaire}`
                            : '',
                        }));
                      }
                      else if (newValue && newValue.nom) {
                        // 2) Assuré existant => on remplit automatiquement
                        setSelectedFacture((prev) => ({
                          ...prev,
                          assure_nom: newValue.nom,
                          assure_prenom: newValue.prenom,
                          assure_numero_securite_sociale: newValue.numero_securite_sociale || '',
                          assure_adresse: newValue.adresse || '',
                          assure_code_postal: newValue.code_postal
                            ? `${newValue.code_postal} ${newValue.ville || ''}`.trim()
                            : '',
                          patient_exo: newValue.exoneration || '',
                          patient_risque: newValue.nature_assurance || '',
                          patient_taux: newValue.taux || '',
                          patient_date_fin_droits: newValue.date_fin_droits || '',
                          assure_mutuelle_nom: newValue.mutuelle ? newValue.mutuelle.nom : '',
                          assure_type_contrat: newValue.mutuelle ? newValue.mutuelle.numero_contrat : '',
                          assure_organisme_nom: newValue.caisse ? newValue.caisse.libelle : '',
                          assure_organisme_code: newValue.caisse
                            ? `${newValue.caisse.code_regime} ${newValue.caisse.caisse_gestionnaire} ${newValue.caisse.centre_gestionnaire}`
                            : '',
                        }));
                      }
                      else {
                        // 3) Sélection effacée => remet tout à zéro
                        setSelectedFacture((prev) => ({
                          ...prev,
                          assure_nom: '',
                          assure_prenom: '',
                          assure_numero_securite_sociale: '',
                          assure_adresse: '',
                          assure_code_postal: '',
                          patient_exo: '',
                          patient_risque: '',
                          patient_taux: '',
                          patient_date_fin_droits: '',
                          assure_mutuelle_nom: '',
                          assure_type_contrat: '',
                          assure_organisme_nom: '',
                          assure_organisme_code: '',
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Rechercher ou saisir un assuré (Nom Prénom)"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                {/* Numéro de Sécurité Sociale */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="assure_numero_securite_sociale"
                    label="Numéro de Sécurité Sociale"
                    type="text"
                    fullWidth
                    value={selectedFacture?.assure_numero_securite_sociale || ''}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Adresse */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="assure_adresse"
                    label="Adresse"
                    type="text"
                    fullWidth
                    value={selectedFacture?.assure_adresse || ''}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Code Postal (et ville si vous le souhaitez) */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="assure_code_postal"
                    label="Code Postal"
                    type="text"
                    fullWidth
                    value={selectedFacture?.assure_code_postal || ''}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Date de Fin des Droits */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="patient_date_fin_droits"
                    label="Date de Fin des Droits"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={formatDateForInput(selectedFacture?.patient_date_fin_droits) || ''}
                    onChange={handleChange}
                  />
                </Grid>

                {/* On regroupe la suite pour Exonération, Mutuelle, Caisse, etc. */}
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {/* Sélecteur d'exonération */}
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="exo-label">Exonération</InputLabel>
                      <Select
                        labelId="exo-label"
                        label="Exonération"
                        name="exoneration_id" // Changer le nom pour refléter l'ID
                        value={selectedFacture?.exoneration_id || ''} // Utiliser l'ID
                        onChange={handleExonerationChange}
                      >
                        <MenuItem value="">(Aucune)</MenuItem>
                        {exonerations.map((exo) => (
                          <MenuItem key={exo.id} value={exo.id}>
                            {exo.commentaire}
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </Grid>

                  {/* Taux (se met à jour si on choisit une exo) */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      name="patient_taux"
                      label="Taux"
                      type="number"
                      fullWidth
                      value={selectedFacture?.patient_taux || ''}
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* Nature d'assurance (risque) */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      name="patient_risque"
                      label="Nature d'Assurance (risque)"
                      type="text"
                      fullWidth
                      value={selectedFacture?.patient_risque || ''}
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* Date de fin de droits (par redondance si vous voulez) */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      name="patient_date_fin_droits"
                      label="Date de Fin des Droits"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={formatDateForInput(selectedFacture?.patient_date_fin_droits) || ''}
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* Sélecteur de la Caisse => Remplit assure_organisme_nom / code */}
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="caisse-label">Caisse</InputLabel>
                      <Select
                        labelId="caisse-label"
                        label="Caisse"
                        name="caisse_id" // Changer le nom pour refléter l'ID
                        value={selectedFacture?.caisse_id || ''} // Utiliser l'ID
                        onChange={handleCaisseChange}
                      >
                        <MenuItem value="">(Aucune)</MenuItem>
                        {caisses.map((caisse) => (
                          <MenuItem key={caisse.id} value={caisse.id}>
                            {caisse.libelle}
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </Grid>

                  {/* Organisme Code affiché ou modifiable */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      name="assure_organisme_code"
                      label="Code Organisme"
                      type="text"
                      fullWidth
                      value={selectedFacture?.assure_organisme_code || ''}
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* Sélecteur de Mutuelle => Remplit assure_mutuelle_nom + assure_type_contrat */}
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="mutuelle-label">Mutuelle</InputLabel>
                      <Select
                        labelId="mutuelle-label"
                        label="Mutuelle"
                        name="mutuelle_id"
                        value={selectedFacture?.mutuelle_id || ''}
                        onChange={handleMutuelleChange}
                      >
                        <MenuItem value="">(Aucune)</MenuItem>
                        {mutuelles.map((mut) => (
                          <MenuItem key={mut.id} value={mut.id}>
                            {mut.nom}
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </Grid>

                  {/* Contrat Mutuelle */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      name="assure_type_contrat"
                      label="Contrat Mutuelle"
                      type="text"
                      fullWidth
                      value={selectedFacture?.assure_type_contrat || ''}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            {/** Onglet 3 : Informations du transport */}
            <TabPanel value="3" sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>Informations du transport</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="motif"
                    label="Motif"
                    type="text"
                    fullWidth
                    value={selectedFacture?.motif || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="origine"
                    label="Origine"
                    type="text"
                    fullWidth
                    value={selectedFacture?.origine || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="numero_prescripteur"
                    label="Numéro de Prescripteur"
                    type="text"
                    fullWidth
                    value={selectedFacture?.numero_prescripteur || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="numero_structure"
                    label="Numéro de Structure"
                    type="text"
                    fullWidth
                    value={selectedFacture?.numero_structure || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="date_prescription"
                    label="Date de Prescription"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={formatDateForInput(selectedFacture?.date_prescription) || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="numero_marche"
                    label="Numéro de Marché"
                    type="text"
                    fullWidth
                    value={selectedFacture?.numero_marche || ''}
                    onChange={handleChange}
                  />
                </Grid>

                <Divider sx={{ width: '100%', my: 2 }} />

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="date_transport"
                    label="Date du Transport"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={formatDateForInput(selectedFacture?.date_transport) || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="heure_depart"
                    label="Heure de Départ"
                    type="time"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={selectedFacture?.heure_depart || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="heure_arrivee"
                    label="Heure d'Arrivée"
                    type="time"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={selectedFacture?.heure_arrivee || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="vehicule"
                    label="Véhicule"
                    type="text"
                    fullWidth
                    value={selectedFacture?.vehicule || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="chauffeur1"
                    label="Chauffeur 1"
                    type="text"
                    fullWidth
                    value={selectedFacture?.chauffeur1 || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="chauffeur2"
                    label="Chauffeur 2"
                    type="text"
                    fullWidth
                    value={selectedFacture?.chauffeur2 || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="adresse_depart"
                    label="Adresse de Départ"
                    type="text"
                    fullWidth
                    value={selectedFacture?.adresse_depart || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="adresse_arrivee"
                    label="Adresse d'Arrivée"
                    type="text"
                    fullWidth
                    value={selectedFacture?.adresse_arrivee || ''}
                    onChange={handleChange}
                  />
                </Grid>

                <Divider sx={{ width: '100%', my: 2 }} />

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="distance"
                    label="Distance"
                    type="number"
                    fullWidth
                    value={selectedFacture?.distance || ''}
                    onChange={handleChange}
                  />
                </Grid>

                {isTaxiOrVSL() && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      name="nombre_personnes"
                      label="Nombre de Personnes"
                      type="number"
                      fullWidth
                      value={selectedFacture?.nombre_personnes || ''}
                      onChange={handleChange}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="attente"
                    label="Attente"
                    type="time"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={selectedFacture?.attente || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="remise"
                    label="Remise"
                    type="number"
                    fullWidth
                    value={selectedFacture?.remise || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="supplement"
                    label="Supplément"
                    type="number"
                    fullWidth
                    value={selectedFacture?.supplement || ''}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={selectedFacture?.retour || false}
                        onChange={handleCheckboxChange}
                        name="retour"
                        color="primary"
                      />
                    )}
                    label="Retour"
                  />
                </Grid>

                {/** Si "retour" est coché, on affiche les champs du retour */}
                {selectedFacture?.retour && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="date_retour"
                        label="Date de Retour"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={formatDateForInput(selectedFacture?.date_retour) || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="heure_depart_retour"
                        label="Heure de Départ du Retour"
                        type="time"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={selectedFacture?.heure_depart_retour || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="heure_arrivee_retour"
                        label="Heure d'Arrivée du Retour"
                        type="time"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={selectedFacture?.heure_arrivee_retour || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="vehicule_retour"
                        label="Véhicule du Retour"
                        type="text"
                        fullWidth
                        value={selectedFacture?.vehicule_retour || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="chauffeur1_retour"
                        label="Chauffeur 1 du Retour"
                        type="text"
                        fullWidth
                        value={selectedFacture?.chauffeur1_retour || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="chauffeur2_retour"
                        label="Chauffeur 2 du Retour"
                        type="text"
                        fullWidth
                        value={selectedFacture?.chauffeur2_retour || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="adresse_depart_retour"
                        label="Adresse de Départ du Retour"
                        type="text"
                        fullWidth
                        value={selectedFacture?.adresse_depart_retour || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="adresse_arrivee_retour"
                        label="Adresse d'Arrivée du Retour"
                        type="text"
                        fullWidth
                        value={selectedFacture?.adresse_arrivee_retour || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="distance_retour"
                        label="Distance du Retour"
                        type="number"
                        fullWidth
                        value={selectedFacture?.distance_retour || ''}
                        onChange={handleChange}
                      />
                    </Grid>

                    {isTaxiOrVSL() && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          name="nombre_personnes_retour"
                          label="Nombre de Personnes du Retour"
                          type="number"
                          fullWidth
                          value={selectedFacture?.nombre_personnes_retour || ''}
                          onChange={handleChange}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="attente_retour"
                        label="Attente du Retour"
                        type="time"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={selectedFacture?.attente_retour || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="remise_retour"
                        label="Remise du Retour"
                        type="number"
                        fullWidth
                        value={selectedFacture?.remise_retour || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        name="supplement_retour"
                        label="Supplément du Retour"
                        type="number"
                        fullWidth
                        value={selectedFacture?.supplement_retour || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </TabPanel>

            {/** Onglet 4 : Modalité de règlement */}
            <TabPanel value="4" sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>Modalité de règlement</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={selectedFacture?.paiement_avance || false}
                        onChange={handleCheckboxChange}
                        name="paiement_avance"
                        color="primary"
                      />
                    )}
                    label="Dispense d'avance de frais"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={selectedFacture?.paiement_direct || false}
                        onChange={handleCheckboxChange}
                        name="paiement_direct"
                        color="primary"
                      />
                    )}
                    label="Paiement Direct"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="signature_paiement"
                    label="Signature Paiement"
                    type="text"
                    fullWidth
                    value={selectedFacture?.signature_paiement || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="lieu_paiement"
                    label="Lieu de Paiement"
                    type="text"
                    fullWidth
                    value={selectedFacture?.lieu_paiement || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="date_paiement"
                    label="Date de Paiement"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={formatDateForInput(selectedFacture?.date_paiement) || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            {/** Onglet 5 : Attestation sur l’honneur */}
            <TabPanel value="5" sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>Attestation sur l’honneur</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="lieu_attestation"
                    label="Lieu d'Attestation"
                    type="text"
                    fullWidth
                    value={selectedFacture?.lieu_attestation || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="date_attestation"
                    label="Date d'Attestation"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={formatDateForInput(selectedFacture?.date_attestation) || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="signature_attestation"
                    label="Signature Attestation"
                    type="text"
                    fullWidth
                    value={selectedFacture?.signature_attestation || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            {/** Onglet 6 : Règlements (paiements) */}
            <TabPanel value="6" sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>Règlements</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="paiement_assure"
                    label="Paiement Assuré"
                    type="number"
                    fullWidth
                    value={selectedFacture?.paiement_assure || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="paiement_caisse"
                    label="Paiement Caisse"
                    type="number"
                    fullWidth
                    value={selectedFacture?.paiement_caisse || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="paiement_complementaire"
                    label="Paiement Complémentaire"
                    type="number"
                    fullWidth
                    value={selectedFacture?.paiement_complementaire || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="paiement_divers"
                    label="Paiement Divers"
                    type="number"
                    fullWidth
                    value={selectedFacture?.paiement_divers || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            {/* Onglet 7 : Tarif */}
            <TabPanel value="7" sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>Tarification</Typography>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                {/* Sélection du tarif principal */}
                <Grid item xs={12} sm={6}>
                  <Select
                    variant="outlined"
                    name="tarif_nom"
                    label="Nom"
                    fullWidth
                    value={selectedFacture?.tarif_nom || ''}
                    onChange={handleTarifChange}
                  >
                    {tarifs.map((tarif) => (
                      <MenuItem key={tarif.nom} value={tarif.nom}>
                        {tarif.nom}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                {/* Type de tarif (lecture seule ou modifiable à votre convenance) */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="tarif_type"
                    label="Type de Tarif"
                    type="text"
                    fullWidth
                    value={selectedFacture?.tarif_type || ''}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Sélection Taxi si c’est un Taxi */}
                {isTaxi() && (
                <Grid item xs={12} sm={6}>
                  <Select
                    variant="outlined"
                    name="tarif_taxi"
                    label="Tarif Taxi"
                    fullWidth
                    value={selectedFacture?.tarif_taxi || ''}
                    onChange={handleTarifTaxiChange}
                  >
                    {tarifTaxiOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                )}
              </Grid>

              {/* Tableau “Facture” */}
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Désignation</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }} align="right">Montant (€)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* Forfait */}
                    <TableRow>
                      <TableCell>Forfait</TableCell>
                      <TableCell align="right">
                        {/* Si modifiable, utilisez un TextField “standard” */}
                        <TextField
                          variant="standard"
                          type="number"
                          name="tarif_forfait"
                          value={selectedFacture?.tarif_forfait || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>

                    {/* Dimanche / Férié */}
                    <TableRow>
                      <TableCell>Tarif dimanche/férié</TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          type="number"
                          name="tarif_dimanche_ferie"
                          value={selectedFacture?.tarif_dimanche_ferie || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>

                    {/* Nuit */}
                    <TableRow>
                      <TableCell>Tarif nuit</TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          type="number"
                          name="tarif_nuit"
                          value={selectedFacture?.tarif_nuit || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>

                    {/* Distance */}
                    <TableRow>
                      <TableCell>Tarif distance</TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          type="number"
                          name="tarif_distance"
                          value={selectedFacture?.tarif_distance || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>

                    {/* Majoration trajet court */}
                    <TableRow>
                      <TableCell>Majoration trajet court</TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          type="number"
                          name="majoration_trajet_court"
                          value={selectedFacture?.majoration_trajet_court || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>

                    {/* Coût Temps d’Attente */}
                    <TableRow>
                      <TableCell>Coût temps d’attente</TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          type="number"
                          name="cout_temps_attente"
                          value={selectedFacture?.cout_temps_attente || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>

                    {/* Coût par Tranche de 25 km */}
                    <TableRow>
                      <TableCell>Coût par tranche de 25 km</TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          type="number"
                          name="cout_par_tranche_25km"
                          value={selectedFacture?.cout_par_tranche_25km || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>

                    {/* Remise simultanée (si Taxi ou VSL) */}
                    {isTaxiOrVSL() && (
                    <TableRow>
                      <TableCell>Remise simultanée</TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          type="number"
                          name="tarif_remise_simultane"
                          value={selectedFacture?.tarif_remise_simultane || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>
                    )}

                    {/* Péage */}
                    <TableRow>
                      <TableCell>Tarif péage</TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          type="number"
                          name="tarif_peage"
                          value={selectedFacture?.tarif_peage || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>

                    {/* Sous-total (optionnel) */}
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Base de remboursement</TableCell>
                      <TableCell align="right" style={{ fontWeight: 'bold' }}>
                        {selectedFacture?.tarif_total
                          ? Number(selectedFacture?.tarif_total).toFixed(2)
                          : '0.00'}
                      </TableCell>
                    </TableRow>

                    {/* Part Organisme / Part Assuré / Supplément */}
                    <TableRow>
                      <TableCell>Part de l’organisme</TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          type="number"
                          name="tarif_part_organisme"
                          value={selectedFacture?.tarif_part_organisme || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Part de l’assuré</TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          type="number"
                          name="tarif_part_assure"
                          value={selectedFacture?.tarif_part_assure || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Supplément non remboursable</TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          type="number"
                          name="tarif_supplement_non_remboursable"
                          value={selectedFacture?.tarif_supplement_non_remboursable || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Somme payée par l’assuré</TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          type="number"
                          name="tarif_somme_payee_assure"
                          value={selectedFacture?.tarif_somme_payee_assure || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>

                    {/* Total de la Facture */}
                    <TableRow sx={{ backgroundColor: '#e0f7fa' }}>
                      <TableCell style={{ fontWeight: 'bold' }}>Total de la facture</TableCell>
                      <TableCell align="right" style={{ fontWeight: 'bold' }}>
                        <TextField
                          variant="standard"
                          type="number"
                          name="tarif_total_facture"
                          value={selectedFacture?.tarif_total_facture || ''}
                          onChange={handleChange}
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>

            {/** Onglet 8 : Renseignements société */}
            <TabPanel value="8" sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>Renseignements société</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Select
                    variant="outlined"
                    name="societe_nom"
                    label="Nom de la Société"
                    fullWidth
                    value={selectedFacture?.societe_nom || ''}
                    onChange={handleCompanyChange}
                  >
                    {companies.map((company) => (
                      <MenuItem key={company.code} value={company.code}>
                        {company.companyname}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="societe_siret"
                    label="SIRET de la Société"
                    type="text"
                    fullWidth
                    value={selectedFacture?.societe_siret || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="societe_adresse"
                    label="Adresse de la Société"
                    type="text"
                    fullWidth
                    value={selectedFacture?.societe_adresse || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="societe_code_postal"
                    label="Code Postal de la Société"
                    type="text"
                    fullWidth
                    value={selectedFacture?.societe_code_postal || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="societe_ville"
                    label="Ville de la Société"
                    type="text"
                    fullWidth
                    value={selectedFacture?.societe_ville || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="societe_numero_identification"
                    label="Numéro d'Identification"
                    type="text"
                    fullWidth
                    value={selectedFacture?.societe_numero_identification || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="error" variant="outlined">
            Annuler
          </Button>
          <Button onClick={handleSaveFacture} color="primary" variant="contained">
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(FactureManager);
