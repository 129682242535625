/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './etablissementsManagementStyles.scss';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddHomeIcon from '@mui/icons-material/AddHome';
import AddEtablissementModal from './addEtablissementModal/addEtablissementModal';
import UpdateEtablissementModal from './updateEtablissementModal/updateEtablissementModal';
import AddServiceModal from './addServiceModal/addServiceModal';
import UpdateServices from './updateServices/updateServices';
import usePageTitle from '../../hooks/usePageTitle';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import { BASE_URL } from '../../../utils/api';

function Etablissements({ theme, userRole, userToken }) {
  usePageTitle('DRIVESOFT | GESTION DES ETABLISSEMENTS');
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false); // state to control service modal
  const [etablissementList, setEtablissementList] = useState([]);
  const [serviceList, setServiceList] = useState([]); // state for the services
  const [currentEtablissement, setCurrentEtablissement] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showServicesFor, setShowServicesFor] = useState(null);
  const [currentService, setCurrentService] = useState(null);
  const [showUpdateServiceModal, setShowUpdateServiceModal] = useState(false);

  const UpdateServiceModalRef = useRef(null); // reference for the service update modal
  const modalEtablissementRef = useRef(null);
  const UpdateEtablissementModalRef = useRef(null);
  const ServiceModalRef = useRef(null); // reference for the service modal

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  console.log(etablissementList);
  console.log(serviceList);

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Régulation', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  const handleClick = (e) => {
    if (modalEtablissementRef.current && !modalEtablissementRef.current.contains(e.target)) {
      setShowModal(false);
    }
    if (UpdateEtablissementModalRef.current && !UpdateEtablissementModalRef.current.contains(e.target)) {
      setShowUpdateModal(false);
    }
    if (ServiceModalRef.current && !ServiceModalRef.current.contains(e.target)) {
      setShowServiceModal(false);
    }
    if (UpdateServiceModalRef.current && !UpdateServiceModalRef.current.contains(e.target)) {
      setShowUpdateServiceModal(false);
    }
  };

  const handleUpdateClick = (etablissement) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Vous n\'avez pas les droits pour modifier un établissement',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setCurrentEtablissement(etablissement);
    setShowUpdateModal(true);
  };

  const getServicesForEtablissement = (etablissementId) => {
    const filteredServices = serviceList.filter((service) => service.etablissement_id === etablissementId);
    return filteredServices;
  };
  const handleUpdateClickService = (service) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Vous n\'avez pas les droits pour modifier un service',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setCurrentService(service);
    setShowUpdateServiceModal(true);
  };

  async function fetchAllEtablissementsAndServices() {
    setIsLoading(true);

    if (!userToken) {
      setIsLoading(false);
      return;
    }

    try {
      const etablissementsResponse = await axios.get(`${BASE_URL}etablissements`, { headers });
      const servicesResponse = await axios.get(`${BASE_URL}services`, { headers });
      setEtablissementList(etablissementsResponse.data.etablissements);
      setServiceList(servicesResponse.data.services);
      setIsLoading(false);
    }
    catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const handleDeleteService = (id) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Vous n\'avez pas les droits pour supprimer un service',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Voulez-vous vraiment supprimer ce service?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${BASE_URL}services/${id}`, { headers })
          .then((response) => {
            if (response.status === 200) {
              const newList = serviceList.filter((service) => service._id !== id);
              setServiceList(newList);
              Swal.fire({
                title: 'Service supprimé !',
                icon: 'success',
                confirmButtonText: 'OK',
              });
            }
            fetchAllEtablissementsAndServices();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const handleAddEtablissement = (etablissement) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Voulez-vous vraiment ajouter cet établissement?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, ajouter!'
    }).then((result) => {
      if (result.isConfirmed) {
        setEtablissementList((prevEtablissementList) => [...prevEtablissementList, etablissement]);
        setShowModal(false);
        Swal.fire(
          'Ajouté!',
          'Votre établissement a été ajouté.',
          'success'
        );
      }
      fetchAllEtablissementsAndServices();
    });
  };

  const handleAddEtablissementClick = () => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Vous n\'avez pas les droits pour ajouter un établissement',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setShowModal(true);
  };

  const handleUpdateEtablissement = (updatedEtablissement) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Voulez-vous vraiment modifier cet établissement?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, modifier!'
    }).then((result) => {
      if (result.isConfirmed) {
        setEtablissementList((prevEtablissementList) => prevEtablissementList.map((etablissement) => (etablissement._id === updatedEtablissement._id ? updatedEtablissement : etablissement)));
        setShowUpdateModal(false);
        Swal.fire(
          'Modifié!',
          'Votre établissement a été modifié.',
          'success'
        );
      }
      fetchAllEtablissementsAndServices();
    });
  };

  const handleDeleteEtablissement = (id) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Vous n\'avez pas les droits pour supprimer un établissement',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Voulez-vous vraiment supprimer cet établissement?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${BASE_URL}etablissements/${id}`, { headers })
          .then((response) => {
            if (response.status === 200) {
              const newList = etablissementList.filter((etablissement) => etablissement._id !== id);
              setEtablissementList(newList);
              Swal.fire(
                'Supprimé!',
                'Votre établissement a été supprimé.',
                'success'
              );
            }
            fetchAllEtablissementsAndServices();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const handleAddService = (service) => {
    setServiceList([...serviceList, service]);
    setShowServiceModal(false);
    Swal.fire({
      title: 'Service ajouté !',
      icon: 'success',
      confirmButtonText: 'OK',
    });
    fetchAllEtablissementsAndServices();
  };

  const handleAddServiceClick = () => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Vous n\'avez pas les droits pour ajouter un service',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setShowServiceModal(true);
  };

  const handleUpdateService = (updatedService) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Vous n\'avez pas les droits pour modifier un service',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setServiceList((prevServiceList) => prevServiceList.map((service) => (service.id === updatedService.id ? updatedService : service)));
    setShowUpdateServiceModal(false);
    Swal.fire({
      title: 'Service mis à jour !',
      icon: 'success',
      confirmButtonText: 'OK',
    });
    fetchAllEtablissementsAndServices();
  };

  useEffect(() => {
    fetchAllEtablissementsAndServices();
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const confirmCloseModal = (setModalState) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester'
    }).then((result) => {
      if (result.isConfirmed) {
        // Fermer la modal si l'utilisateur confirme
        setModalState(false);
      }
    });
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      margin: 'auto',
      width: '80%',
      marginLeft: '12%',
      height: '70%',
      padding: '30px',
      borderRadius: '0.5rem',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
      overflow: 'scroll', // Vous pourriez vouloir ajuster cela pour être 'auto' si vous voulez que le défilement vertical apparaisse uniquement si nécessaire
      overflowX: 'hidden', // Cela cache la scrollbar horizontale
      backgroundColor: theme === 'dark' ? 'grey' : '#fff',
      color: theme === 'dark' ? 'black' : '#000',
    },
  };

  const customStylesService = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      margin: 'auto',
      width: '80%',
      marginLeft: '12%',
      height: '20%',
      padding: '30px',
      borderRadius: '0.5rem',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
      overflow: 'scroll', // Vous pourriez vouloir ajuster cela pour être 'auto' si vous voulez que le défilement vertical apparaisse uniquement si nécessaire
      overflowX: 'hidden', // Cela cache la scrollbar horizontale
      backgroundColor: theme === 'dark' ? 'grey' : '#fff',
      color: theme === 'dark' ? 'black' : '#000',
    },
  };

  if (isLoading) return <AmbysoftLoader />;

  return (
    <div className="staffManagement" onMouseDown={handleClick}>
      <button className="AddTransport" onClick={handleAddEtablissementClick}>Ajouter un établissement</button>
      <table className="table">
        <thead>
          <tr className="table-row">
            <th className="table__header">Code</th>
            <th className="table__header">Nom</th>
            <th className="table__header">Adresse</th>
            <th className="table__header">Code postal</th>
            <th className="table__header">Contact</th>
            <th className="table__header">Téléphone</th>
            <th className="table__header">Actions</th>
          </tr>
        </thead>
        <tbody className="transportBody">
          {etablissementList.map((etablissement) => {
            const servicesForEtablissement = getServicesForEtablissement(etablissement.id);
            return (
              <React.Fragment key={etablissement.id}>
                <tr className="table-row" onClick={() => handleUpdateClick(etablissement)}>
                  <td className="table__cell">{etablissement.code}</td>
                  <td className="table__cell">{etablissement.name}</td>
                  <td className="table__cell">{etablissement.address}</td>
                  <td className="table__cell">{etablissement.zipcode}</td>
                  <td className="table__cell">{etablissement.contact}</td>
                  <td className="table__cell">{etablissement.phone}</td>
                  <td>
                    <div
                      className="show_services"
                      onClick={(e) => {
                        e.stopPropagation(); // Appelle la fonction
                        setShowServicesFor(etablissement.id === showServicesFor ? null : etablissement.id);
                      }}
                    >
                      Liste des services
                      {etablissement.id === showServicesFor ? <ArrowDropUpIcon fontSize="small" /> : <ArrowDropDownIcon fontSize="small" />}
                    </div>
                    <Tooltip title="Ajouter un service" arrow>
                      <IconButton onClick={(e) => {
                        e.stopPropagation(); // Appelle la fonction
                        setCurrentEtablissement(etablissement);
                        handleAddServiceClick();
                      }}
                      >
                        <AddHomeIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer l'établissement" arrow>
                      <IconButton onClick={(e) => {
                        e.stopPropagation(); // Appelle la fonction
                        handleDeleteEtablissement(etablissement.id);
                      }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
                {showServicesFor === etablissement.id && servicesForEtablissement.map((service) => (
                  <>

                    <tr className="table-row">
                      <th>UH</th>
                      <th>Nom</th>
                      <th>Téléphone</th>
                      <th>Actions</th>
                    </tr>
                    <tr className="table-row service-row" key={`${etablissement.id}-${service.id}`} onClick={() => handleUpdateClickService(service)}>
                      <td className="table__cell">{service.uh}</td>
                      <td className="table__cell">{service.name}</td>
                      <td className="table__cell">{service.phone}</td>
                      <td>
                        <Tooltip title="Supprimer le service" arrow>
                          <IconButton onClick={(e) => {
                            e.stopPropagation(); // Appelle la fonction
                            handleDeleteService(service.id);
                          }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  </>
                ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>

      {showModal && (
      <Modal
        style={customStyles}
        isOpen={showModal}
        onRequestClose={() => confirmCloseModal(setShowModal)}
        ref={(el) => {
          modalEtablissementRef.current = el && el.contentRef;
        }}
      >
        <AddEtablissementModal onAddEtablissement={handleAddEtablissement} userToken={userToken} />
      </Modal>
      )}

      {showUpdateServiceModal && (
        <Modal
          style={customStylesService}
          isOpen={showUpdateServiceModal}
          onRequestClose={() => confirmCloseModal(setShowUpdateServiceModal)}
          ref={(el) => {
            UpdateServiceModalRef.current = el && el.contentRef;
          }}
        >
          <UpdateServices
            service={currentService}
            handleUpdateService={handleUpdateService}
            handleModalClose={() => setShowUpdateServiceModal(false)}
            userToken={userToken}
          />
        </Modal>
      )}
      {showUpdateModal && (
        <Modal
          style={customStyles}
          isOpen={showUpdateModal}
          onRequestClose={() => confirmCloseModal(setShowUpdateModal)}
          ref={(el) => {
            UpdateEtablissementModalRef.current = el && el.contentRef;
          }}
        >
          <UpdateEtablissementModal
            etablissement={currentEtablissement}
            onUpdateEtablissement={handleUpdateEtablissement}
            handleModalClose={() => setShowUpdateModal(false)}
            userToken={userToken}
          />
        </Modal>
      )}
      <Modal
        style={customStylesService}
        isOpen={showServiceModal}
        onRequestClose={() => confirmCloseModal(setShowServiceModal)}
        ref={(el) => {
          ServiceModalRef.current = el && el.contentRef;
        }}
      >
        <AddServiceModal userToken={userToken} etablissement={currentEtablissement} handleAddService={handleAddService} />
      </Modal>
    </div>
  );
}

export default React.memo(Etablissements);
