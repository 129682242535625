/* eslint-disable react/prop-types */
// Fournisseurs.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Stack,
} from '@mui/material';
import {
  Search as SearchIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { BASE_URL } from '../../../utils/api';

function Fournisseurs({ userToken }) {
  // États pour les fournisseurs et les filtres
  const [fournisseurs, setFournisseurs] = useState([]);
  const [searchNom, setSearchNom] = useState('');
  const [searchCategorie, setSearchCategorie] = useState('');
  const [categories, setCategories] = useState([]);

  // États pour les opérations CRUD
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [formMode, setFormMode] = useState('create'); // 'create' ou 'edit'
  const [selectedFournisseur, setSelectedFournisseur] = useState(null);
  const [formData, setFormData] = useState({
    logo: '',
    nom: '',
    adresse: '',
    telephone: '',
    mail: '',
    site_web: '',
    categorie: '',
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  // Fonction pour récupérer les fournisseurs depuis l'API
  const fetchFournisseurs = async () => {
    try {
      const response = await axios.get(`${BASE_URL}fournisseurs`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setFournisseurs(response.data.fournisseurs);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des fournisseurs:', error);
    }
  };

  // Extraire les catégories uniques
  const extractCategories = () => {
    const uniqueCategories = [...new Set(fournisseurs.map((f) => f.categorie))];
    setCategories(uniqueCategories);
  };

  useEffect(() => {
    fetchFournisseurs();
  }, []);

  useEffect(() => {
    extractCategories();
  }, [fournisseurs]);

  // Filtrer les fournisseurs selon les critères
  const filteredFournisseurs = fournisseurs.filter((f) => (
    f.nom.toLowerCase().includes(searchNom.toLowerCase())
      && f.categorie.toLowerCase().includes(searchCategorie.toLowerCase())
  ));

  // Ouvrir le dialogue du formulaire
  const handleOpenFormDialog = (mode, fournisseur = null) => {
    setFormMode(mode);
    setSelectedFournisseur(fournisseur);
    if (fournisseur) {
      setFormData({ ...fournisseur });
    }
    else {
      setFormData({
        logo: '',
        nom: '',
        adresse: '',
        telephone: '',
        mail: '',
        site_web: '',
        categorie: '',
      });
    }
    setOpenFormDialog(true);
  };

  // Fermer le dialogue du formulaire
  const handleCloseFormDialog = () => {
    setOpenFormDialog(false);
    setSelectedFournisseur(null);
  };

  // Gérer les changements dans le formulaire
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Soumettre le formulaire pour créer ou éditer un fournisseur
  const handleFormSubmit = async () => {
    try {
      if (formMode === 'create') {
        await axios.post(`${BASE_URL}fournisseurs`, formData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setSnackbar({ open: true, message: 'Fournisseur créé avec succès', severity: 'success' });
      }
      else if (formMode === 'edit' && selectedFournisseur) {
        await axios.put(`${BASE_URL}fournisseurs/${selectedFournisseur.id}`, formData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setSnackbar({ open: true, message: 'Fournisseur mis à jour avec succès', severity: 'success' });
      }
      fetchFournisseurs();
      handleCloseFormDialog();
    }
    catch (error) {
      console.error('Erreur lors de l\'enregistrement du fournisseur:', error);
      setSnackbar({ open: true, message: 'Erreur lors de l\'enregistrement', severity: 'error' });
    }
  };

  // Supprimer un fournisseur
  const handleDeleteFournisseur = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce fournisseur ?')) {
      try {
        await axios.delete(`/api/fournisseurs/${id}`, { headers: { Authorization: `Bearer ${userToken}` } });
        setSnackbar({ open: true, message: 'Fournisseur supprimé avec succès', severity: 'success' });
        fetchFournisseurs();
      }
      catch (error) {
        console.error('Erreur lors de la suppression du fournisseur:', error);
        setSnackbar({ open: true, message: 'Erreur lors de la suppression', severity: 'error' });
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom sx={{ pt: 4 }}>
        Annuaire des fournisseurs
      </Typography>

      {/* Bouton pour ajouter un nouveau fournisseur */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenFormDialog('create')}
        sx={{ mb: 2 }}
      >
        Ajouter un Fournisseur
      </Button>

      {/* Filtres */}
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Rechercher par nom"
            variant="outlined"
            value={searchNom}
            onChange={(e) => setSearchNom(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="categorie-label">Catégorie</InputLabel>
            <Select
              labelId="categorie-label"
              label="Catégorie"
              value={searchCategorie}
              onChange={(e) => setSearchCategorie(e.target.value)}
            >
              <MenuItem value="">
                <em>Toutes les catégories</em>
              </MenuItem>
              {categories.map((categorie) => (
                <MenuItem key={categorie} value={categorie}>
                  {categorie}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Liste des fournisseurs */}
      <Grid container spacing={4}>
        {filteredFournisseurs.map((fournisseur) => (
          <Grid item key={fournisseur.id} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              {fournisseur.logo && (
                <CardMedia
                  component="img"
                  image={fournisseur.logo}
                  alt={`${fournisseur.nom} logo`}
                  sx={{ height: 140 }}
                />
              )}
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {fournisseur.nom}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {fournisseur.adresse}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Téléphone: {fournisseur.telephone}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Email:{' '}
                  <Link href={`mailto:${fournisseur.mail}`} underline="hover">
                    {fournisseur.mail}
                  </Link>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Site Web:{' '}
                  <Link href={fournisseur.site_web} target="_blank" rel="noopener" underline="hover">
                    {fournisseur.site_web}
                  </Link>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Catégorie: {fournisseur.categorie}
                </Typography>
              </CardContent>
              <Stack direction="row" spacing={1} sx={{ p: 1 }}>
                <IconButton
                  color="primary"
                  onClick={() => handleOpenFormDialog('edit', fournisseur)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={() => handleDeleteFournisseur(fournisseur.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog pour le formulaire d'ajout/édition */}
      <Dialog open={openFormDialog} onClose={handleCloseFormDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{formMode === 'create' ? 'Ajouter un Fournisseur' : 'Modifier le Fournisseur'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Nom"
            name="nom"
            fullWidth
            value={formData.nom}
            onChange={handleFormChange}
            required
          />
          <TextField
            margin="dense"
            label="Logo (URL)"
            name="logo"
            fullWidth
            value={formData.logo}
            onChange={handleFormChange}
          />
          <TextField
            margin="dense"
            label="Adresse"
            name="adresse"
            fullWidth
            value={formData.adresse}
            onChange={handleFormChange}
          />
          <TextField
            margin="dense"
            label="Téléphone"
            name="telephone"
            fullWidth
            value={formData.telephone}
            onChange={handleFormChange}
          />
          <TextField
            margin="dense"
            label="Email"
            name="mail"
            fullWidth
            value={formData.mail}
            onChange={handleFormChange}
          />
          <TextField
            margin="dense"
            label="Site Web"
            name="site_web"
            fullWidth
            value={formData.site_web}
            onChange={handleFormChange}
          />
          <TextField
            margin="dense"
            label="Catégorie"
            name="categorie"
            fullWidth
            value={formData.categorie}
            onChange={handleFormChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFormDialog}>Annuler</Button>
          <Button onClick={handleFormSubmit} variant="contained" color="primary">
            {formMode === 'create' ? 'Ajouter' : 'Enregistrer'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar pour les messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default React.memo(Fournisseurs);
