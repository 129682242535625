/* eslint-disable react/prop-types */
/* eslint-disable max-len */
// Procedures.jsx
import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Feed as FeedIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { addDays } from 'date-fns';
import { BASE_URL } from '../../../utils/api';
import usePageTitle from '../../hooks/usePageTitle';
import AddProcedures from './addProcedures/addProcedures';
import UpdateProcedures from './updateProcedures/updateProcedures';
import UserProcedureForm from './userProcedures/userProcedures';
import ProcedureResponsesModal from './userProcedures/proceduresResponses/proceduresResponsesModal';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import './proceduresStyles.scss';

function Procedures({ userRole, userToken }) {
  usePageTitle('DRIVESOFT | Gestion des procédures');

  const [procedures, setProcedures] = useState([]);
  const [proceduresWithDates, setProceduresWithDates] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentProcedure, setCurrentProcedure] = useState(null);
  const [showFillModal, setShowFillModal] = useState(false);
  const [showResponsesModal, setShowResponsesModal] = useState(false);
  const [selectedProcedureId, setSelectedProcedureId] = useState(null);
  const [vehicleCode, setVehicleCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [vehicules, setVehicules] = useState([]);
  const [staff, setStaff] = useState([]);
  const [crews, setCrews] = useState([]);
  const [userEmail, setUserEmail] = useState('');

  // Nouveaux états pour les types de procédures
  const [types, setTypes] = useState([]);
  const [selectedProcedureType, setSelectedProcedureType] = useState('');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isAuthorizedRole = () => {
    const authorizedRoles = [
      'Administrateur',
      'Direction',
      'Responsable exploitation',
      'Gestionnaire de stock',
    ];
    return authorizedRoles.includes(userRole);
  };

  const currentDate = new Date().toISOString().slice(0, 10);

  console.log(procedures);

  // Récupération du staff, des équipages et des véhicules
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [staffRes, crewsRes, vehiculesRes] = await Promise.all([
          axios.get(`${BASE_URL}staff`, { headers }),
          axios.get(`${BASE_URL}crews`, { headers }),
          axios.get(`${BASE_URL}vehicules`, { headers }),
        ]);
        setStaff(staffRes.data.staff);
        setCrews(crewsRes.data.crews);
        const sortedVehicles = vehiculesRes.data.vehicule.sort((a, b) => a.code.localeCompare(b.code));
        setVehicules(sortedVehicles);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des données', error);
      }
      finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Récupération des procédures
  const fetchProcedures = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}procedures`, { headers });
      setProcedures(response.data);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des procédures', error);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProcedures();
  }, []);

  // Extraction des types uniques des procédures
  useEffect(() => {
    if (procedures.length > 0) {
      const uniqueTypes = [];
      const typeIds = new Set();
      procedures.forEach((proc) => {
        if (proc.types) {
          proc.types.forEach((type) => {
            if (!typeIds.has(type.id)) {
              typeIds.add(type.id);
              uniqueTypes.push(type);
            }
          });
        }
      });
      setTypes(uniqueTypes);
    }
  }, [procedures]);

  // Détermination du vehicleCode en fonction de l'utilisateur connecté
  useEffect(() => {
    const email = localStorage.getItem('userEmail');
    setUserEmail(email);

    if (staff.length > 0 && crews.length > 0 && vehicules.length > 0) {
      const staffMember = staff.find((member) => member.email === userEmail);
      if (staffMember) {
        const staffId = staffMember.id;

        // Trouver l'équipage contenant ce membre du staff pour la date actuelle
        const crew = crews.find(
          (c) => (c.staff_id_1 === staffId || c.staff_id_2 === staffId)
            && c.date.startsWith(currentDate)
            && c.vehicule_id !== null
        );
        if (crew) {
          const vehicle = vehicules.find((v) => v.id === crew.vehicule_id);
          if (vehicle) {
            setVehicleCode(vehicle.code);
          }
        }
      }
    }
  }, [currentDate, staff, crews, vehicules, userEmail]);

  const calculateNextAllowedDate = async (procedure) => {
    try {
      if (!vehicleCode) {
        return new Date();
      }
      const response = await axios.get(
        `${BASE_URL}procedure-responses?procedureId=${procedure.id}&vehicleCode=${vehicleCode}`,
        { headers }
      );
      if (response.data.length > 0) {
        const lastResponse = response.data[response.data.length - 1];
        const lastResponseDate = new Date(lastResponse.submitted_at);

        let nextDate = null;
        switch (procedure.frequency) {
          case 'Hebdomadaire':
            nextDate = addDays(lastResponseDate, 7);
            break;
          case 'Bihebdomadaire':
            nextDate = addDays(lastResponseDate, 14);
            break;
          case 'Mensuelle':
            nextDate = addDays(lastResponseDate, 30);
            break;
          case 'Annuelle':
            nextDate = addDays(lastResponseDate, 365);
            break;
          case 'Quotidienne':
            nextDate = addDays(lastResponseDate, 1);
            break;
          case 'Ponctuelle':
            nextDate = null;
            break;
          default:
            break;
        }
        if (nextDate) {
          return nextDate;
        }
      }
      return new Date();
    }
    catch (error) {
      console.error('Erreur lors de la récupération des réponses', error);
      return new Date();
    }
  };

  const handleGoToHistorique = () => {
    navigate('/activityManagement/processManagement/historique');
  };

  const findVehiclesNotResponded = async (procedureId, procedureFrequency) => {
    try {
      const responses = await axios.get(
        `${BASE_URL}procedure-responses?procedureId=${procedureId}`,
        { headers }
      );

      const validResponses = responses.data.filter((response) => {
        const responseDate = new Date(response.submitted_at);
        const currentDateVeh = new Date();
        const timeDiff = currentDateVeh.getTime() - responseDate.getTime();

        switch (procedureFrequency) {
          case 'Quotidienne':
            return timeDiff < 1 * 24 * 60 * 60 * 1000;
          case 'Hebdomadaire':
            return timeDiff < 7 * 24 * 60 * 60 * 1000;
          case 'Bihebdomadaire':
            return timeDiff < 14 * 24 * 60 * 60 * 1000;
          case 'Mensuelle':
            return timeDiff < 30 * 24 * 60 * 60 * 1000;
          case 'Annuelle':
            return timeDiff < 365 * 24 * 60 * 60 * 1000;
          default:
            return false;
        }
      });

      const respondedVehicleIds = validResponses.map((response) => response.vehicule_id.toString());
      const vehiclesNotResponded = vehicules.filter(
        (v) => !respondedVehicleIds.includes(v.code.toString())
      );
      return vehiclesNotResponded.map((v) => v.code).join(', ');
    }
    catch (error) {
      console.error('Erreur lors de la récupération des réponses', error);
      return 'Erreur';
    }
  };

  useEffect(() => {
    const updateProceduresWithDatesAndResponses = async () => {
      const updatedProcedures = await Promise.all(
        procedures.map(async (procedure) => {
          const nextDate = await calculateNextAllowedDate(procedure);
          const vehiclesNotResponded = await findVehiclesNotResponded(
            procedure.id,
            procedure.frequency
          );

          // Extraction des noms des types
          const typeNames = procedure.types
            ? procedure.types.map((type) => type.name)
            : [];

          return {
            ...procedure,
            nextAllowedDate: nextDate,
            vehiclesNotResponded,
            typesNames: typeNames, // Ajouter les noms des types
          };
        })
      );
      setProceduresWithDates(updatedProcedures);
    };

    if (procedures.length > 0) {
      updateProceduresWithDatesAndResponses();
    }
  }, [procedures, vehicleCode]);

  const handleViewResponses = (procedureId) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title:
          "Vous n'avez pas les droits pour voir les réponses des procédures",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setSelectedProcedureId(procedureId);
    setShowResponsesModal(true);
  };

  const handleEditProcedure = (procedure) => {
    if (isAuthorizedRole()) {
      setCurrentProcedure(procedure);
      setShowUpdateModal(true);
    }
    else {
      Swal.fire({
        icon: 'error',
        title: "Vous n'avez pas les droits pour modifier une procédure",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleFillClick = (e, procedureId) => {
    e.stopPropagation();
    const procedureToOpen = procedures.find((p) => p.id === procedureId);
    setCurrentProcedure(procedureToOpen);
    setShowFillModal(true);
    setShowUpdateModal(false);
  };

  const handleAddProcedure = (procedure) => {
    setProcedures((prevProcedures) => [...prevProcedures, procedure]);
    setShowAddModal(false);
    Swal.fire({
      icon: 'success',
      title: 'Procédure créée avec succès',
      showConfirmButton: false,
      timer: 1500,
    });
    fetchProcedures();
  };

  const handleAddProcedureClick = () => {
    if (isAuthorizedRole()) {
      setShowAddModal(true);
    }
    else {
      Swal.fire({
        icon: 'error',
        title: "Vous n'avez pas les droits pour créer une procédure",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleUpdateProcedure = async (procedure) => {
    const confirmation = await Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir modifier cette procédure?',
      showDenyButton: true,
      confirmButtonText: 'Oui',
      denyButtonText: 'Non',
    });
    if (confirmation.isConfirmed) {
      const index = procedures.findIndex((p) => p.id === currentProcedure.id);
      const newProcedures = [
        ...procedures.slice(0, index),
        procedure,
        ...procedures.slice(index + 1),
      ];
      setProcedures(newProcedures);
      setShowUpdateModal(false);
      Swal.fire({
        icon: 'success',
        title: 'Procédure modifiée avec succès',
        showConfirmButton: false,
        timer: 1500,
      });
      fetchProcedures();
    }
  };

  const handleDeleteProcedure = async (id) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: "Vous n'avez pas les droits pour supprimer une procédure",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    const confirmation = await Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir supprimer cette procédure?',
      showDenyButton: true,
      confirmButtonText: 'Oui',
      denyButtonText: 'Non',
    });
    if (confirmation.isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}procedures/${id}`, { headers });
        const newList = procedures.filter((procedure) => procedure.id !== id);
        setProcedures(newList);
        Swal.fire({
          icon: 'success',
          title: 'Procédure supprimée avec succès',
          showConfirmButton: false,
          timer: 1500,
        });
      }
      catch (error) {
        console.error('Erreur lors de la suppression de la procédure', error);
      }
    }
  };

  const confirmCloseModal = (setModalState) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester',
    }).then((result) => {
      if (result.isConfirmed) {
        setModalState(false);
      }
    });
  };

  if (loading) {
    return <AmbysoftLoader />;
  }

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: 2 }}
      >
        <Grid item>
          <Typography variant="h5">Gestion des procédures</Typography>
        </Grid>
        {isAuthorizedRole() && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddProcedureClick}
            >
              Créer une procédure
            </Button>
          </Grid>
        )}
        <Grid>
          <Button variant="outlined" color="secondary" onClick={handleGoToHistorique}>
            Historique
          </Button>
        </Grid>
        {/* Filtre des types */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="type-filter-label">Filtrer par type</InputLabel>
            <Select
              labelId="type-filter-label"
              id="type-filter"
              value={selectedProcedureType}
              onChange={(e) => setSelectedProcedureType(e.target.value)}
              label="Filtrer par type"
            >
              <MenuItem value="">
                <em>Tous les types</em>
              </MenuItem>
              {types.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nom de la procédure</TableCell>
            <TableCell>Fréquence</TableCell>
            <TableCell>Types</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {proceduresWithDates
            .filter((procedure) => {
              if (!selectedProcedureType) return true;
              return (
                procedure.types?.some(
                  (type) => type.id === parseInt(selectedProcedureType, 10)
                ) || false
              );
            })
            .map((procedure) => (
              <TableRow key={procedure.id} hover>
                <TableCell>{procedure.name}</TableCell>
                <TableCell>{procedure.frequency}</TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {procedure.typesNames.join(', ')}
                  </Typography>
                </TableCell>
                <TableCell>

                  {isAuthorizedRole() && (
                  <>
                    <Tooltip title="Compléter la procédure" placement="top">
                      <IconButton
                        aria-label="fill"
                        onClick={(e) => handleFillClick(e, procedure.id)}
                      >
                        <FeedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Historique des réponses"
                      placement="top"
                    >
                      <IconButton
                        aria-label="view responses"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleViewResponses(procedure.id);
                        }}
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Modifier la procédure"
                      placement="top"
                    >
                      <IconButton
                        aria-label="edit"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditProcedure(procedure);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Supprimer la procédure"
                      placement="top"
                    >
                      <IconButton
                        aria-label="delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteProcedure(procedure.id);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      {/* Dialog pour ajouter une procédure */}
      <Dialog
        open={showAddModal}
        onClose={() => confirmCloseModal(setShowAddModal)}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Créer une procédure</DialogTitle>
        <DialogContent>
          <AddProcedures
            userToken={userToken}
            handleAddProcedure={handleAddProcedure}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => confirmCloseModal(setShowAddModal)}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog pour modifier une procédure */}
      <Dialog
        open={showUpdateModal}
        onClose={() => confirmCloseModal(setShowUpdateModal)}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Modifier une procédure</DialogTitle>
        <DialogContent>
          <UpdateProcedures
            userToken={userToken}
            currentProcedures={currentProcedure}
            onUpdateProcedures={handleUpdateProcedure}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => confirmCloseModal(setShowUpdateModal)}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog pour compléter une procédure */}
      <Dialog
        open={showFillModal}
        onClose={() => {
          setShowFillModal(false);
          setCurrentProcedure(null);
        }}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Compléter la procédure</DialogTitle>
        <DialogContent>
          {currentProcedure && (
            <UserProcedureForm
              userToken={userToken}
              procedureData={currentProcedure}
              onClose={() => setShowFillModal(false)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowFillModal(false);
              setCurrentProcedure(null);
            }}
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog pour afficher les réponses */}
      <ProcedureResponsesModal
        procedureId={selectedProcedureId}
        isOpen={showResponsesModal}
        onRequestClose={() => setShowResponsesModal(false)}
        userToken={userToken}
      />
    </Paper>
  );
}

export default React.memo(Procedures);
