/* eslint-disable max-len */
import React from 'react';
import './privacyStyles.scss';

function Privacy() {
  return (
    <div className="privacy-policy-container">
      <h1>Politique de confidentialité - DRIVESOFT</h1>
      <p>Dernière mise à jour : 17/11/2024</p>

      <p>
        DRIVESOFT accorde une importance primordiale à la protection de vos données personnelles. Cette politique de confidentialité détaille comment nous collectons, utilisons, stockons et protégeons vos données, conformément au Règlement Général sur la Protection des Données (RGPD) et autres lois applicables.
      </p>

      <h2>Collecte de Données</h2>
      <p>
        Nous collectons uniquement les données nécessaires pour fournir nos services. Ces données incluent :
      </p>
      <ul>
        <li><strong>Informations d'identification :</strong> Nom, prénom, adresse e-mail, numéro de téléphone.</li>
        <li><strong>Données d'utilisation :</strong> Historique d’utilisation de l’application, pages consultées, interactions.</li>
        <li><strong>Informations techniques :</strong> Adresse IP, type d'appareil, système d'exploitation, identifiants uniques.</li>
      </ul>

      <h2>Base légale et finalités du traitement</h2>
      <p>Vos données sont collectées et traitées sur les bases légales suivantes :</p>
      <ul>
        <li>Pour exécuter le contrat liant DRIVESOFT et ses utilisateurs.</li>
        <li>Pour respecter des obligations légales.</li>
        <li>Pour des intérêts légitimes, tels que l’amélioration des services ou la prévention des fraudes.</li>
        <li>Avec votre consentement explicite pour certaines fonctionnalités optionnelles.</li>
      </ul>

      <h2>Utilisation des données</h2>
      <p>Les données collectées sont utilisées pour :</p>
      <ul>
        <li>Fournir et maintenir nos services.</li>
        <li>Garantir la sécurité et la stabilité de l'application.</li>
        <li>Analyser et améliorer l'expérience utilisateur.</li>
        <li>Respecter nos obligations légales.</li>
        <li>Communiquer avec vous pour des mises à jour ou des informations liées à nos services.</li>
      </ul>

      <h2>Partage de Données</h2>
      <p>
        Vos données personnelles ne seront jamais vendues. Nous partageons vos informations uniquement dans les cas suivants :
      </p>
      <ul>
        <li>Avec des prestataires de services tiers pour l’hébergement sécurisé, les analyses ou le support technique.</li>
        <li>Pour répondre à des obligations légales ou en cas de demande judiciaire valide.</li>
        <li>Dans le cadre d'une fusion, acquisition ou autre transfert d’actifs, avec notification préalable.</li>
      </ul>

      <h2>Conservation des Données</h2>
      <p>
        Vos données personnelles sont conservées uniquement pour la durée nécessaire aux finalités définies dans cette politique ou pour respecter des obligations légales. Une fois cette période expirée, elles sont supprimées ou anonymisées.
      </p>

      <h2>Sécurité des Données</h2>
      <p>
        Nous utilisons des mesures de sécurité rigoureuses pour protéger vos données :
      </p>
      <ul>
        <li>Chiffrement des données en transit (TLS 1.2 ou supérieur) et au repos (AES-256).</li>
        <li>Authentification multi-facteurs pour accéder à nos services.</li>
        <li>Séparation stricte des environnements de développement, test et production.</li>
        <li>Sauvegardes chiffrées et testées régulièrement.</li>
      </ul>

      <h2>Vos Droits</h2>
      <p>Conformément au RGPD, vous disposez des droits suivants :</p>
      <ul>
        <li>Accéder à vos données personnelles.</li>
        <li>Demander la rectification de vos données inexactes.</li>
        <li>Demander l’effacement de vos données, dans la limite des obligations légales.</li>
        <li>Restreindre ou s’opposer au traitement de vos données.</li>
        <li>Exercer votre droit à la portabilité des données.</li>
      </ul>
      <p>
        Vous pouvez exercer vos droits en nous contactant à : <a href="mailto:contact@drivesoft.fr">contact@drivesoft.fr</a>
      </p>

      <h2>Modifications de cette Politique</h2>
      <p>
        Nous pouvons mettre à jour cette politique pour refléter les changements dans nos pratiques ou pour des raisons légales. Toute modification sera publiée sur cette page avec une date de mise à jour.
      </p>

      <h2>Contact</h2>
      <p>Pour toute question ou préoccupation concernant cette politique de confidentialité :</p>
      <ul>
        <li>Email : <a href="mailto:contact@drivesoft.fr">contact@drivesoft.fr</a></li>
        <li>Téléphone : (+33)652807736</li>
      </ul>
    </div>
  );
}

export default React.memo(Privacy);
