/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Button, Snackbar, Alert, IconButton, Chip, Menu, MenuItem
} from '@mui/material';
import axios from 'axios';
import {
  MoreVert, CheckCircle, Cancel
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../utils/api';

function GestionDevis({ userToken }) {
  const [devisList, setDevisList] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDevis, setSelectedDevis] = useState(null);
  const navigate = useNavigate();

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchDevis = async () => {
    try {
      const response = await axios.get(`${BASE_URL}devis`, { headers });
      setDevisList(response.data.devis);
    }
    catch (error) {
      setSnackbar({ open: true, message: 'Erreur lors du chargement des devis.', severity: 'error' });
    }
  };

  useEffect(() => {
    fetchDevis();
  }, []);

  const handleStatusChange = async (id, newStatus) => {
    try {
      const devisToUpdate = devisList.find((devis) => devis.id === id);
      if (devisToUpdate) {
        const updatedDevis = { ...devisToUpdate, status: newStatus };
        await axios.put(`${BASE_URL}devis/${id}`, updatedDevis, { headers });
        setSnackbar({ open: true, message: 'Statut mis à jour.', severity: 'success' });
        fetchDevis();
      }
    }
    catch (error) {
      setSnackbar({ open: true, message: 'Erreur lors de la mise à jour du statut.', severity: 'error' });
    }
  };
  const fetchSignedUrlForViewing = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}devis/get-signed-url`, { key }, { headers });
      const signedUrl = response.data.url;
      window.open(signedUrl, '_blank');
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}devis/${id}`, { headers });
      setSnackbar({ open: true, message: 'Devis supprimé avec succès.', severity: 'success' });
      fetchDevis();
    }
    catch (error) {
      setSnackbar({ open: true, message: 'Erreur lors de la suppression.', severity: 'error' });
    }
  };

  const handleAction = (action, devis) => {
    setAnchorEl(null);
    switch (action) {
      case 'edit':
        navigate('/devis', { state: { initialValues: devis.data } });
        break;
      case 'delete':
        handleDelete(devis.id);
        break;
      case 'send':
        handleStatusChange(devis.id, 'Devis envoyé');
        break;
      case 'validate':
        handleStatusChange(devis.id, 'Devis validé');
        break;
      case 'refuse':
        handleStatusChange(devis.id, 'Devis refusé');
        break;
      case 'download':
        fetchSignedUrlForViewing(devis.pdf_file_url);
        break;
      default:
        break;
    }
  };

  const getStatusChip = (status) => {
    switch (status) {
      case 'Devis envoyé':
        return <Chip label="Envoyé" color="primary" icon={<CheckCircle />} />;
      case 'Devis validé':
        return <Chip label="Validé" color="success" icon={<CheckCircle />} />;
      case 'Devis refusé':
        return <Chip label="Refusé" color="error" icon={<Cancel />} />;
      default:
        return <Chip label="En cours" color="default" />;
    }
  };

  return (
    <Box sx={{
      padding: 4, borderRadius: 2, margin: 'auto'
    }}
    >
      <Button variant="contained" color="primary" sx={{ marginBottom: 2 }} onClick={() => navigate('/devis')}>
        Créer un nouveau devis
      </Button>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Numéro</TableCell>
                <TableCell>Date du devis</TableCell>
                <TableCell>Fin de validité</TableCell>
                <TableCell>Demandeur</TableCell>
                <TableCell>Départ</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devisList.map((devis) => (
                <TableRow key={devis.id}>
                  <TableCell>{devis.estimate_number}</TableCell>
                  <TableCell>{new Date(devis.date_created).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(devis.validity_date).toLocaleDateString()}</TableCell>
                  <TableCell>{devis.data.recipientName}</TableCell>
                  <TableCell>{devis.data.selectedCity}</TableCell>
                  <TableCell>{devis.data.selectedDestinationCity}</TableCell>
                  <TableCell>{getStatusChip(devis.status)}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(e) => {
                        setSelectedDevis(devis);
                        setAnchorEl(e.currentTarget);
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem onClick={() => handleAction('edit', selectedDevis)}>Modifier</MenuItem>
                      <MenuItem onClick={() => handleAction('delete', selectedDevis)}>Supprimer</MenuItem>
                      <MenuItem onClick={() => handleAction('send', selectedDevis)}>Envoyer</MenuItem>
                      <MenuItem onClick={() => handleAction('validate', selectedDevis)}>Valider</MenuItem>
                      <MenuItem onClick={() => handleAction('refuse', selectedDevis)}>Refuser</MenuItem>
                      {selectedDevis?.pdf_file_url && (
                        <MenuItem onClick={() => handleAction('download', selectedDevis)}>
                          Télécharger le PDF
                        </MenuItem>
                      )}
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Box>
  );
}

export default React.memo(GestionDevis);
