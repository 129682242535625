/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
  Card,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Doughnut, Bar } from 'react-chartjs-2';

/**
 * Une petite fonction utilitaire pour détecter le préfixe dans incident.incident
 * et renvoyer l'établissement concerné + la vraie description.
 */
function parseIncidentText(incidentText) {
  if (!incidentText) return { facilityPrefix: '', description: '' };

  let facilityPrefix = '';
  let description = incidentText;

  if (incidentText.startsWith('[DEPART]')) {
    facilityPrefix = 'Établissement de départ';
    description = incidentText.replace('[DEPART]', '').trim();
  }
  else if (incidentText.startsWith('[ARRIV]')) {
    facilityPrefix = 'Établissement d’arrivée';
    description = incidentText.replace('[ARRIV]', '').trim();
  }
  else if (incidentText.startsWith('[BOTH]')) {
    facilityPrefix = 'Établissement de départ et arrivée';
    description = incidentText.replace('[BOTH]', '').trim();
  }

  return { facilityPrefix, description };
}

function FacilityIncidents({
  establishments,
  selectedEstablishment,
  handleEstablishmentChange,
  currentIncidents,
  paginate,
  currentPage,
  totalPages,
  incidentsByEstablishment,
  establishmentIncidentData,
  incidentColors,
  pastelColors,
  handleChartClick,
}) {
  const muiTheme = useTheme();
  const isSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const barChartRef = useRef(null);

  // Préparer les données pour le graphique en barres horizontal
  const incidentsPerEstablishment = Object.entries(incidentsByEstablishment)
    .sort((a, b) => b[1] - a[1]); // Tri décroissant

  const barChartDataEstablishments = {
    labels: incidentsPerEstablishment.map(([establishmentName]) => establishmentName),
    datasets: [
      {
        label: "Nombre d'incidents par établissement",
        data: incidentsPerEstablishment.map(([, count]) => count),
        backgroundColor: pastelColors.slice(0, incidentsPerEstablishment.length),
      },
    ],
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* Sélecteur d'établissement */}
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="establishment-select-label">Sélectionner un établissement</InputLabel>
        <Select
          labelId="establishment-select-label"
          value={selectedEstablishment}
          label="Sélectionner un établissement"
          onChange={handleEstablishmentChange}
        >
          <MenuItem value="">
            <em>Tous les établissements</em>
          </MenuItem>
          {establishments.map((est) => (
            <MenuItem key={est.id} value={est.name}>
              {est.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Tableau des incidents */}
      <TableContainer component={Paper}>
        <Table aria-label="Incidents des établissements">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Nom du patient</TableCell>
              <TableCell>Heure de départ</TableCell>
              <TableCell>Établissement</TableCell>
              <TableCell>Service</TableCell>
              <TableCell>Raison mission</TableCell>
              <TableCell>Type d'incident</TableCell>
              <TableCell>Description de l'incident</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentIncidents.map((incident) => {
              // 1. Parse le champ incident pour trouver le préfixe
              const { facilityPrefix, description } = parseIncidentText(incident.incident);

              // 2. Sélectionner le(s) vrai(s) nom(s) d’établissement selon le préfixe
              let establishmentName = 'N/A';
              if (facilityPrefix === 'Établissement de départ') {
                establishmentName = incident.departure_label || 'Inconnu';
              }
              else if (facilityPrefix === 'Établissement d’arrivée') {
                establishmentName = incident.arrival_label || 'Inconnu';
              }
              else if (facilityPrefix === 'Établissement de départ et arrivée') {
                const dep = incident.departure_label || 'Inconnu';
                const arr = incident.arrival_label || 'Inconnu';
                establishmentName = `${dep} / ${arr}`;
              }
              else {
                // Pas de préfixe => fallback : on suppose l'établissement de départ
                establishmentName = incident.departure_label || 'N/A';
              }

              return (
                <TableRow key={incident.id}>
                  <TableCell>{new Date(incident.date).toLocaleDateString()}</TableCell>
                  <TableCell>{incident.last_name}</TableCell>
                  <TableCell>{incident.departure_hour}</TableCell>

                  {/* Le vrai nom du / des établissement(s) selon le préfixe */}
                  <TableCell>{establishmentName}</TableCell>

                  <TableCell>{incident.service}</TableCell>
                  <TableCell>{incident.reason}</TableCell>
                  <TableCell>{incident.incident_type}</TableCell>

                  {/* Description de l'incident (préfixe + texte) */}
                  <TableCell>
                    {facilityPrefix && (
                    <strong>
                      {facilityPrefix}
                      <br />
                    </strong>
                    )}
                    {description || ''}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginY: 2 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => paginate(page)}
          color="primary"
        />
      </Box>

      {/* Graphiques */}
      {selectedEstablishment && establishmentIncidentData[selectedEstablishment] ? (
        <Box sx={{ marginTop: 4 }}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Répartition des incidents pour {selectedEstablishment}
              </Typography>
              <Box sx={{ height: isSmDown ? 250 : 400 }}>
                <Doughnut
                  data={{
                    labels: Object.keys(establishmentIncidentData[selectedEstablishment]),
                    datasets: [{
                      data: Object.values(establishmentIncidentData[selectedEstablishment]),
                      backgroundColor: Object.keys(establishmentIncidentData[selectedEstablishment]).map(
                        (type) => incidentColors[type] || '#000000'
                      ),
                    }],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          color: muiTheme.palette.text.primary,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <Box sx={{ marginTop: 4 }}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Nombre d'incidents par établissement
              </Typography>
              <Box sx={{ height: isSmDown ? 500 : 600 }}>
                <Bar
                  ref={barChartRef}
                  data={barChartDataEstablishments}
                  options={{
                    indexAxis: 'y',
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          color: muiTheme.palette.text.primary,
                        },
                        beginAtZero: true,
                      },
                      y: {
                        ticks: {
                          color: muiTheme.palette.text.primary,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    onClick: (event) => handleChartClick(event, barChartRef.current),
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
}

export default React.memo(FacilityIncidents);
