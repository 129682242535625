/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Button, TextField, IconButton, Tooltip, Grid, Accordion, AccordionSummary, AccordionDetails, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../../utils/api';
import DocumentsImages from '../documentsImages';

function GeneralDocument({
  userRole, theme, userToken, userEmail
}) {
  const [file, setFile] = useState(null);
  const [category, setCategory] = useState('');
  const [viewImageUrl, setViewImageUrl] = useState('');
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [title, setTitle] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [viewHistoryOpen, setViewHistoryOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [staffViewHistory, setStaffViewHistory] = useState([]);

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Direction', 'Responsable exploitation', 'Ressources humaines'];
    return authorizedRoles.includes(userRole);
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const themeStyles = {
    backgroundColor: theme === 'dark' ? '#424242' : '#ffffff',
    color: theme === 'dark' ? '#ffffff' : '#000000',
  };

  const fetchDocumentMetadata = async () => {
    const userEmail = localStorage.getItem('userEmail');
    if (userEmail) {
      try {
        const staffResponse = await axios.get(`${BASE_URL}staff/email/${userEmail}`, { headers });
        const userCompanyCode = staffResponse.data.staff.company;
        const companiesResponse = await axios.get(`${BASE_URL}companies`, { headers });
        const userCompany = companiesResponse.data.companies.find((company) => company.code === userCompanyCode);

        const documentsResponse = await axios.get(`${BASE_URL}documents`, { headers });
        let filteredDocuments = documentsResponse.data.documents;
        if (!isAuthorizedRole(userRole)) {
          const userCompanyId = userCompany ? userCompany.id : null;
          filteredDocuments = filteredDocuments.filter((doc) => doc.company_ids.includes(userCompanyId) || doc.company_ids.includes('all'));
        }

        // Stocker seulement les métadonnées nécessaires
        const metadata = filteredDocuments.map((doc) => ({
          id: doc.id,
          title: doc.title,
          category: doc.category,
          file_path: doc.file_path,
          company_ids: doc.company_ids,
        }));
        setDocuments(metadata);
      }
      catch (error) {
        console.error('Error fetching document metadata:', error);
      }
    }
  };

  useEffect(() => {
    fetchDocumentMetadata();
  }, []);

  const handleChange = (panel, docId) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(response.data.companies);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des sociétés :', error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const clearFormFields = () => {
    setTitle('');
    setCategory('');
    setSelectedCompanies([]);
    setFile(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setSelectedFileName(file.name); // Mettre à jour le nom du fichier sélectionné
    }
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleCompanyChange = (event) => {
    setSelectedCompanies(event.target.value);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (!file || !category) return;

    try {
      // Demander une URL présignée pour l'image convertie ou le fichier original
      const presignedResponse = await axios.post(`${BASE_URL}documents/presigned`, { name: file.name, type: file.type }, { headers });
      const { url, fields } = presignedResponse.data;

      // Préparer les données pour l'upload
      const uploadData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        uploadData.append(key, value);
      });
      uploadData.append('file', file);

      // Upload du fichier sur S3
      await axios.post(url, uploadData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Enregistrer les informations du document dans la base de données
      const documentData = {
        title: title,
        category: category,
        company_ids: selectedCompanies[0] === -1 ? ['all'] : selectedCompanies,
        file_path: fields.key // Chemin du fichier sur S3
      };
      await axios.post(`${BASE_URL}documents`, documentData, { headers });

      fetchDocumentMetadata(); // Rafraîchir la liste des documents
      Swal.fire('Succès', 'Document ajouté avec succès', 'success');
      clearFormFields(); // Effacer les champs du formulaire
    }
    catch (error) {
      console.error('Erreur lors de l’upload du fichier :', error);
      Swal.fire('Erreur', 'Une erreur est survenue lors de l’ajout du document', 'error');
    }
  }

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}documents/${id}`, { headers });
      fetchDocumentMetadata(); // Rafraîchir la liste des documents
      Swal.fire('Succès', 'Document supprimé avec succès', 'success');
    }
    catch (error) {
      console.error('Erreur lors de la suppression du document :', error);
      Swal.fire('Erreur', 'Une erreur est survenue lors de la suppression du document', 'error');
    }
  };

  const downloadImage = async (signedUrl, filename) => {
    try {
      const response = await axios.get(signedUrl, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'image/jpeg' }); // Ajustez le type MIME si nécessaire
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename || 'image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }
    catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  const fetchSignedUrlForDownloading = async (key) => {
    try {
      const response = await axios.post(
        `${BASE_URL}documents/get-signed-url`,
        { key, userEmail }, // Inclure userEmail ici
        { headers }
      );
      downloadImage(response.data.url, key.split('/').pop());
    }
    catch (error) {
      console.error('Erreur lors de la récupération de l’URL signée :', error);
    }
  };

  const fetchSignedUrlForViewing = async (key) => {
    try {
      const response = await axios.post(
        `${BASE_URL}documents/get-signed-url`,
        { key, userEmail }, // Inclure userEmail ici
        { headers }
      );
      setViewImageUrl(response.data.url);
    }
    catch (error) {
      console.error('Erreur lors de la récupération de l’URL signée :', error);
    }
  };

  useEffect(() => {
    if (viewImageUrl) {
      window.open(viewImageUrl, '_blank');
    }
  }, [viewImageUrl]);

  const handleViewHistory = async (documentId) => {
    try {
      // Récupérer l'historique des vues détaillé
      const response = await axios.get(`${BASE_URL}documents/${documentId}/view-history`, { headers });
      setStaffViewHistory(response.data.staff);
      setSelectedDocumentId(documentId);
      setViewHistoryOpen(true);
    }
    catch (error) {
      console.error('Erreur lors de la récupération de l’historique des vues :', error);
      Swal.fire('Erreur', 'Une erreur est survenue lors de la récupération de l’historique des vues', 'error');
    }
  };

  const documentsByCategory = documents.reduce((acc, doc) => {
    acc[doc.category] = acc[doc.category] || [];
    acc[doc.category].push(doc);
    return acc;
  }, {});

  const formControlStyle = {
    minWidth: 200, // Ajustez cette valeur selon vos besoins
  };

  const renderSelectedCompanies = (selectedIds) => {
    // Trouver les codes de société correspondant aux ID sélectionnés
    const selectedCodes = selectedIds.map((id) => {
      const company = companies.find((company) => company.id === id);
      return company ? company.code : null;
    });

    // Filtrer les valeurs non valides et les joindre en une chaîne
    return selectedCodes.filter((code) => code !== null).join(', ');
  };

  return (
    <div>
      {isAuthorizedRole(userRole) && (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4} alignItems="center" justifyContent="center" style={themeStyles}>
          <Grid item>
            <input
              id="file-input"
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <Button style={themeStyles} variant="contained" component="label" htmlFor="file-input">
              Choisir un fichier
            </Button>
            {selectedFileName && <Typography variant="subtitle1" style={{ marginLeft: 10 }}>{selectedFileName}</Typography>}
          </Grid>

          <Grid item>

            <FormControl margin="normal">
              <TextField
                label="Titre du document"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Titre du document"
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl style={formControlStyle}>
              <InputLabel id="category-select-label">Catégorie</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={category}
                onChange={handleCategoryChange}
                label="Catégorie"
              >
                <MenuItem value="Livret d'accueil">Livret d'accueil</MenuItem>
                <MenuItem value="Règlement intérieur">Règlement intérieur</MenuItem>
                <MenuItem value="Note de service">Note de service</MenuItem>
                <MenuItem value="Protocoles">Protocoles</MenuItem>
                <MenuItem value="Fiches de données de sécurité">Fiches de données de sécurités</MenuItem>
                <MenuItem value="Fiches techniques">Fiches techniques</MenuItem>
                <MenuItem value="Réunions">Réunions</MenuItem>
                <MenuItem value="Autre">Autre</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item>

            <FormControl style={formControlStyle}>
              <InputLabel id="company-multiple-checkbox-label">Sociétés</InputLabel>
              <Select
                labelId="company-multiple-checkbox-label"
                multiple
                value={selectedCompanies}
                onChange={handleCompanyChange}
                renderValue={renderSelectedCompanies}
                label="Catégorie"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      width: 250,
                    },
                  },
                }}
              >
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    <Checkbox checked={selectedCompanies.indexOf(company.id) > -1} />
                    <ListItemText primary={company.code} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <Button style={themeStyles} type="submit" variant="contained">
              Ajouter
            </Button>
          </Grid>
        </Grid>
      </form>
      )}

      <div className="vehicule-files">
        {documents.length > 0 ? (
          Object.keys(documentsByCategory).map((category, index) => (
            <Accordion
              key={index}
              style={themeStyles}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`, documentsByCategory[category][0].id)}
            >
              <AccordionSummary
                style={themeStyles}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <Typography style={themeStyles}>{category}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {documentsByCategory[category].map((document) => (
                  <div key={document.id}>
                    <InputLabel htmlFor="idcard-input">
                      <p className="vehiculeLabel">{document.title}</p>
                    </InputLabel>
                    <DocumentsImages userToken={userToken} imageKey={document.file_path} />
                    <div className="vehiculeButton">
                      <Tooltip title="Télécharger le document">
                        <IconButton type="button" onClick={() => fetchSignedUrlForDownloading(document.file_path)}>
                          <CloudDownloadIcon size="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Visualiser le document">
                        <IconButton type="button" onClick={() => fetchSignedUrlForViewing(document.file_path)}>
                          <ImageSearchIcon size="small" />
                        </IconButton>
                      </Tooltip>
                      {isAuthorizedRole() && (
                      <>
                        <Tooltip title="Supprimer le document">
                          <IconButton type="button" onClick={() => handleDelete(document.id)}>
                            <DeleteIcon size="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Voir l'historique des vues">
                          <IconButton type="button" onClick={() => handleViewHistory(document.id)}>
                            <VisibilityIcon size="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                      )}
                    </div>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <p>Aucun document disponible pour votre société</p>
        )}
      </div>

      {/* Déplacer le Dialog ici, en dehors de la boucle */}
      <Dialog open={viewHistoryOpen} onClose={() => setViewHistoryOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Historique des vues</DialogTitle>
        <DialogContent>
          {staffViewHistory.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Prénom</TableCell>
                    <TableCell>Consulté</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staffViewHistory.map((staff) => (
                    <TableRow key={staff.id}>
                      <TableCell>{staff.last_name}</TableCell>
                      <TableCell>{staff.first_name}</TableCell>
                      <TableCell>
                        {staff.hasViewed ? (
                          <Tooltip title={`Consulté le ${new Date(staff.viewedAt).toLocaleString()}`}>
                            <CheckBoxIcon color="primary" />
                          </Tooltip>
                        ) : (
                          <CheckBoxOutlineBlankIcon color="disabled" />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>Aucune donnée disponible.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewHistoryOpen(false)} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  );
}

export default React.memo(GeneralDocument);
