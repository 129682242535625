/* eslint-disable react/prop-types */
/* eslint-disable max-len */

import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  useTheme,
  Autocomplete,
  TextField,
} from '@mui/material';
import {
  AccessTime as AccessTimeIcon,
  PauseCircleOutline as PauseCircleOutlineIcon,
  HourglassFull as HourglassFullIcon,
  WatchLater as WatchLaterIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[6],
  },
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '50%',
  padding: theme.spacing(1),
}));

function TimeClockDataTab({
  averageAmplitude,
  averageBreakTime,
  overtime,
  idaj,
  selectedCompany,
  companies,
  handleFilterCompanyChange,
}) {
  const muiTheme = useTheme();

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 4 },
        backgroundColor: muiTheme.palette.background.default,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      {/* Filtre de Société */}
      <Box sx={{ marginBottom: 4 }}>
        <Autocomplete
          options={companies}
          getOptionLabel={(option) => option.code}
          value={companies.find((company) => company.code === (selectedCompany || '')) || null}
          onChange={(event, newValue) => handleFilterCompanyChange(newValue ? newValue.code : '')}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filtrer par société"
              variant="outlined"
              fullWidth
            />
          )}
          clearOnEscape
        />
      </Box>

      {/* Cartes de Statistiques */}
      <Grid container spacing={3} sx={{ marginBottom: 4 }}>
        {/* Amplitude quotidienne moyenne */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <AccessTimeIcon color="primary" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Amplitude quotidienne moyenne
              </Typography>
              <Typography variant="h5" color="text.primary">
                {averageAmplitude.toFixed(2)} heures
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Temps de pause moyen */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <PauseCircleOutlineIcon color="secondary" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Temps de pause moyen
              </Typography>
              <Typography variant="h5" color="text.primary">
                {averageBreakTime.toFixed(2)} heures
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Heures à 125% */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <HourglassFullIcon color="error" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Nombre d'heures à 125%
              </Typography>
              <Typography variant="h5" color="text.primary">
                {overtime.hoursAt125.toFixed(2)} heures
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Heures à 150% */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <HourglassFullIcon color="error" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Nombre d'heures à 150%
              </Typography>
              <Typography variant="h5" color="text.primary">
                {overtime.hoursAt150.toFixed(2)} heures
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Nombre d'IDAJ */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <WatchLaterIcon color="warning" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Nombre d'IDAJ
              </Typography>
              <Typography variant="h5" color="text.primary">
                {idaj}
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(TimeClockDataTab);
